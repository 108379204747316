
import { defineComponent, ref } from "vue";
import { Form, Field, configure, FormState } from "vee-validate";
import SearchApplicationBackofficeRequest from "@/models/Requests/SearchApplicationBackofficeRequest.model";
import Pager from "@/models/Pager.model";
import ApplicationDataService from "@/services/ApplicationDataService";
import SessionService from "@/services/SessionService";
import { AxiosError, AxiosResponse } from "axios";
import PagedResponseModel from "@/models/Responses/PagedResponse.model";
import ApplicationResponseModel from "@/models/Responses/ApplicationResponse.model";
import moment from "moment";
import Pagination from "@/components/Pagination.vue";
import ApplicationStatusesResponseModel from "@/models/Responses/ApplicationStatusesResponse.model";
import ApplicationStatusTypeModel from "@/models/ApplicationStatusType.model";
import * as RoutingHelpers from "@/helper/RoutingHelpers";
import * as ValidationHelpers from "@/helper/ValidationHelpers";
import ProviderDataService from "@/services/ProviderDataService";
import ProviderContextResponseModel from "@/models/Responses/ProviderContextResponse.model";
import Provider from "@/models/Provider.model";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import {
  AdministratorPermission,
  OperatorPermission,
} from "@/helper/Permission";

const CURRENT_ROUTE = "applications";
const ROUTE_SEARCH = "applications";
const RECHECK = "recheck";
const DETAILS = "details";

export default defineComponent({
  name: "ApplicationListing",
  components: {
    Form,
    Field,
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      providers: [] as Array<Provider>,
      defaultApplicationStatusId: -1,
      defaultProviderId: -1,
      currentApplication: {} as ApplicationResponseModel,
      applicationStatuses: [] as Array<ApplicationStatusTypeModel>,
      searchModel: {} as SearchApplicationBackofficeRequest,
      currentSearchModel: {} as SearchApplicationBackofficeRequest,
      applications: [] as Array<ApplicationResponseModel>,
      totalApplications: 0,
    };
  },
  computed: {
    schema() {
      const schema = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // applicationId(value: string) {
        //   return true;
        // },
        fiscalNumber(value: string) {
          if (value && !ValidationHelpers.validateNIF(value)) {
            return "NIF inválido";
          }
          return true;
        },
        studentFiscalNumber(value: string) {
          if (value && !ValidationHelpers.validateNIF(value)) {
            return "NIF inválido";
          }
          return true;
        },
      };

      return schema;
    },
    datePickerStartTime() {
      return { hours: 0, minutes: 0 };
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const searchForm = ref<null | {
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    const datePickerFormat = (date: Date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${String(day).padStart(2, "0")}/${String(month).padStart(
        2,
        "0"
      )}/${year}`;
    };

    return {
      searchForm,
      datePickerFormat,
    };
  },
  methods: {
    canAccessReader() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Applications_Reader,
        OperatorPermission.ApplicationsModule_Reader,
      ]);
    },
    getProviders() {
      ProviderDataService.getAll()
        .then((response: AxiosResponse<ProviderContextResponseModel[]>) => {
          if (response && response.data) {
            this.providers = response.data.map((provider) => {
              return {
                id: provider.providerId,
                designation: provider.providerDescription,
              } as Provider;
            });
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    onChangePage(pager: Pager) {
      if (
        this.currentPage !== pager.currentPage ||
        this.pageSize !== pager.pageSize
      ) {
        this.currentPage = pager.currentPage;
        this.pageSize = pager.pageSize;
        this.search();
      }
    },
    newSearch() {
      this.searchForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            this.currentPage = 1;
            this.currentSearchModel = {
              id: this.searchModel.id,
              fiscalNumber: this.searchModel.fiscalNumber,
              beginDate: this.searchModel.beginDate,
              endDate: this.searchModel.endDate,
              providerId:
                this.searchModel.providerId === this.defaultProviderId
                  ? undefined
                  : this.searchModel.providerId,
              providerEmail: this.searchModel.providerEmail,
              createdBy: this.searchModel.createdBy,
              isStudent: this.searchModel.isStudent,
              postCode: this.searchModel.postCode,
              studentFiscalNumber: this.searchModel.studentFiscalNumber,
              applicationStatusId:
                this.searchModel.applicationStatusId ===
                this.defaultApplicationStatusId
                  ? undefined
                  : this.searchModel.applicationStatusId,
              wasTSIActivationDelayed: this.searchModel.wasTSIActivationDelayed,
            } as SearchApplicationBackofficeRequest;
            if (
              this.currentSearchModel.applicationStatusId ===
              this.defaultApplicationStatusId
            ) {
              this.currentSearchModel.applicationStatusId = undefined;
            }
            if (this.currentSearchModel.providerId === this.defaultProviderId) {
              this.currentSearchModel.providerId = undefined;
            }
            this.search();
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    search() {
      ApplicationDataService.searchBackoffice(this.getSearchModel())
        .then(
          (
            resp: AxiosResponse<PagedResponseModel<ApplicationResponseModel>>
          ) => {
            if (resp) {
              if (resp.data) {
                this.applications = resp.data.items;
                this.totalApplications = resp.data.totalItems;
              } else {
                this.$toast.error(
                  "Ocorreu um erro na obtenção dos dados, por favor tente novamente."
                );
              }
            }
          }
        )
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((error: Error | AxiosError) => {
          this.$toast.error(
            "Ocorreu um erro na obtenção dos dados, por favor tente novamente."
          );
        });
    },
    resetSearch() {
      this.searchForm?.resetForm();
      this.currentPage = 1;
      this.pageSize = 10;
      this.searchModel = {
        id: undefined,
        fiscalNumber: undefined,
        beginDate: undefined,
        endDate: undefined,
        providerId: undefined,
        postCode: undefined,
        createdBy: undefined,
        providerEmail: undefined,
        isStudent: undefined,
        studentFiscalNumber: undefined,
        applicationStatusId: -1,
        wasTSIActivationDelayed: undefined,
      } as SearchApplicationBackofficeRequest;
    },
    formatDate(date: Date | undefined): string {
      return date ? moment(date).format("DD-MM-YYYY HH:mm") : "";
    },
    validatePageNumber() {
      if (this.applications.length - 1 == 0 && this.totalApplications - 1 > 0) {
        this.currentPage -= 1;
      }
    },
    getApplicationStatuses() {
      ApplicationDataService.getApplicationStatusBackoffice()
        .then((response: AxiosResponse<ApplicationStatusesResponseModel>) => {
          if (response && response.data) {
            this.applicationStatuses = response.data.data;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    goToApplicationDetail(applicationId: string) {
      this.saveCurrentSearch();
      this.$router.push(
        "/" + CURRENT_ROUTE + "/" + applicationId + "/" + DETAILS
      );
    },
    goToApplicationRecheckDetail(
      recheckApplicationId: string,
      applicationId: string
    ) {
      this.saveCurrentSearch();
      this.$router.push(
        "/" +
          CURRENT_ROUTE +
          "/" +
          recheckApplicationId +
          "/" +
          RECHECK +
          "/" +
          applicationId +
          "/" +
          DETAILS
      );
    },
    saveCurrentSearch() {
      const searchObj = {
        currentSearch: this.currentSearchModel,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      SessionService.changeSearch(ROUTE_SEARCH, searchObj);
    },
    getSearchModel(): SearchApplicationBackofficeRequest {
      const newSearchModel = {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        id: this.currentSearchModel.id,
        fiscalNumber: this.currentSearchModel.fiscalNumber,
        beginDate: this.currentSearchModel.beginDate,
        endDate: this.currentSearchModel.endDate,
        providerId: this.currentSearchModel.providerId,
        providerEmail: this.currentSearchModel.providerEmail,
        createdBy: this.currentSearchModel.createdBy,
        isStudent: this.currentSearchModel.isStudent,
        postCode: this.currentSearchModel.postCode,
        studentFiscalNumber: this.currentSearchModel.studentFiscalNumber,
        applicationStatusId: this.currentSearchModel.applicationStatusId,
        wasTSIActivationDelayed:
          this.currentSearchModel.wasTSIActivationDelayed,
      } as SearchApplicationBackofficeRequest;

      return newSearchModel;
    },
    exportAnonymizedApplicationsExcel() {
      ApplicationDataService.exportAnonymizedApplicationsExcel()
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.data.type,
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            "Exportação Total de dados anonimizados (" +
            moment(new Date()).format("DD-MM-YYYY HH:mm") +
            ").xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$toast.error(
            "Ocorreu um erro na obtenção do documento, por favor tente novamente."
          );
        });
    },
  },
  mounted() {
    const sessionState = SessionService.getNavigation();
    const isFromDetail = RoutingHelpers.compareUrl(
      [CURRENT_ROUTE, RoutingHelpers.STRING_VALIDATION],
      sessionState.navigationFrom
    );
    if (isFromDetail) {
      const lastSearch = SessionService.getSearch(ROUTE_SEARCH);
      if (lastSearch) {
        this.currentPage = lastSearch.currentPage;
        this.pageSize = lastSearch.pageSize;
        this.searchModel = JSON.parse(JSON.stringify(lastSearch.currentSearch));
        this.currentSearchModel = JSON.parse(JSON.stringify(this.searchModel));
      }
    } else {
      SessionService.resetSearch();
      this.currentSearchModel = JSON.parse(JSON.stringify(this.searchModel));
    }
    this.searchModel.providerId =
      this.searchModel.providerId === undefined
        ? this.defaultProviderId
        : this.searchModel.providerId;
    this.searchModel.applicationStatusId =
      this.searchModel.applicationStatusId === undefined
        ? this.defaultApplicationStatusId
        : this.searchModel.applicationStatusId;
    this.getProviders();
    this.getApplicationStatuses();
    this.search();
    this.$emit("scrollToTop");
  },
});
