
import moment from "moment";
import axios, { AxiosError, AxiosResponse } from "axios";
import { defineComponent, ref } from "vue";
import Pagination from "@/components/Pagination.vue";
import { Form, Field, configure, FormState } from "vee-validate";
import SearchAnnualMaintenanceApplicationRequestModel from "@/models/Requests/SearchAnnualMaintenanceApplicationRequest.model";
import FileModel from "@/models/File.model";
import RegisterAnnualMaintenanceRequestModel from "@/models/Requests/RegisterAnnualMaintenanceRequest.model";
import RegisterAnnualMaintenanceResponseModel from "@/models/Responses/RegisterAnnualMaintenanceResponse.model";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import Pager from "@/models/Pager.model";
import PagedResponseModel from "@/models/Responses/PagedResponse.model";
import ApplicationStatusesResponseModel from "@/models/Responses/ApplicationStatusesResponse.model";
import ApplicationStatusTypeModel from "@/models/ApplicationStatusType.model";
import { ApplicationStatusType } from "@/models/Enums/ApplicationStatusType.enum";
import ApplicationResponseModel from "@/models/Responses/ApplicationResponse.model";
import CheckableApplicationResponseModel from "@/models/Responses/CheckableApplicationResponse.model";
import ApplicationDataService from "@/services/ApplicationDataService";
import ApplicationAnnualMaintenanceDataService from "@/services/ApplicationAnnualMaintenanceDataService";
import SessionService from "@/services/SessionService";
import * as RoutingHelpers from "@/helper/RoutingHelpers";
import * as ValidationHelpers from "@/helper/ValidationHelpers";
import * as FileHelpers from "@/helper/FileHelpers";
import ValidationMessage from "@/models/ValidationMessage.model";
import AnnualMaintenanceUserNotificationModel from "@/models/AnnualMaintenanceUserNotification.model";
import CreateAnnualMaintenanceUserNotificationRequestModel from "@/models/Requests/CreateAnnualMaintenanceUserNotificationRequest.model";
import CreateAnnualMaintenanceUserNotificationResponseModel from "@/models/Responses/CreateAnnualMaintenanceUserNotificationResponse.model";
import UpdateAnnualMaintenanceUserNotificationResponseModel from "@/models/Responses/UpdateAnnualMaintenanceUserNotificationResponse.model";
import UpdateAnnualMaintenanceUserNotificationRequestModel from "@/models/Requests/UpdateAnnualMaintenanceUserNotificationRequest.model";
import { mapGetters } from "vuex";
import Provider from "@/models/Provider.model";
import ProviderDataService from "@/services/ProviderDataService";
import ProviderContextResponseModel from "@/models/Responses/ProviderContextResponse.model";
import AnnualMaintenancePeriodModel from "@/models/AnnualMaintenancePeriod.model";
import ProcessAnnualMaintenanceRequestModel from "@/models/Requests/ProcessAnnualMaintenanceRequest.model";
import ProcessApplicationResponseModel from "@/models/Responses/ProcessApplicationResponse.model";
import * as DateHelpers from "@/helper/DateHelpers";
import UpdateAnnualMaintenancePeriodRequestModel from "@/models/Requests/UpdateAnnualMaintenancePeriodRequest.model";
import UpdateAnnualMaintenancePeriodResponseModel from "@/models/Responses/UpdateAnnualMaintenancePeriodResponse.model";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import {
  AdministratorPermission,
  OperatorPermission,
} from "@/helper/Permission";
import ExportAnnualMaintenanceApplicationResponseModel from "@/models/Responses/ExportAnnualMaintenanceApplicationResponse.model";

const CURRENT_ROUTE = "applicationAnnualMaintenance";
const ROUTE_SEARCH = "applicationAnnualMaintenance";
const APPLICATION_MANAGEMENT = "applicationManagement";
const APPLICATIONS = "applications";
const DETAILS = "details";

export default defineComponent({
  name: "applicationAnnualMaintenance",
  components: {
    Form,
    Field,
    Pagination,
    ConfirmationModal,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      providers: [] as Array<Provider>,
      defaultProviderId: -1,
      defaultApplicationStatusId: -1,
      isUploadConfirmationModalVisible: false,
      isProcessConfirmationModalVisible: false,
      applicationStatuses: [] as Array<ApplicationStatusTypeModel>,
      searchModel: {} as SearchAnnualMaintenanceApplicationRequestModel,
      currentSearchModel: {} as SearchAnnualMaintenanceApplicationRequestModel,
      applications: [] as Array<CheckableApplicationResponseModel>,
      totalApplications: 0,
      applicationAnnualMaintenanceFile: null as FileModel | null,
      fileUploadResult: null as RegisterAnnualMaintenanceResponseModel | null,
      annualMaintenanceEmail: {} as AnnualMaintenanceUserNotificationModel,
      applicationsWithoutMaintenanceCount: 0,
      didUserSelectAll: false,
      applicationIdsToBeProcessed: [] as Array<string>,
      currentOrLastMaintenancePeriod: {} as AnnualMaintenancePeriodModel,
      currentOrNextMaintenancePeriod: {} as AnnualMaintenancePeriodModel,
      isMaintenancePeriodInEffect: false,
      hasAnyMaintenanceRequestBeenProcessed: false,
    };
  },
  computed: {
    ...mapGetters(["isUserAdmin", "isUserProvider", "isUserOperator"]),
    emailFormSchema() {
      const schema = {
        notificationEmail(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          // if the field is not a valid email
          // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          const regex =
            /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i;
          if (!regex.test(value)) {
            return "E-mail inválido.";
          }
          // All is good
          return true;
        },
      };
      return schema;
    },
    maintenancePeriodFormSchema() {
      const beginDate = this.currentOrNextMaintenancePeriod.beginDate;
      const endDate = this.currentOrNextMaintenancePeriod.endDate;
      const isPeriodInEfect = this.isMaintenancePeriodInEffect;

      const schema = {
        startDate() {
          const value = new Date(beginDate);
          const now = new Date();

          if (isPeriodInEfect) {
            return true;
          }

          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }

          if (value.getTime() <= now.getTime()) {
            return "Insira uma data posterior à data atual.";
          }

          // All is good
          return true;
        },
        endDate() {
          const value = new Date(endDate);
          const now = new Date();
          const refBeginDate = new Date(beginDate);

          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }

          if (value.getTime() <= now.getTime()) {
            return "Insira uma data posterior à data atual.";
          }

          if (value.getTime() <= refBeginDate.getTime()) {
            return "Insira uma data posterior à data início.";
          }

          // All is good
          return true;
        },
      };
      return schema;
    },
    uploadFormSchema() {
      const applicationAnnualMaintenanceFile =
        this.applicationAnnualMaintenanceFile;
      const schema = {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        applicationAnnualMaintenanceFileInput(value: any) {
          if (!applicationAnnualMaintenanceFile) {
            return "Campo obrigatório.";
          }
          return true;
        },
      };
      return schema;
    },
    searchFormSchema() {
      const schema = {
        fiscalNumber(value: string) {
          if (value && !ValidationHelpers.validateNIF(value)) {
            return "NIF inválido";
          }
          return true;
        },
        studentFiscalNumber(value: string) {
          if (value && !ValidationHelpers.validateNIF(value)) {
            return "NIF inválido";
          }
          return true;
        },
      };

      return schema;
    },
    isMaintenanceEmailSet(): boolean {
      return this.annualMaintenanceEmail.id != undefined;
    },
    defaultBeginStartTime() {
      return { hours: 0, minutes: 0 };
    },
    defaultEndStartTime() {
      return { hours: 23, minutes: 59 };
    },
    minBeginDate(): Date {
      if (this.currentOrNextMaintenancePeriod) {
        const periodBeginDate = new Date(
          this.currentOrNextMaintenancePeriod.beginDate
        );

        if (this.isMaintenancePeriodInEffect) {
          return new Date();
        }

        return DateHelpers.getLatestOfDates(
          new Date(),
          DateHelpers.getFirstDateOfYear(periodBeginDate.getFullYear())
        );
      } else {
        return new Date();
      }
    },
    maxBeginDate(): Date {
      if (this.currentOrNextMaintenancePeriod) {
        const periodBeginDate = new Date(
          this.currentOrNextMaintenancePeriod.beginDate
        );

        return DateHelpers.getLastDateOfYear(periodBeginDate.getFullYear());
      } else {
        return DateHelpers.getLastDateOfYear(new Date().getFullYear());
      }
    },
    minEndDate(): Date {
      if (this.currentOrNextMaintenancePeriod) {
        const periodEndDate = new Date(
          this.currentOrNextMaintenancePeriod.endDate
        );

        const periodBeginDate = new Date(
          this.currentOrNextMaintenancePeriod.beginDate
        );

        return DateHelpers.getLatestOfDates(
          periodBeginDate,
          DateHelpers.getFirstDateOfYear(periodEndDate.getFullYear())
        );
      } else {
        return new Date();
      }
    },
    maxEndDate(): Date {
      if (this.currentOrNextMaintenancePeriod) {
        const periodEndDate = new Date(
          this.currentOrNextMaintenancePeriod.endDate
        );

        return DateHelpers.getLastDateOfYear(periodEndDate.getFullYear());
      } else {
        return DateHelpers.getLastDateOfYear(new Date().getFullYear());
      }
    },
    isMaintenanceEmailEditable(): boolean {
      return (
        this.isMaintenancePeriodInEffect &&
        !this.hasAnyMaintenanceRequestBeenProcessed
      );
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const emailForm = ref<null | {
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    const uploadForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    const searchForm = ref<null | {
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    const maintenancePeriodForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    const datePickerFormat = (date: Date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${String(day).padStart(2, "0")}/${String(month).padStart(
        2,
        "0"
      )}/${year}`;
    };

    const dateTimePickerFormat = (date: Date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      return `${String(day).padStart(2, "0")}/${String(month).padStart(
        2,
        "0"
      )}/${year} ${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}`;
    };

    return {
      emailForm,
      uploadForm,
      maintenancePeriodForm,
      searchForm,
      datePickerFormat,
      dateTimePickerFormat,
    };
  },
  methods: {
    canAccessReader() {
      return AuthenticationDataService.canUserAccess([
        OperatorPermission.AnnualMaintenanceModule_Reader,
      ]);
    },
    canAccessWriter() {
      return AuthenticationDataService.canUserAccess([
        OperatorPermission.AnnualMaintenanceModule_Writer,
      ]);
    },
    canAccessUpdater() {
      return AuthenticationDataService.canUserAccess([
        OperatorPermission.AnnualMaintenanceModule_Updater,
      ]);
    },
    canAccessAdminReader() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.AnnualMaintenance_Reader,
      ]);
    },
    canAccessAdminUpdater() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.AnnualMaintenance_Updater,
      ]);
    },
    onChangePage(pager: Pager) {
      if (
        this.currentPage !== pager.currentPage ||
        this.pageSize !== pager.pageSize
      ) {
        this.currentPage = pager.currentPage;
        this.pageSize = pager.pageSize;
        this.search();
      }
    },
    newSearch() {
      this.applicationIdsToBeProcessed = [] as Array<string>;
      this.searchForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            this.currentPage = 1;
            this.currentSearchModel = {
              fiscalNumber: this.searchModel.fiscalNumber,
              isStudent: this.searchModel.isStudent,
              studentFiscalNumber: this.searchModel.studentFiscalNumber,
              beginDate: this.searchModel.beginDate,
              endDate: this.searchModel.endDate,
              applicationStatusId:
                this.searchModel.applicationStatusId ===
                this.defaultApplicationStatusId
                  ? undefined
                  : this.searchModel.applicationStatusId,
              providerId:
                this.searchModel.providerId === this.defaultProviderId
                  ? undefined
                  : this.searchModel.providerId,
            } as SearchAnnualMaintenanceApplicationRequestModel;
            if (
              this.currentSearchModel.applicationStatusId ===
              this.defaultApplicationStatusId
            ) {
              this.currentSearchModel.applicationStatusId = undefined;
            }
            if (this.currentSearchModel.providerId === this.defaultProviderId) {
              this.currentSearchModel.providerId = undefined;
            }
            this.search();
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    exportExcelFile() {
      ApplicationDataService.exportAnnualMaintenanceApplicationExcel(
        this.getSearchModel()
      )
        .then(
          (
            resp: AxiosResponse<ExportAnnualMaintenanceApplicationResponseModel>
          ) => {
            if (resp) {
              if (resp.data) {
                if (resp.data.success && resp.data.resourceFile) {
                  // All good!
                  const blob = FileHelpers.b64toBlob(
                    resp.data.resourceFile.file,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  );
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = resp.data.resourceFile.name;
                  link.click();
                  URL.revokeObjectURL(link.href);
                } else {
                  this.$toast.error(
                    "Ocorreu um erro na exportação do excel. Por favor tente novamente."
                  );
                }
              } else {
                this.$toast.error(
                  "Ocorreu um erro na exportação do excel. Por favor tente novamente."
                );
              }
            }
          }
        )
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            const err = error as AxiosError;
            if (err.response && err.response.status === 400) {
              // Just a stock error
              const validationMessage = err.response.data as ValidationMessage;
              if (
                validationMessage &&
                validationMessage.errors &&
                validationMessage.errors.length &&
                validationMessage.errors[0].errors &&
                validationMessage.errors[0].errors.length
              ) {
                this.$toast.error(validationMessage.errors[0].errors[0]);
              }
            } else {
              // Just a stock error
              this.$toast.error(
                "Ocorreu um erro na exportação do excel. Por favor tente novamente."
              );
            }
          }
        });
    },
    search() {
      ApplicationDataService.searchAnnualMaintenanceApplications(
        this.getSearchModel()
      )
        .then(
          (
            resp: AxiosResponse<PagedResponseModel<ApplicationResponseModel>>
          ) => {
            if (resp) {
              if (resp.data) {
                this.applications = resp.data.items.map((elem) => {
                  return {
                    ...elem,
                    isChecked: this.applicationIdsToBeProcessed.includes(
                      elem.id
                    ),
                  } as CheckableApplicationResponseModel;
                });
                this.totalApplications = resp.data.totalItems;
              } else {
                this.$toast.error(
                  "Ocorreu um erro na obtenção dos dados, por favor tente novamente."
                );
              }
            }
          }
        )
        .catch(() => {
          this.$toast.error(
            "Ocorreu um erro na obtenção dos dados, por favor tente novamente."
          );
        });
    },
    getApplicationIdsForProcessing() {
      ApplicationDataService.getAnnualMaintenanceApplicationIdsForProcessing(
        this.getSearchModel()
      )
        .then((resp: AxiosResponse<Array<string>>) => {
          if (resp) {
            if (resp.data) {
              this.applicationIdsToBeProcessed = resp.data;
              this.applications = this.applications.map((app) => ({
                ...app,
                isChecked: this.applicationIdsToBeProcessed.includes(app.id),
              }));
            } else {
              this.$toast.error(
                "Ocorreu um erro na obtenção dos dados, por favor tente novamente."
              );
            }
          }
        })
        .catch(() => {
          this.$toast.error(
            "Ocorreu um erro na obtenção dos dados, por favor tente novamente."
          );
        });
    },
    resetSearch() {
      this.searchForm?.resetForm();
      this.currentPage = 1;
      this.pageSize = 10;
      // this.applicationFile: null as FileModel | null;
      this.searchModel = {
        fiscalNumber: undefined,
        isStudent: undefined,
        studentFiscalNumber: undefined,
        applicationStatusId: this.defaultApplicationStatusId,
        providerId: this.defaultProviderId,
      } as SearchAnnualMaintenanceApplicationRequestModel;
    },
    formatDate(date: Date | undefined): string {
      return date ? moment(date).format("DD-MM-YYYY HH:mm") : "";
    },
    validatePageNumber() {
      if (this.applications.length - 1 == 0 && this.totalApplications - 1 > 0) {
        this.currentPage -= 1;
      }
    },
    getApplicationStatuses() {
      if (this.isUserAdmin) {
        ApplicationDataService.getApplicationStatusBackoffice()
          .then((response: AxiosResponse<ApplicationStatusesResponseModel>) => {
            if (response && response.data) {
              this.applicationStatuses = response.data.data;
            }
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else {
        ApplicationDataService.getApplicationStatus()
          .then((response: AxiosResponse<ApplicationStatusesResponseModel>) => {
            if (response && response.data) {
              this.applicationStatuses = response.data.data;
            }
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    },
    getAnnualMaintenanceEmail() {
      ApplicationAnnualMaintenanceDataService.getNotificationEmailByProvider()
        .then(
          (response: AxiosResponse<AnnualMaintenanceUserNotificationModel>) => {
            if (response && response.data) {
              this.annualMaintenanceEmail = response.data;
            }
          }
        )
        .catch((e: Error) => {
          console.log(e);
        });
    },
    checkIfAnyMaintenanceRequestsHaveBeenProcessed() {
      ApplicationAnnualMaintenanceDataService.haveAnyAnnualMaintenanceRequestsBeenProcessed()
        .then((response: AxiosResponse<boolean>) => {
          if (response) {
            this.hasAnyMaintenanceRequestBeenProcessed = response.data;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    countApplicationsWithoutAnnualMaintenance() {
      ApplicationAnnualMaintenanceDataService.countApplicationsWithoutAnnualMaintenance()
        .then((response: AxiosResponse<number>) => {
          if (response) {
            this.applicationsWithoutMaintenanceCount = response.data;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    isApplicationSubmitted(application: ApplicationResponseModel) {
      return (
        application.applicationStatusId === ApplicationStatusType.Submitted
      );
    },
    goToApplicationDetail(applicationId: string) {
      this.saveCurrentSearch();
      this.$router.push(
        "/" +
          (this.isUserAdmin ? APPLICATIONS : APPLICATION_MANAGEMENT) +
          "/" +
          applicationId +
          "/" +
          DETAILS
      );
    },
    goToApplicationRecheckForm(applicationId: string) {
      this.saveCurrentSearch();
      this.$router.push("/" + CURRENT_ROUTE + "/" + applicationId + "/edit");
    },
    saveCurrentSearch() {
      const searchObj = {
        currentSearch: this.currentSearchModel,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      SessionService.changeSearch(ROUTE_SEARCH, searchObj);
    },
    getSearchModel(): SearchAnnualMaintenanceApplicationRequestModel {
      const newSearchModel = {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        fiscalNumber: this.currentSearchModel.fiscalNumber,
        isStudent: this.currentSearchModel.isStudent,
        studentFiscalNumber: this.currentSearchModel.studentFiscalNumber,
        applicationStatusId: this.currentSearchModel.applicationStatusId,
        beginDate: this.currentSearchModel.beginDate,
        endDate: this.currentSearchModel.endDate,
        providerId: this.currentSearchModel.providerId,
      } as SearchAnnualMaintenanceApplicationRequestModel;

      return newSearchModel;
    },
    downloadAnnualMaintenanceTemplate() {
      ApplicationAnnualMaintenanceDataService.downloadAnnualMaintenanceTemplate()
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Template de Manutencao Anual.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$toast.error(
            "Ocorreu um erro na obtenção do documento, por favor tente novamente."
          );
        });
    },
    onAnnualMaintenanceFileChange(
      event: Event,
      callback: (e: unknown, shouldValidate?: boolean | undefined) => void
    ) {
      if (event != null && event.target != null) {
        const target = event.target as HTMLInputElement;
        var files = target.files;
        this.fileUploadResult = null;

        if (files != null) {
          const file = files[0] as File;

          const fileExtension = file.name
            .substr(file.name.length - 5)
            .toUpperCase()
            .toLowerCase();
          if (fileExtension !== ".xlsx") {
            this.$toast.error(
              "Formato inválido. Insira documentos .xlsx com estrutura válida e com um máximo de 10.000 linhas cada."
            );
            return;
          }

          FileHelpers.getFileModelFromFile(file)
            .then((fileModel) => {
              this.applicationAnnualMaintenanceFile = fileModel;
              callback(event, true);
            })
            .catch(() => {
              this.$toast.error(
                "Formato inválido. Insira documentos .xlsx com estrutura válida e com um máximo de 10.000 linhas cada."
              );
            });
        }
      }
    },
    submitEmailForm() {
      this.emailForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            if (this.annualMaintenanceEmail && this.annualMaintenanceEmail.id) {
              //UPDATE THE EMAIL
              const updateEmailRequestModel = {
                annualMaintenanceUserNotification: this.annualMaintenanceEmail,
              } as UpdateAnnualMaintenanceUserNotificationRequestModel;

              ApplicationAnnualMaintenanceDataService.updateNotificationEmail(
                updateEmailRequestModel
              )
                .then(
                  (
                    resp: AxiosResponse<UpdateAnnualMaintenanceUserNotificationResponseModel>
                  ) => {
                    if (resp) {
                      if (resp.data && resp.data.success) {
                        this.annualMaintenanceEmail.id = resp.data.id;
                        this.annualMaintenanceEmail.email = resp.data.email;
                        this.$toast.success("E-mail gravado com sucesso.");
                      } else {
                        this.$toast.error(
                          "Ocorreu um erro na gravação do e-mail, por favor tente novamente."
                        );
                      }
                    }
                  }
                )
                .catch((error: Error | AxiosError) => {
                  if (axios.isAxiosError(error)) {
                    // Access to config, request, and response
                    const err = error as AxiosError;
                    if (err.response && err.response.status === 400) {
                      // Just a stock error
                      const validationMessage = err.response
                        .data as ValidationMessage;
                      if (
                        validationMessage &&
                        validationMessage.errors &&
                        validationMessage.errors.length &&
                        validationMessage.errors[0].errors &&
                        validationMessage.errors[0].errors.length
                      ) {
                        this.$toast.error(
                          validationMessage.errors[0].errors[0]
                        );
                      }
                    }
                  } else {
                    // Just a stock error
                    this.$toast.error(
                      "Ocorreu um erro na gravação do e-mail, por favor tente novamente."
                    );
                  }
                });
            } else {
              //CREATE THE EMAIL
              const createEmailRequestModel = {
                annualMaintenanceUserNotification: this.annualMaintenanceEmail,
              } as CreateAnnualMaintenanceUserNotificationRequestModel;

              ApplicationAnnualMaintenanceDataService.createNotificationEmail(
                createEmailRequestModel
              )
                .then(
                  (
                    resp: AxiosResponse<CreateAnnualMaintenanceUserNotificationResponseModel>
                  ) => {
                    if (resp) {
                      if (resp.data && resp.data.success) {
                        this.annualMaintenanceEmail.id = resp.data.id;
                        this.annualMaintenanceEmail.email = resp.data.email;
                        this.$toast.success("E-mail gravado com sucesso.");
                      } else {
                        this.$toast.error(
                          "Ocorreu um erro na gravação do e-mail, por favor tente novamente."
                        );
                      }
                    }
                  }
                )
                .catch((error: Error | AxiosError) => {
                  if (axios.isAxiosError(error)) {
                    // Access to config, request, and response
                    const err = error as AxiosError;
                    if (err.response && err.response.status === 400) {
                      // Just a stock error
                      const validationMessage = err.response
                        .data as ValidationMessage;
                      if (
                        validationMessage &&
                        validationMessage.errors &&
                        validationMessage.errors.length &&
                        validationMessage.errors[0].errors &&
                        validationMessage.errors[0].errors.length
                      ) {
                        this.$toast.error(
                          validationMessage.errors[0].errors[0]
                        );
                      }
                    }
                  } else {
                    // Just a stock error
                    this.$toast.error(
                      "Ocorreu um erro na gravação do e-mail, por favor tente novamente."
                    );
                  }
                });
            }
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    submitUploadForm() {
      //Only upload file if notification email is set
      if (this.isMaintenanceEmailSet) {
        this.uploadForm
          ?.validate()
          .then(
            (result: { valid: boolean; errors: Record<string, string> }) => {
              if (result.valid) {
                this.isUploadConfirmationModalVisible = true;
              }
            }
          )
          .catch((error: Error) => {
            console.log(error);
          });
      }
    },
    submitMaintenancePeriodForm() {
      this.maintenancePeriodForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            if (
              this.currentOrNextMaintenancePeriod &&
              this.currentOrNextMaintenancePeriod.id
            ) {
              const updateMaintenancePeriodRequestModel = {
                annualMaintenancePeriod: this.currentOrNextMaintenancePeriod,
              } as UpdateAnnualMaintenancePeriodRequestModel;

              ApplicationAnnualMaintenanceDataService.updateMaintenancePeriod(
                updateMaintenancePeriodRequestModel
              )
                .then(
                  (
                    resp: AxiosResponse<UpdateAnnualMaintenancePeriodResponseModel>
                  ) => {
                    if (resp) {
                      if (resp.data && resp.data.success) {
                        this.currentOrNextMaintenancePeriod.id = resp.data.id;
                        this.currentOrNextMaintenancePeriod.beginDate =
                          resp.data.beginDate;
                        this.currentOrNextMaintenancePeriod.endDate =
                          resp.data.endDate;

                        this.$toast.success(
                          "Período de manutenção atualizado com sucesso."
                        );
                      } else {
                        this.$toast.error(
                          "Ocorreu um erro na atualização do período de manutenção, por favor tente novamente."
                        );
                      }
                    }
                  }
                )
                .catch((error: Error | AxiosError) => {
                  if (axios.isAxiosError(error)) {
                    // Access to config, request, and response
                    const err = error as AxiosError;
                    if (err.response && err.response.status === 400) {
                      // Just a stock error
                      const validationMessage = err.response
                        .data as ValidationMessage;
                      if (
                        validationMessage &&
                        validationMessage.errors &&
                        validationMessage.errors.length &&
                        validationMessage.errors[0].errors &&
                        validationMessage.errors[0].errors.length
                      ) {
                        this.$toast.error(
                          validationMessage.errors[0].errors[0]
                        );
                      }
                    }
                  } else {
                    // Just a stock error
                    this.$toast.error(
                      "Ocorreu um erro na atualização do período de manutenção, por favor tente novamente."
                    );
                  }
                });
            }
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    uploadMaintenanceFile() {
      const registerAnnualMaintenanceRequest = {
        MaintenanceFile: this.applicationAnnualMaintenanceFile,
      } as RegisterAnnualMaintenanceRequestModel;

      ApplicationAnnualMaintenanceDataService.uploadAnnualMaintenanceTemplate(
        registerAnnualMaintenanceRequest
      )
        .then((resp: AxiosResponse<RegisterAnnualMaintenanceResponseModel>) => {
          if (resp) {
            if (resp.data) {
              if (resp.data.success) {
                // All good!
                this.$toast.success("Ficheiro importado com sucesso!");
                this.fileUploadResult = null;
                this.newSearch();
                this.countApplicationsWithoutAnnualMaintenance();
              } else {
                this.fileUploadResult = resp.data;
              }
              this.removeApplicationAnnualMaintenanceFile();
              this.uploadForm?.resetForm();
            } else {
              this.$toast.error(
                "Formato inválido. Insira documentos .xlsx com estrutura válida e com um máximo de 10.000 linhas cada."
              );
            }
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            const err = error as AxiosError;
            if (err.response && err.response.status === 400) {
              // Just a stock error
              const validationMessage = err.response.data as ValidationMessage;
              if (
                validationMessage &&
                validationMessage.errors &&
                validationMessage.errors.length &&
                validationMessage.errors[0].errors &&
                validationMessage.errors[0].errors.length
              ) {
                this.$toast.error(validationMessage.errors[0].errors[0]);
              }
            } else {
              // Just a stock error
              this.$toast.error(
                "Formato inválido. Insira documentos .xlsx com estrutura válida e com um máximo de 10.000 linhas cada."
              );
            }
          }
        });
    },
    downloadErrorFile() {
      if (this.fileUploadResult && this.fileUploadResult.resourceFile) {
        const blob = FileHelpers.b64toBlob(
          this.fileUploadResult.resourceFile.file,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.fileUploadResult.resourceFile.name;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    removeApplicationAnnualMaintenanceFile() {
      this.applicationAnnualMaintenanceFile = null;
      this.uploadForm?.setFieldError(
        "applicationAnnualMaintenanceFileInput",
        "Campo obrigatório."
      );
      const fileInput = document.getElementById(
        "applicationAnnualMaintenanceFileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    },
    showUploadConfirmationModal() {
      this.isUploadConfirmationModalVisible = true;
    },
    acceptUploadConfirmationModal() {
      this.isUploadConfirmationModalVisible = false;
      this.uploadMaintenanceFile();
    },
    closeUploadConfirmationModal() {
      this.isUploadConfirmationModalVisible = false;
    },
    showProcessConfirmationModal() {
      this.isProcessConfirmationModalVisible = true;
    },
    acceptProcessConfirmationModal() {
      this.isProcessConfirmationModalVisible = false;
      this.processApplications();
    },
    closeProcessConfirmationModal() {
      this.isProcessConfirmationModalVisible = false;
    },
    deleteApplication(applicationId: string) {
      ApplicationDataService.deleteApplicationByApplicationId(applicationId)
        .then((resp: AxiosResponse<boolean>) => {
          if (resp) {
            this.$toast.success("Pedido eliminado com sucesso.");
            this.newSearch();
            this.countApplicationsWithoutAnnualMaintenance();
          } else {
            this.$toast.error(
              "Ocorreu um erro na eliminação do pedido, por favor tente novamente."
            );
          }
        })
        .catch(() => {
          this.$toast.error(
            "Ocorreu um erro na eliminação do pedido, por favor tente novamente."
          );
        });
    },
    getProviders() {
      ProviderDataService.getAll()
        .then((response: AxiosResponse<ProviderContextResponseModel[]>) => {
          if (response && response.data) {
            this.providers = response.data.map((provider) => {
              return {
                id: provider.providerId,
                designation: provider.providerDescription,
              } as Provider;
            });
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    isChecked(id: string) {
      return this.applicationIdsToBeProcessed.includes(id);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleApplication(event: any, id: string) {
      const index = this.applicationIdsToBeProcessed.findIndex(
        (value) => value === id
      );
      if (event.target.checked) {
        if (index === -1) {
          this.applicationIdsToBeProcessed.push(id);
        }
      } else {
        if (index !== -1) {
          this.applicationIdsToBeProcessed.splice(index, 1);
        }
      }
    },
    selectAll() {
      this.getApplicationIdsForProcessing();
      this.didUserSelectAll = true;
    },
    unselectAll() {
      this.applicationIdsToBeProcessed = [] as Array<string>;
      this.applications = this.applications.map((app) => ({
        ...app,
        isChecked: false,
      }));
      this.didUserSelectAll = false;
    },
    processApplications() {
      if (
        this.applicationIdsToBeProcessed &&
        this.applicationIdsToBeProcessed.length > 0
      ) {
        const processApplicationRequest = {
          maintenanceRequestIdsToProcess: this.applicationIdsToBeProcessed,
        } as ProcessAnnualMaintenanceRequestModel;

        ApplicationAnnualMaintenanceDataService.processAnnualMaintenanceRequests(
          processApplicationRequest
        )
          .then(
            (resp: AxiosResponse<Array<ProcessApplicationResponseModel>>) => {
              if (resp) {
                if (resp.data) {
                  if (resp.data.every((x) => x.success)) {
                    this.$toast.success("Pedidos processados com sucesso.");
                  } else if (resp.data.some((x) => x.success)) {
                    this.$toast.warning(
                      "Alguns pedidos não foram processados com sucesso, por favor tente novamente."
                    );
                  } else {
                    this.$toast.error(
                      "Ocorreu um erro no processamento dos pedidos, por favor tente novamente."
                    );
                  }
                } else {
                  this.$toast.error(
                    "Ocorreu um erro no processamento dos pedidos, por favor tente novamente."
                  );
                }
              }
            }
          )
          .catch((error: Error | AxiosError) => {
            if (axios.isAxiosError(error)) {
              // Access to config, request, and response
              const err = error as AxiosError;
              if (err.response && err.response.status === 400) {
                // Just a stock error
                const validationMessage = err.response
                  .data as ValidationMessage;
                if (
                  validationMessage &&
                  validationMessage.errors &&
                  validationMessage.errors.length &&
                  validationMessage.errors[0].errors &&
                  validationMessage.errors[0].errors.length
                ) {
                  this.$toast.error(validationMessage.errors[0].errors[0]);
                }
              }
            } else {
              // Just a stock error
              this.$toast.error(
                "Ocorreu um erro no processamento dos pedidos, por favor tente novamente."
              );
            }
          })
          .finally(() => {
            this.unselectAll();
            this.newSearch();
          });
      }
    },
    getCurrentOrLastMaintenancePeriod() {
      ApplicationAnnualMaintenanceDataService.getCurrentOrLastAnnualMaintenancePeriod()
        .then((response: AxiosResponse<AnnualMaintenancePeriodModel>) => {
          if (response && response.data) {
            this.currentOrLastMaintenancePeriod = response.data;

            var currentDate = new Date();

            this.isMaintenancePeriodInEffect =
              new Date(this.currentOrLastMaintenancePeriod.beginDate) <=
                currentDate &&
              new Date(this.currentOrLastMaintenancePeriod.endDate) >=
                currentDate;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    getCurrentOrNextMaintenancePeriod() {
      ApplicationAnnualMaintenanceDataService.getCurrentOrNextAnnualMaintenancePeriod()
        .then((response: AxiosResponse<AnnualMaintenancePeriodModel>) => {
          if (response && response.data) {
            this.currentOrNextMaintenancePeriod = response.data;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    validateMaintenanceBeginDate(): boolean {
      const value = this.currentOrNextMaintenancePeriod.beginDate;

      if (value <= new Date()) {
        this.maintenancePeriodForm?.setFieldError(
          "startDate",
          "Insira uma data posterior à data atual."
        );
        return false;
      }

      this.maintenancePeriodForm?.setFieldError("startDate", undefined);
      return true;
    },
    validateMaintenanceEndDate(): boolean {
      const value = this.currentOrNextMaintenancePeriod.endDate;

      if (value <= new Date()) {
        this.maintenancePeriodForm?.setFieldError(
          "endDate",
          "Insira uma data posterior à data atual."
        );
        return false;
      }

      if (value <= this.currentOrNextMaintenancePeriod.beginDate) {
        this.maintenancePeriodForm?.setFieldError(
          "endDate",
          "Insira uma data posterior à data início."
        );
        return false;
      }

      this.maintenancePeriodForm?.setFieldError("endDate", undefined);
      return true;
    },
  },
  mounted() {
    const sessionState = SessionService.getNavigation();
    const isFromDetail = RoutingHelpers.compareUrl(
      [CURRENT_ROUTE, RoutingHelpers.STRING_VALIDATION],
      sessionState.navigationFrom
    );
    if (isFromDetail) {
      const lastSearch = SessionService.getSearch(ROUTE_SEARCH);
      if (lastSearch) {
        this.currentPage = lastSearch.currentPage;
        this.pageSize = lastSearch.pageSize;
        this.searchModel = JSON.parse(JSON.stringify(lastSearch.currentSearch));
        this.currentSearchModel = JSON.parse(JSON.stringify(this.searchModel));
      }
    } else {
      SessionService.resetSearch();
      this.currentSearchModel = JSON.parse(JSON.stringify(this.searchModel));
    }
    this.searchModel.applicationStatusId =
      this.searchModel.applicationStatusId === undefined
        ? this.defaultApplicationStatusId
        : this.searchModel.applicationStatusId;

    this.getCurrentOrLastMaintenancePeriod();

    if (this.isUserProvider || this.isUserOperator) {
      this.getAnnualMaintenanceEmail();
      this.checkIfAnyMaintenanceRequestsHaveBeenProcessed();
      this.countApplicationsWithoutAnnualMaintenance();
    } else {
      this.getCurrentOrNextMaintenancePeriod();
      this.getProviders();

      this.searchModel.providerId =
        this.searchModel.providerId === undefined
          ? this.defaultProviderId
          : this.searchModel.providerId;
    }

    this.getApplicationStatuses();
    this.search();
    this.$emit("scrollToTop");
  },
});
