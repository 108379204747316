import Provider from "@/models/Provider.model";
import ProviderContextResponseModel from "@/models/Responses/ProviderContextResponse.model";
import http from "@/services/HttpCommons";
import { AxiosResponse } from "axios";

const providerArray: Provider[] = [
  {
    id: 3,
    designation: "AR Telecom - Acessos e Redes de Telecomunicações, S.A.",
  },
  { id: 4, designation: "Ask4 Portugal Limited." },
  {
    id: 5,
    designation: "Associação dos Municípios da Terra Quente Transmontana",
  },
  { id: 6, designation: "Associação Porto Digital" },
  {
    id: 7,
    designation:
      "AT&T - Serviços de Telecomunicações, Sociedade Unipessoal, Lda.",
  },
  { id: 8, designation: "BLU, S.A." },
  { id: 9, designation: "Claranet Portugal, S.A." },
  { id: 10, designation: "Cogent Communications Portugal, Lda." },
  { id: 11, designation: "COLT Technology Services, Unipessoal, Lda." },
  { id: 12, designation: "Companhia I.B.M. Portuguesa, S.A." },
  { id: 13, designation: "Cubic Telecom Limited" },
  { id: 14, designation: "Cyclop Net - Informática e Telecomunicações, Lda." },
  { id: 15, designation: "Equinix (Portugal) Data Centers, S.A." },
  { id: 16, designation: "Eutelsat, S.A." },
  { id: 17, designation: "Evomedia Mobile, Unipessoal, Lda." },
  {
    id: 18,
    designation:
      "Fleximédia - Serviços e Meios de Informação e Comunicação, Lda.",
  },
  { id: 19, designation: "G9Telecom, S.A." },
  { id: 20, designation: "Gardunha Networks Unipessoal, Lda." },
  { id: 21, designation: "Gotelecom, S.A." },
  { id: 22, designation: "Greenmill, Lda." },
  { id: 23, designation: "Hoist Group Portugal, S.A." },
  { id: 24, designation: "IP Telecom - Serviços de Telecomunicações, S.A." },
  { id: 25, designation: "Lazer Telecomunicações, S.A." },
  { id: 26, designation: "Lumen Technologies Iberia S.A." },
  { id: 27, designation: "Lycamobile Portugal, Lda." },
  { id: 28, designation: "MEO - Serviços de Comunicações e Multimédia, S.A." },
  {
    id: 29,
    designation: "Nextweb - Prestação de Serviços na Área da Internet, Lda.",
  },
  { id: 30, designation: "NOS Açores Comunicações, S.A." },
  { id: 31, designation: "NOS Comunicações, S.A." },
  { id: 32, designation: "NOS Madeira Comunicações, S.A." },
  { id: 33, designation: "NOWO Communications, S.A." },
  { id: 34, designation: "Onitelecom - Infocomunicações, S.A." },
  { id: 35, designation: "Orange Business Portugal, S.A." },
  { id: 36, designation: "Otnetvtel - Unipessoal, Lda." },
  { id: 37, designation: "Panasonic Avionics Corporation" },
  { id: 38, designation: "Pinkhairezonanet, Unipessoal, Lda." },
  {
    id: 39,
    designation: "Prodevice - Serviços Informáticos, Unipessoal, Lda.",
  },
  {
    id: 40,
    designation:
      "Satélite da Sabedoria - Serviços de Internet, Unipessoal, Lda.",
  },
  { id: 41, designation: "SemCabo - Soluções em Redes Informáticas, Lda." },
  { id: 42, designation: "SkyDSL Europe B.V." },
  { id: 43, designation: "Sousa Pinheiro Telecomunicações, Lda." },
  { id: 44, designation: "Starlink Internet Services Limited" },
  {
    id: 45,
    designation: "STV - Sociedade de Telecomunicações do Vale do Sousa, S.A.",
  },
  {
    id: 46,
    designation:
      "Telefonica Global Solutions, S.L. Unipersonal, Sucursal em Portugal",
  },
  { id: 47, designation: "T-Mobile HotSpot GmbH" },
  { id: 48, designation: "Verizon Portugal - Sociedade Unipessoal, Lda." },
  {
    id: 49,
    designation:
      "Vivasat - Distribuição de Serviços de Telecomunicações, Unipessoal, Lda.",
  },
  { id: 50, designation: "Vodafone Portugal - Comunicações Pessoais, S.A." },
];

class ProviderDataService {
  private CONTROLLER_BASE = "/Provider";

  getAll(): Promise<AxiosResponse<ProviderContextResponseModel[]>> {
    return http.get(this.CONTROLLER_BASE + "/get-providers");
    // return new Promise<Provider[]>((resolve) => {
    //   //saving MyClass using http service
    //   //return the saved MyClass or error

    //   // if (isSomeCondition) {
    //   //   throw new Error("No reason but to reject");
    //   // }

    //   // setTimeout(() => {
    //   //   resolve(providerArray);
    //   // }, 1500);
    // });
  }

  getProviderContext(): Promise<AxiosResponse<ProviderContextResponseModel>> {
    return http.get(this.CONTROLLER_BASE + "/provider-context");
  }
}

export default new ProviderDataService();
