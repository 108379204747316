import { BehaviorSubject } from "rxjs";
import http from "@/services/HttpCommons";
import axios, { AxiosError, AxiosResponse } from "axios";
import router from "@/router";
import LoginRequestModel from "@/models/Requests/LoginRequest.model";
import LoginResponseModel from "@/models/Responses/LoginResponse.model";
import { Role } from "@/helper/Role";
import * as Msal from "msal";
import ErrorException from "@/models/ErrorException.model";
import { ActionTypes } from "@/store";
import { appContext } from "@/main";
import {
  AdministratorPermission,
  OperatorPermission,
} from "@/helper/Permission";
import PasswordRecoveryRequestModel from "@/models/Requests/PasswordRecoveryRequest.model";
import PasswordRecoveryResponseModel from "@/models/Responses/PasswordRecoveryResponse.model";
import PasswordResetResponseModel from "@/models/Responses/PasswordResetResponse.model";
import PasswordResetRequestModel from "@/models/Requests/PasswordResetRequest.model";
import PasswordChangeResponseModel from "@/models/Responses/PasswordChangeResponse.model";
import PasswordChangeRequestModel from "@/models/Requests/PasswordChangeRequest.model";
import RefreshAccessTokenRequestModel from "@/models/Requests/RefreshAccessTokenRequest.model";
import RefreshAccessTokenResponseModel from "@/models/Responses/RefreshAccessTokenResponse.model";

export const currentUserSubject = new BehaviorSubject(
  JSON.parse(<string>localStorage.getItem("currentUser"))
);

class AuthenticationDataService {
  private CONTROLLER_BASE = "/Authentication";

  private msal: Msal.UserAgentApplication;

  private currentUser = currentUserSubject.asObservable();
  get currentUserValue(): LoginResponseModel {
    return currentUserSubject.value;
  }

  get isUserAdmin(): boolean {
    return (
      this.currentUserValue &&
      this.currentUserValue.roles.includes(Role.Administrator)
    );
  }

  get isUserProvider(): boolean {
    return (
      this.currentUserValue &&
      this.currentUserValue.roles.includes(Role.Provider)
    );
  }

  get isUserOperator(): boolean {
    return (
      this.currentUserValue &&
      this.currentUserValue.roles.includes(Role.Operator)
    );
  }

  canUserAccess(
    userPermissions: (AdministratorPermission | OperatorPermission)[]
  ): boolean {
    return this.currentUserValue?.permissions?.some((permission) =>
      userPermissions.some((userPermission) => userPermission === permission)
    );
  }

  constructor() {
    const configAd = {
      auth: {
        clientId: process.env.VUE_APP_AZURE_AD_CLIENT_ID,
        authority:
          "https://login.microsoftonline.com/" +
          process.env.VUE_APP_AZURE_AD_TENANT_ID +
          "/",
        redirectUri: process.env.VUE_APP_AZURE_AD_REDIRECT_URI,
        navigateToLoginRequestUrl: false,
        validateAuthority: false,
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: true,
        },
      },
    } as Msal.Configuration;
    this.msal = new Msal.UserAgentApplication(configAd);

    this.msal.handleRedirectCallback(
      (tokenReceivedCallback) => {
        setTimeout(() => {
          this.dispatchLoginAd(tokenReceivedCallback.idToken.rawIdToken);
        }, 10);
      },
      (errorReceivedCallback) => {
        console.error(errorReceivedCallback);
        if (errorReceivedCallback.errorCode === "login_progress_error") {
          this.redirectLoginAd();
          return;
        }

        appContext.config.globalProperties.$toast.error(
          "Erro interno de autenticação. Tente novamente!"
        );
      }
    );
  }

  login(data: LoginRequestModel): Promise<AxiosResponse<LoginResponseModel>> {
    return http.post(this.CONTROLLER_BASE + "/login", data).then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user.data));
      currentUserSubject.next(user.data);

      return user;
    });
  }

  refreshAccessToken(): Promise<
    AxiosResponse<RefreshAccessTokenResponseModel>
  > {
    const user = JSON.parse(
      <string>localStorage.getItem("currentUser")
    ) as LoginResponseModel;

    return http
      .post(this.CONTROLLER_BASE + "/refresh-access-token", {
        accessToken: user.accessToken,
        refreshToken: user.refreshToken,
      } as RefreshAccessTokenRequestModel)
      .then((user: AxiosResponse<RefreshAccessTokenResponseModel>) => {
        const storedUser = JSON.parse(
          <string>localStorage.getItem("currentUser")
        ) as LoginResponseModel;

        storedUser.accessToken = user.data.accessToken;
        storedUser.refreshToken = user.data.refreshToken;

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("currentUser", JSON.stringify(storedUser));
        currentUserSubject.next(storedUser);

        return user;
      })
      .catch((error) => {
        appContext.config.globalProperties.$toast.error("Sessão expirada");
        throw error;
      });
  }

  getLoginFromAzureIdentity(
    accessToken: string
  ): Promise<AxiosResponse<LoginResponseModel>> {
    return http
      .get(this.CONTROLLER_BASE + "/get-login-from-azure-identity", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("currentUser", JSON.stringify(user.data));
        currentUserSubject.next(user.data);

        return user;
      });
  }

  redirectLoginAd() {
    const userRequest = {
      scopes: ["user.read"],
      prompt: "select_account",
      forceRefresh: true,
      redirectUri: process.env.VUE_APP_AZURE_AD_REDIRECT_URI,
    } as Msal.AuthenticationParameters;
    this.msal.loginRedirect(userRequest);
  }

  popupLoginAd(): Promise<AxiosResponse<LoginResponseModel> | null> {
    const userRequest = {
      scopes: ["user.read"],
      prompt: "select_account",
      forceRefresh: true,
      redirectUri: process.env.VUE_APP_AZURE_AD_REDIRECT_URI,
    } as Msal.AuthenticationParameters;

    return this.msal.loginPopup(userRequest).then((res) => {
      return this.getLoginFromAzureIdentity(res.idToken.rawIdToken);
      // return app
      //   .acquireTokenSilent(userRequest)
      //   .then((response) => {
      //     return this.getLoginFromAzureIdentity(response.accessToken);
      //   })
      //   .catch((err) => {
      //     // could also check if err instance of InteractionRequiredAuthError if you can import the class.
      //     if (err.name === "InteractionRequiredAuthError") {
      //       return app.acquireTokenPopup(userRequest).then((response) => {
      //         return this.getLoginFromAzureIdentity(response.accessToken);
      //       });
      //     }
      //     throw err;
      //   });
    });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    currentUserSubject.next(null);

    localStorage.clear();
  }

  private dispatchLoginAd(token: string) {
    appContext.config.globalProperties.$store
      .dispatch(ActionTypes.LOGIN_AD, token)
      .then((resp: AxiosResponse<LoginResponseModel>) => {
        if (resp && resp.data) {
          const redirectUrl =
            router.currentRoute.value.query.redirect?.toString() || "/";
          router.push({ path: redirectUrl });
        } else {
          appContext.config.globalProperties.$toast.error(
            "Erro interno de autenticação. Tente novamente!"
          );
        }
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          // Access to config, request, and response
          const err = error as AxiosError;
          if (err.response) {
            // Just a stock error
            const errorException = err.response.data as ErrorException;

            if (errorException.message) {
              appContext.config.globalProperties.$toast.error(
                errorException.message
              );
              return;
            }

            if (err.response.data.includes("IDW10201")) {
              this.dispatchLoginAd(token);
            }
          }
        }
      });
  }

  passwordRecovery(
    data: PasswordRecoveryRequestModel
  ): Promise<AxiosResponse<PasswordRecoveryResponseModel>> {
    return http.post(
      this.CONTROLLER_BASE + "/get-email-for-password-recovery",
      data
    );
  }

  passwordReset(
    data: PasswordResetRequestModel
  ): Promise<AxiosResponse<PasswordResetResponseModel>> {
    return http.post(this.CONTROLLER_BASE + "/submit-user-reset-pwd", data);
  }

  passwordChange(
    data: PasswordChangeRequestModel
  ): Promise<AxiosResponse<PasswordChangeResponseModel>> {
    return http.post(this.CONTROLLER_BASE + "/submit-user-change-pwd", data);
  }
}

export default new AuthenticationDataService();
