import { createStore } from "vuex";
import LoginRequestModel from "@/models/Requests/LoginRequest.model";
import LoginResponseModel from "@/models/Responses/LoginResponse.model";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import { AxiosError, AxiosResponse } from "axios";
import { Role } from "@/helper/Role";
import RefreshAccessTokenResponseModel from "@/models/Responses/RefreshAccessTokenResponse.model";

export enum MutationTypes {
  SET_LOADING = "SET_LOADING",
  UNSET_LOADING = "UNSET_LOADING",
  TOGGLE_LOADING = "TOGGLE_LOADING",
  LOGIN = "LOGIN",
  LOGIN_AD = "LOGIN_AD",
  LOGOUT = "LOGOUT",
  AUTHORIZATION_SUCCESS = "AUTHORIZATION_SUCCESS",
  AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR",
  REFRESH_TOKEN = "REFRESH_TOKEN",
  SET_TITTLE = "SET_TITTLE",
}

export enum ActionTypes {
  SET_LOADING = "SET_LOADING",
  UNSET_LOADING = "UNSET_LOADING",
  TOGGLE_LOADING = "TOGGLE_LOADING",
  LOGIN = "LOGIN",
  LOGIN_AD = "LOGIN_AD",
  LOGOUT = "LOGOUT",
  REFRESH_TOKEN = "REFRESH_TOKEN",
}

export default createStore({
  state: {
    isLoading: false,
    loadCount: 0,
    pageTittle: "",
    isUserLoggedIn: localStorage.getItem("currentUser") ? true : false,
    currentUser: localStorage.getItem("currentUser")
      ? (JSON.parse(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          localStorage.getItem("currentUser")!
        ) as LoginResponseModel)
      : ({} as LoginResponseModel),
  },
  getters: {
    isLoading(state): boolean {
      return state.isLoading;
    },
    getTitle(state): string {
      return state.pageTittle;
    },
    isUserLoggedIn(state): boolean {
      return state.isUserLoggedIn;
    },
    getCurrentUser(state): LoginResponseModel {
      return state.currentUser;
    },
    getCurrentUserName(state): string {
      return state.currentUser.username;
    },
    isUserAdmin(state): boolean {
      return (
        state.currentUser &&
        state.currentUser.roles.some((role) => role === Role.Administrator)
      );
    },
    isUserProvider(state): boolean {
      return (
        state.currentUser &&
        state.currentUser.roles.some((role) => role === Role.Provider)
      );
    },
    isUserOperator(state): boolean {
      return (
        state.currentUser &&
        state.currentUser.roles.some((role) => role === Role.Operator)
      );
    },
  },
  mutations: {
    // convention to uppercase mutation names
    SET_LOADING(state) {
      state.loadCount++;
      state.isLoading = state.loadCount > 0;
    },
    UNSET_LOADING(state) {
      state.loadCount--;
      state.isLoading = state.loadCount > 0;
    },
    TOGGLE_LOADING(state) {
      state.isLoading = !state.isLoading;
    },
    SET_TITTLE(state, payload: string) {
      state.pageTittle = payload;
    },
    LOGIN(state, payload: LoginResponseModel) {
      state.isUserLoggedIn = true;
      state.currentUser = payload;
    },
    REFRESH_TOKEN(state, payload: RefreshAccessTokenResponseModel) {
      state.isUserLoggedIn = true;
      state.currentUser = {
        ...state.currentUser,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      } as LoginResponseModel;
    },
    LOGIN_AD(state, payload: LoginResponseModel) {
      state.isUserLoggedIn = true;
      state.currentUser = payload;
    },
    LOGOUT(state) {
      state.isUserLoggedIn = false;
      state.currentUser = {} as LoginResponseModel;
    },
  },
  actions: {
    async SET_LOADING({ commit }) {
      //make some kind of ajax request
      //   try {
      //     await doAjaxRequest(payload);
      //     // can commit multiple mutations in an action
      //     commit("INSERT_POST", payload);
      //     commit("INCREMENT_USER_POSTS_COUNT");
      //   } catch (error) {
      //     commit("INSERT_ERROR", error);
      //   }
      commit(MutationTypes.SET_LOADING);
    },
    async UNSET_LOADING({ commit }) {
      //make some kind of ajax request
      //   try {
      //     await doAjaxRequest(payload);
      //     // can commit multiple mutations in an action
      //     commit("INSERT_POST", payload);
      //     commit("INCREMENT_USER_POSTS_COUNT");
      //   } catch (error) {
      //     commit("INSERT_ERROR", error);
      //   }
      commit(MutationTypes.UNSET_LOADING);
    },
    async TOGGLE_LOADING({ commit }) {
      commit(MutationTypes.TOGGLE_LOADING);
    },
    async SET_TITLE({ commit }, payload: string) {
      commit(MutationTypes.SET_TITTLE, payload);
    },
    async LOGIN({ commit }, loginRequest: LoginRequestModel) {
      return new Promise((resolve, reject) => {
        AuthenticationDataService.login(loginRequest)
          .then((resp: AxiosResponse<LoginResponseModel>) => {
            commit(MutationTypes.LOGIN, resp.data);
            resolve(resp);
          })
          .catch((error: Error | AxiosError) => {
            commit(MutationTypes.LOGOUT);
            AuthenticationDataService.logout();
            reject(error);
          });
      });
    },
    async LOGIN_AD({ commit }, payload: string) {
      return new Promise((resolve, reject) => {
        // AuthenticationDataService.popuploginAd()
        AuthenticationDataService.getLoginFromAzureIdentity(payload)
          .then((resp: AxiosResponse<LoginResponseModel> | null) => {
            commit(MutationTypes.LOGIN_AD, resp?.data);
            resolve(resp);
          })
          .catch((error: Error | AxiosError) => {
            commit(MutationTypes.LOGOUT);
            AuthenticationDataService.logout();
            reject(error);
          });
      });
    },
    async LOGOUT({ commit }) {
      return new Promise<void>((resolve) => {
        commit(MutationTypes.LOGOUT);
        AuthenticationDataService.logout();
        resolve();
      });
    },
    async REFRESH_TOKEN({ commit }) {
      return new Promise((resolve, reject) => {
        AuthenticationDataService.refreshAccessToken()
          .then((resp: AxiosResponse<RefreshAccessTokenResponseModel>) => {
            commit(MutationTypes.REFRESH_TOKEN, resp.data);
            resolve(resp);
          })
          .catch((error: Error | AxiosError) => {
            commit(MutationTypes.LOGOUT);
            AuthenticationDataService.logout();
            reject(error);
          });
      });
    },
  },
});
