import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import NoPermissions from "@/components/pages/AppNoPermissions.vue";
import Login from "@/components/pages/AppLogin.vue";
import PasswordRecovery from "@/components/pages/AppPasswordRecovery.vue";
import PasswordReset from "@/components/pages/AppPasswordReset.vue";
import PasswordChange from "@/components/pages/AppPasswordChange.vue";
import ApplicationForm from "@/components/pages/ApplicationForm.vue";
import ApplicationManagement from "@/components/pages/ApplicationManagement.vue";
import ApplicationAnnualMaintenance from "@/components/pages/ApplicationAnnualMaintenance.vue";
import ApplicationListing from "@/components/pages/ApplicationListing.vue";
import ApplicationDetail from "@/components/pages/ApplicationDetail.vue";
import ApplicationRecheckForm from "@/components/pages/ApplicationRecheckForm.vue";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import SessionService from "@/services/SessionService";
import NavigationState from "@/models/NavigationState.model";
import UserListing from "@/components/pages/UserListing.vue";
import UserDetail from "@/components/pages/UserDetail.vue";
import ProviderEdit from "@/components/pages/ProviderEdit.vue";
import ProviderListing from "@/components/pages/ProviderListing.vue";
import UserProviderListing from "@/components/pages/UserProviderListing.vue";
import {
  AdministratorPermission,
  OperatorPermission,
} from "@/helper/Permission";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    redirect: () => {
      if (AuthenticationDataService.isUserAdmin) {
        const hasApplicationsPermission =
          AuthenticationDataService.canUserAccess([
            AdministratorPermission.Applications_Reader,
            AdministratorPermission.Applications_Updater,
          ]);
        if (hasApplicationsPermission) {
          return "/applications";
        }
        const hasUsersPermission = AuthenticationDataService.canUserAccess([
          AdministratorPermission.Users_Reader,
          AdministratorPermission.Users_Writer,
          AdministratorPermission.Users_Updater,
        ]);
        if (hasUsersPermission) {
          return "/users";
        }
        const hasProvidersPermission = AuthenticationDataService.canUserAccess([
          AdministratorPermission.Providers_Reader,
          AdministratorPermission.Providers_Writer,
          AdministratorPermission.Providers_Updater,
        ]);
        if (hasProvidersPermission) {
          return "/providers";
        }
        return "noPermissions";
      } else if (
        AuthenticationDataService.isUserProvider ||
        AuthenticationDataService.isUserOperator
      ) {
        const hasApplicationsPermission =
          AuthenticationDataService.canUserAccess([
            OperatorPermission.ApplicationsModule_Writer,
          ]);
        if (hasApplicationsPermission) {
          return "/form";
        }
        const hasApplicationManagementPermission =
          AuthenticationDataService.canUserAccess([
            OperatorPermission.ApplicationsModule_Reader,
            OperatorPermission.ApplicationsModule_Updater,
          ]);
        if (hasApplicationManagementPermission) {
          return "/applicationManagement";
        }
        const hasAnnualMaintenancePermission =
          AuthenticationDataService.canUserAccess([
            OperatorPermission.AnnualMaintenanceModule_Reader,
            OperatorPermission.AnnualMaintenanceModule_Writer,
            OperatorPermission.AnnualMaintenanceModule_Updater,
          ]);
        if (hasAnnualMaintenancePermission) {
          return "/applicationAnnualMaintenance";
        }
        return "noPermissions";
      }

      //Is it Login AD in progress. Redirect to default url for admin users
      return "/applications";
    },
  },
  {
    path: "/noPermissions",
    name: "NoPermissions",
    component: NoPermissions,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/passwordRecovery",
    name: "PasswordRecovery",
    component: PasswordRecovery,
  },
  {
    path: "/passwordReset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/passwordChange",
    name: "PasswordChange",
    component: PasswordChange,
    beforeEnter: (to, from, next) => {
      const allowedUser =
        AuthenticationDataService.isUserProvider ||
        AuthenticationDataService.isUserOperator;
      if (!allowedUser) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/form",
    name: "Form",
    component: ApplicationForm,
    beforeEnter: (to, from, next) => {
      const allowedUser =
        AuthenticationDataService.isUserProvider ||
        AuthenticationDataService.isUserOperator;
      const hasPermission = AuthenticationDataService.canUserAccess([
        OperatorPermission.ApplicationsModule_Writer,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/applicationManagement",
    name: "ApplicationManagement",
    component: ApplicationManagement,
    beforeEnter: (to, from, next) => {
      const allowedUser =
        AuthenticationDataService.isUserProvider ||
        AuthenticationDataService.isUserOperator;
      const hasPermission = AuthenticationDataService.canUserAccess([
        OperatorPermission.ApplicationsModule_Reader,
        OperatorPermission.ApplicationsModule_Writer,
        OperatorPermission.ApplicationsModule_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/applicationAnnualMaintenance",
    name: "ApplicationAnnualMaintenance",
    component: ApplicationAnnualMaintenance,
    beforeEnter: (to, from, next) => {
      const allowedUser =
        AuthenticationDataService.isUserAdmin ||
        AuthenticationDataService.isUserProvider ||
        AuthenticationDataService.isUserOperator;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.AnnualMaintenance_Reader,
        OperatorPermission.AnnualMaintenanceModule_Reader,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/applicationAnnualMaintenance/:id/edit",
    name: "ApplicationAnnualMaintenanceEdit",
    component: ApplicationRecheckForm,
    beforeEnter: (to, from, next) => {
      const allowedUser =
        AuthenticationDataService.isUserProvider ||
        AuthenticationDataService.isUserOperator;
      const hasPermission = AuthenticationDataService.canUserAccess([
        OperatorPermission.AnnualMaintenanceModule_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/applications",
    name: "ApplicationListing",
    component: ApplicationListing,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Applications_Reader,
        AdministratorPermission.Applications_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/applications/:id/details",
    name: "ApplicationDetail",
    component: ApplicationDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Applications_Reader,
        AdministratorPermission.Applications_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/applications/:id/recheck/:recheckId/details",
    name: "ApplicationRecheckDetail",
    component: ApplicationDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Applications_Reader,
        AdministratorPermission.Applications_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/applicationManagement/:id/details",
    name: "ApplicationManagementDetail",
    component: ApplicationDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser =
        AuthenticationDataService.isUserProvider ||
        AuthenticationDataService.isUserOperator;
      const hasPermission = AuthenticationDataService.canUserAccess([
        OperatorPermission.ApplicationsModule_Reader,
        OperatorPermission.ApplicationsModule_Writer,
        OperatorPermission.ApplicationsModule_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/applicationManagement/:id/recheck/:recheckId/details",
    name: "ApplicationManagementRecheckDetail",
    component: ApplicationDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser =
        AuthenticationDataService.isUserProvider ||
        AuthenticationDataService.isUserOperator;
      const hasPermission = AuthenticationDataService.canUserAccess([
        OperatorPermission.ApplicationsModule_Reader,
        OperatorPermission.ApplicationsModule_Writer,
        OperatorPermission.ApplicationsModule_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/applicationManagement/:id/recheckForm",
    name: "ApplicationRecheckForm",
    component: ApplicationRecheckForm,
    beforeEnter: (to, from, next) => {
      const allowedUser =
        AuthenticationDataService.isUserProvider ||
        AuthenticationDataService.isUserOperator;
      const hasPermission = AuthenticationDataService.canUserAccess([
        OperatorPermission.ApplicationsModule_Writer,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/users",
    name: "UserListing",
    component: UserListing,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Users_Reader,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/users/create",
    name: "UserCreate",
    component: UserDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Users_Writer,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/users/:id/details",
    name: "UserDetail",
    component: UserDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Users_Reader,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/users/:id/edit",
    name: "UserEdit",
    component: UserDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Users_Reader,
        AdministratorPermission.Users_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/providers",
    name: "ProviderListing",
    component: ProviderListing,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Reader,
        AdministratorPermission.Providers_Writer,
        AdministratorPermission.Providers_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/providers/create",
    name: "ProviderCreate",
    component: ProviderEdit,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Writer,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/providers/:id/edit",
    name: "ProviderEdit",
    component: ProviderEdit,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/userProviders",
    name: "UserProviderListing",
    component: UserProviderListing,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Reader,
        AdministratorPermission.Providers_Writer,
        AdministratorPermission.Providers_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/userProviders/:id/details",
    name: "UserProviderDetail",
    component: UserDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Reader,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/userProviders/create",
    name: "UserProviderCreate",
    component: UserDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Writer,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/userProviders/:id/edit",
    name: "UserUserProviderEdit",
    component: UserDetail,
    beforeEnter: (to, from, next) => {
      const allowedUser = AuthenticationDataService.isUserAdmin;
      const hasPermission = AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Updater,
      ]);
      if (!allowedUser || !hasPermission) next({ name: "Home" });
      else next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const navigationState = {
    navigationTo: to.fullPath,
    navigationFrom: from.fullPath,
  } as NavigationState;

  SessionService.setNavigation(navigationState);

  if (
    to.name !== "Login" &&
    to.name !== "PasswordRecovery" &&
    to.name !== "PasswordReset" &&
    !AuthenticationDataService.currentUserValue
  )
    next({ name: "Login", query: { redirect: to.fullPath } });
  else next();
});

export default router;
