import http from "@/services/HttpCommons";
import CreateApplicationResponseModel from "@/models/Responses/CreateApplicationResponse.model";
import CreateApplicationRequestModel from "@/models/Requests/CreateApplicationRequest.model";
import { AxiosResponse } from "axios";
import PagedResponseModel from "@/models/Responses/PagedResponse.model";
import SearchEligibilityApplicationsResponseModel from "@/models/Responses/SearchEligibilityApplicationsResponse.model";
import SearchEligibilityApplicationsRequestModel from "@/models/Requests/SearchEligibilityApplicationsRequest.model";
import SetActivationApplicationResponseModel from "@/models/Responses/SetActivationApplicationResponse.model";
import SetActivationApplicationRequestModel from "@/models/Requests/SetActivationApplicationRequest.model";
import ApplicationActivationReasonsResponseModel from "@/models/Responses/ApplicationActivationReasonsResponse.model";
import CancelApplicationRequestModel from "@/models/Requests/CancelApplicationRequest.model";
import CancelApplicationResponseModel from "@/models/Responses/CancelApplicationResponse.model";
import ApplicationCancellationReasonsResponseModel from "@/models/Responses/ApplicationCancellationReasonsResponse.model";
import SearchApplicationBackofficeRequestModel from "@/models/Requests/SearchApplicationBackofficeRequest.model";
import SearchApplicationRequestModel from "@/models/Requests/SearchApplicationRequest.model";
import ApplicationResponseModel from "@/models/Responses/ApplicationResponse.model";
import ApplicationStatusesResponseModel from "@/models/Responses/ApplicationStatusesResponse.model";
import Application from "@/models/Application.model";
import SetEligibilityApplicationRequestModel from "@/models/Requests/SetEligibilityApplicationRequest.model";
import { ResourceFileType } from "@/models/Enums/ResouceFileType.enum";
import FileModel from "@/models/File.model";
import ApplicationInactivationReasonsResponseModel from "@/models/Responses/ApplicationInactivationReasonsResponse.model";
import RecheckApplicationResponseModel from "@/models/Responses/RecheckApplicationResponse.model";
import RecheckApplicationRequestModel from "@/models/Requests/RecheckApplicationRequest.model";
import GetApplicationRecheckRequestsResponseModel from "@/models/Responses/GetApplicationRecheckRequestsResponse.model";
import GetMailNotificationsResponseModel from "@/models/Responses/GetMailNotificationsResponse.model";
import SearchAnnualMaintenanceApplicationRequestModel from "@/models/Requests/SearchAnnualMaintenanceApplicationRequest.model";
import ExportAnnualMaintenanceApplicationResponseModel from "@/models/Responses/ExportAnnualMaintenanceApplicationResponse.model";

class ApplicationDataService {
  private CONTROLLER_BASE = "/Application";

  create(
    data: CreateApplicationRequestModel
  ): Promise<AxiosResponse<CreateApplicationResponseModel[]>> {
    return http.post(this.CONTROLLER_BASE + "/submit", data);
  }

  recheck(
    data: RecheckApplicationRequestModel
  ): Promise<AxiosResponse<RecheckApplicationResponseModel[]>> {
    return http.post(this.CONTROLLER_BASE + "/recheck", data);
  }

  updateAnnualRecheck(
    data: RecheckApplicationRequestModel
  ): Promise<AxiosResponse<RecheckApplicationResponseModel[]>> {
    return http.post(this.CONTROLLER_BASE + "/update-annual-recheck", data);
  }

  searchEligible(
    data: SearchEligibilityApplicationsRequestModel
  ): Promise<
    AxiosResponse<
      PagedResponseModel<SearchEligibilityApplicationsResponseModel>
    >
  > {
    return http.post(this.CONTROLLER_BASE + "/search-eligible", data);
  }

  searchBackoffice(
    data: SearchApplicationBackofficeRequestModel
  ): Promise<AxiosResponse<PagedResponseModel<ApplicationResponseModel>>> {
    return http.post(this.CONTROLLER_BASE + "/search-backoffice", data);
  }

  search(
    data: SearchApplicationRequestModel
  ): Promise<AxiosResponse<PagedResponseModel<ApplicationResponseModel>>> {
    return http.post(this.CONTROLLER_BASE + "/search", data);
  }

  searchAnnualMaintenanceApplications(
    data: SearchAnnualMaintenanceApplicationRequestModel
  ): Promise<AxiosResponse<PagedResponseModel<ApplicationResponseModel>>> {
    return http.post(
      this.CONTROLLER_BASE + "/search-annual-maintenance-applications",
      data
    );
  }

  getAnnualMaintenanceApplicationIdsForProcessing(
    data: SearchAnnualMaintenanceApplicationRequestModel
  ): Promise<AxiosResponse<Array<string>>> {
    return http.post(
      this.CONTROLLER_BASE +
        "/get-annual-maintenance-application-ids-for-processing",
      data
    );
  }

  setActivations(
    data: SetActivationApplicationRequestModel
  ): Promise<AxiosResponse<SetActivationApplicationResponseModel>> {
    return http.post(this.CONTROLLER_BASE + "/set-activation-status", data);
  }

  getActivationReasons(): Promise<
    AxiosResponse<ApplicationActivationReasonsResponseModel>
  > {
    return http.get(this.CONTROLLER_BASE + "/get-activation-reasons");
  }

  getCancellationReasons(): Promise<
    AxiosResponse<ApplicationCancellationReasonsResponseModel>
  > {
    return http.get(this.CONTROLLER_BASE + "/get-cancellation-reasons");
  }

  getDeactivationReasons(): Promise<
    AxiosResponse<ApplicationInactivationReasonsResponseModel>
  > {
    return http.get(this.CONTROLLER_BASE + "/get-inactivation-reasons");
  }

  getApplicationStatusBackoffice(): Promise<
    AxiosResponse<ApplicationStatusesResponseModel>
  > {
    return http.get(this.CONTROLLER_BASE + "/get-status-backoffice");
  }

  getApplicationStatus(): Promise<
    AxiosResponse<ApplicationStatusesResponseModel>
  > {
    return http.get(this.CONTROLLER_BASE + "/get-status");
  }

  cancelApplication(
    data: CancelApplicationRequestModel
  ): Promise<AxiosResponse<CancelApplicationResponseModel>> {
    return http.post(this.CONTROLLER_BASE + "/cancel", data);
  }

  getById(id: string): Promise<AxiosResponse<Application>> {
    return http.get(this.CONTROLLER_BASE + "/get-by-id", {
      params: { applicationId: id },
    });
  }

  getFiles(
    applicationId: string | null,
    resourceFileType: ResourceFileType | null
  ): Promise<AxiosResponse<FileModel[]>> {
    return http.get(this.CONTROLLER_BASE + "/get-files", {
      params: { applicationId, resourceFileType },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadResourceFile(resourceFileId: number): Promise<AxiosResponse<any>> {
    return http.get(this.CONTROLLER_BASE + "/download-resource-file", {
      params: { resourceFileId },
      responseType: "blob",
    });
  }

  setEligibility(
    data: SetEligibilityApplicationRequestModel
  ): Promise<AxiosResponse<boolean>> {
    return http.post(this.CONTROLLER_BASE + "/set-eligibility", data);
  }

  isApplicationBeingRechecked(id: string): Promise<AxiosResponse<boolean>> {
    return http.get(
      this.CONTROLLER_BASE + "/exist-recheck-application-in-progress",
      {
        params: { applicationId: id },
      }
    );
  }

  getRecheckRequestsByApplicationId(
    applicationId: string
  ): Promise<AxiosResponse<GetApplicationRecheckRequestsResponseModel>> {
    return http.get(
      this.CONTROLLER_BASE + "/get-recheck-requests-by-application-id",
      {
        params: { applicationId: applicationId },
      }
    );
  }

  getMailNotificationsByApplicationId(
    applicationId: string
  ): Promise<AxiosResponse<GetMailNotificationsResponseModel>> {
    return http.get(
      this.CONTROLLER_BASE + "/get-mail-notifications-by-application-id",
      {
        params: { applicationId: applicationId },
      }
    );
  }

  deleteApplicationByApplicationId(
    applicationId: string
  ): Promise<AxiosResponse<boolean>> {
    return http.delete(
      this.CONTROLLER_BASE + "/delete-application-by-application-id",
      { params: { applicationId: applicationId } }
    );
  }

  exportAnnualMaintenanceApplicationExcel(
    data: SearchAnnualMaintenanceApplicationRequestModel
  ): Promise<AxiosResponse<ExportAnnualMaintenanceApplicationResponseModel>> {
    return http.post(
      this.CONTROLLER_BASE + "/export-annual-maintenance-application-excel",
      data
    );
  }

  exportAnonymizedApplicationsExcel(): Promise<AxiosResponse<any>> {
    return http.get(
      this.CONTROLLER_BASE + "/export-anonymized-application-excel",
      {
        responseType: "blob",
      }
    );
  }
}

export default new ApplicationDataService();
