
import { defineComponent, PropType, ref } from "vue";
import { configure, Form, Field, FormState } from "vee-validate";
import ReasonModel from "@/models/Reason.model";
import ApplicationDataService from "@/services/ApplicationDataService";
import axios, { AxiosError, AxiosResponse } from "axios";
import ValidationMessage from "@/models/ValidationMessage.model";
import SetActivationApplicationRequestModel from "@/models/Requests/SetActivationApplicationRequest.model";
import ApplicationActivationModel from "@/models/ApplicationActivation.model";
import SetActivationApplicationResponseModel from "@/models/Responses/SetActivationApplicationResponse.model";
import ApplicationInactivationReasonsResponseModel from "@/models/Responses/ApplicationInactivationReasonsResponse.model";
import Application from "@/models/Application.model";

export default defineComponent({
  name: "DeactivateApplicationModal",
  components: {
    Form,
    Field,
  },
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
    activationDate: {
      type: Date,
    },
  },
  data() {
    return {
      defaultReasonId: -1,
      reasons: [] as Array<ReasonModel>,
      formData: {
        applicationId: this.application?.id,
        activationEndDate: undefined,
        activationEndReasonId: -1,
      } as ApplicationActivationModel,
    };
  },
  computed: {
    schema() {
      const schema = {};

      return schema;
    },
    datepickerMinDate() {
      if (this.activationDate) {
        var auxDate = new Date(this.activationDate);
        var activationDate = new Date(
          auxDate.getFullYear(),
          auxDate.getMonth(),
          auxDate.getDate()
        );

        return activationDate;
      }

      return null;
    },
    datepickerMaxDate(): Date {
      return new Date();
    },
    datePickerStartTime() {
      return { hours: 0, minutes: 0 };
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const deactivationForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    const datePickerFormat = (date: Date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${String(day).padStart(2, "0")}/${String(month).padStart(
        2,
        "0"
      )}/${year}`;
    };

    return {
      deactivationForm,
      datePickerFormat,
    };
  },
  methods: {
    yes() {
      this.simpleDatePickerValidation().then((valid) => {
        if (valid) {
          var clonedModel = {
            applicationId: this.formData.applicationId,
            activationEndDate: this.formData.activationEndDate,
            activationEndReasonId:
              this.formData.activationEndReasonId === this.defaultReasonId
                ? undefined
                : this.formData.activationEndReasonId,
          };
          if (clonedModel.activationEndReasonId === this.defaultReasonId) {
            clonedModel.activationEndReasonId = undefined;
          }
          const activationRequest = {
            applicationActivations: [
              clonedModel,
            ] as Array<ApplicationActivationModel>,
          } as SetActivationApplicationRequestModel;

          ApplicationDataService.setActivations(activationRequest)
            .then(
              (resp: AxiosResponse<SetActivationApplicationResponseModel>) => {
                if (resp) {
                  if (resp.data && resp.data.data.every((x) => x.success)) {
                    this.$toast.success("TSI desativada com sucesso.");
                    this.$emit("yes");
                  } else {
                    this.$toast.error(
                      "Ocorreu um erro na desativação da TSI, por favor tente novamente."
                    );
                  }
                }
              }
            )
            .catch((error: Error | AxiosError) => {
              if (axios.isAxiosError(error)) {
                // Access to config, request, and response
                const err = error as AxiosError;
                if (err.response && err.response.status === 400) {
                  // Just a stock error
                  const validationMessage = err.response
                    .data as ValidationMessage;
                  if (
                    validationMessage &&
                    validationMessage.errors &&
                    validationMessage.errors.length &&
                    validationMessage.errors[0].errors &&
                    validationMessage.errors[0].errors.length
                  ) {
                    this.$toast.error(validationMessage.errors[0].errors[0]);
                  }
                }
              } else {
                this.$toast.error(
                  "Ocorreu um erro na desativação da TSI, por favor tente novamente."
                );
              }
            });
        }
      });
    },
    no() {
      this.$emit("no");
    },
    close() {
      this.$emit("close");
    },
    getReasons() {
      ApplicationDataService.getDeactivationReasons()
        .then(
          (
            response: AxiosResponse<ApplicationInactivationReasonsResponseModel>
          ) => {
            if (response && response.data) {
              this.reasons = response.data.data;
            }
          }
        )
        .catch((e: Error) => {
          console.log(e);
        });
    },
    simpleDatePickerValidation(): Promise<boolean> {
      return new Promise((resolve) => {
        if (this.formData.activationEndDate) {
          this.deactivationForm?.setFieldError("endDate", undefined);
          resolve(true);
        } else {
          this.deactivationForm?.setFieldError(
            "endDate",
            "O campo é obrigatório."
          );
          resolve(false);
        }
      });
    },
    validate() {
      this.simpleDatePickerValidation().then((resp) => {
        console.log(resp);
      });
    },
  },
  mounted() {
    this.getReasons();
    this.$emit("scrollToTop");
  },
});
