
import { defineComponent, ref } from "vue";
import { Form, Field, configure, FormState } from "vee-validate";
import SearchProviderUsersRequestModel from "@/models/Requests/SearchProviderUsersRequest.model";
import Pager from "@/models/Pager.model";
import SessionService from "@/services/SessionService";
import { AxiosError, AxiosResponse } from "axios";
import PagedResponseModel from "@/models/Responses/PagedResponse.model";
import Pagination from "@/components/Pagination.vue";
import UserStatusesResponseModel from "@/models/Responses/UserStatusesResponse.model";
import UserStatusTypeModel from "@/models/UserStatusType.model";
import * as RoutingHelpers from "@/helper/RoutingHelpers";
import UserDataService from "@/services/UserDataService";
import { AdministratorPermission } from "@/helper/Permission";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import UserResponseModel from "@/models/Responses/UserResponse.model";
import SearchProvidersRequestModel from "@/models/Requests/SearchProvidersRequest.model";
import ProviderResponseModel from "@/models/Responses/ProviderResponse.model";

const CURRENT_ROUTE = "userProviders";
const ROUTE_SEARCH = "userProviders";
const DETAILS = "details";
const CREATE = "create";

export default defineComponent({
  name: "UserListing",
  components: {
    Form,
    Field,
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      defaultUserId: -1,
      defaultUserStatusId: -1,
      searchModel: {} as SearchProviderUsersRequestModel,
      currentSearchModel: {} as SearchProviderUsersRequestModel,
      usersStatuses: [] as Array<UserStatusTypeModel>,
      userProviders: [] as Array<ProviderResponseModel>,
      users: [] as Array<UserResponseModel>,
      totalUsers: 0,
    };
  },
  computed: {
    schema() {
      const schema = {};

      return schema;
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const searchForm = ref<null | {
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      searchForm,
    };
  },
  methods: {
    canAccessReader() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Reader,
      ]);
    },
    canAccessWriter() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Writer,
      ]);
    },
    canAccessUpdater() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Updater,
      ]);
    },
    onChangePage(pager: Pager) {
      if (
        this.currentPage !== pager.currentPage ||
        this.pageSize !== pager.pageSize
      ) {
        this.currentPage = pager.currentPage;
        this.pageSize = pager.pageSize;
        this.search();
      }
    },
    newSearch() {
      this.searchForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            this.currentPage = 1;
            this.currentSearchModel = {
              userId: this.searchModel.userId,
              username: this.searchModel.username,
              userStatusId:
                this.searchModel.userStatusId === this.defaultUserStatusId
                  ? undefined
                  : this.searchModel.userStatusId,
            } as SearchProviderUsersRequestModel;
            this.search();
          }
        })
        .catch((error: Error) => {
          console.error(error);
        });
    },
    search() {
      UserDataService.searchProviderUsers(this.getSearchModel())
        .then((resp: AxiosResponse<PagedResponseModel<UserResponseModel>>) => {
          if (resp) {
            if (resp.data) {
              this.users = resp.data.items;
              this.totalUsers = resp.data.totalItems;
            } else {
              this.$toast.error(
                "Ocorreu um erro na obtenção dos dados, por favor tente novamente."
              );
            }
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((error: Error | AxiosError) => {
          this.$toast.error(
            "Ocorreu um erro na obtenção dos dados, por favor tente novamente."
          );
        });
    },
    resetSearch() {
      this.searchForm?.resetForm();
      this.currentPage = 1;
      this.pageSize = 10;
      this.searchModel = {
        userId: -1,
        username: undefined,
        userStatusId: -1,
      } as SearchProviderUsersRequestModel;
    },
    validatePageNumber() {
      if (this.users.length - 1 == 0 && this.totalUsers - 1 > 0) {
        this.currentPage -= 1;
      }
    },
    getUserStatuses() {
      UserDataService.getUserStatuses()
        .then((response: AxiosResponse<UserStatusesResponseModel>) => {
          if (response && response.data) {
            this.usersStatuses = response.data.data;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    getUserProviders() {
      const request = {
        userStatusId: 1, //Active
        pageNumber: 1,
        pageSize: 999,
      } as SearchProvidersRequestModel;
      UserDataService.searchProviders(request)
        .then(
          (
            response: AxiosResponse<PagedResponseModel<ProviderResponseModel>>
          ) => {
            if (response && response.data) {
              this.userProviders = response.data.items;
            }
          }
        )
        .catch((e: Error) => {
          console.error(e);
        });
    },
    goToUserDetails(userId: string) {
      this.saveCurrentSearch();
      this.$router.push("/" + CURRENT_ROUTE + "/" + userId + "/" + DETAILS);
    },
    goToUserCreate() {
      this.saveCurrentSearch();
      this.$router.push("/" + CURRENT_ROUTE + "/" + CREATE);
    },
    saveCurrentSearch() {
      const searchObj = {
        currentSearch: this.currentSearchModel,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      SessionService.changeSearch(ROUTE_SEARCH, searchObj);
    },
    getSearchModel(): SearchProviderUsersRequestModel {
      const newSearchModel = {
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        userId: this.currentSearchModel.userId,
        username: this.currentSearchModel.username,
        userStatusId: this.currentSearchModel.userStatusId,
      } as SearchProviderUsersRequestModel;

      return newSearchModel;
    },
  },
  mounted() {
    const sessionState = SessionService.getNavigation();
    const isFromDetail = RoutingHelpers.compareUrl(
      [CURRENT_ROUTE, RoutingHelpers.STRING_VALIDATION],
      sessionState.navigationFrom
    );
    if (isFromDetail) {
      const lastSearch = SessionService.getSearch(ROUTE_SEARCH);
      if (lastSearch) {
        this.currentPage = lastSearch.currentPage;
        this.pageSize = lastSearch.pageSize;
        this.searchModel = JSON.parse(JSON.stringify(lastSearch.currentSearch));
        this.currentSearchModel = JSON.parse(JSON.stringify(this.searchModel));
      }
    } else {
      SessionService.resetSearch();
      this.currentSearchModel = JSON.parse(JSON.stringify(this.searchModel));
    }
    this.searchModel.userId =
      this.searchModel.userId === undefined
        ? this.defaultUserId
        : this.searchModel.userId;
    this.getUserStatuses();
    this.getUserProviders();
    this.search();
    this.$emit("scrollToTop");
  },
});
