import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0643bec1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirmation-modal" }
const _hoisted_2 = { class: "confirmation-modal-inner" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "confirmation-modal-title" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "confirmation-modal-subtitle" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "confirmation-modal-animation" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, { name: "modal-animation-inner" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("i", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
                class: "clickable icon icon-cross"
              }),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.confirmationTitle), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.confirmationSubtitle), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("button", {
                      class: "modal-button yes-button",
                      type: "button",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.accept && _ctx.accept(...args)))
                    }, _toDisplayString(_ctx.acceptText), 1),
                    _createElementVNode("button", {
                      class: "modal-button no-button",
                      type: "button",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                    }, _toDisplayString(_ctx.denyText), 1)
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}