const MILLISECONDS_IN_A_DAY = 86400000; // 24 * 60 * 60 * 1000

export function getDaysBetween(after: Date, before: Date): number {
  return Math.floor(
    Math.round(after.getTime() - before.getTime()) / MILLISECONDS_IN_A_DAY
  );
}

export function getFirstDateOfYear(year: number): Date {
  return new Date(year, 0, 1, 0, 0);
}

export function getLastDateOfYear(year: number): Date {
  return new Date(year, 11, 31, 23, 59);
}

export function getEarliestOfDates(date1: Date, date2: Date): Date {
  return date1 <= date2 ? date1 : date2;
}

export function getLatestOfDates(date1: Date, date2: Date): Date {
  return date1 >= date2 ? date1 : date2;
}
