import AnnualMaintenancePeriodModel from "@/models/AnnualMaintenancePeriod.model";
import AnnualMaintenanceUserNotificationModel from "@/models/AnnualMaintenanceUserNotification.model";
import CreateAnnualMaintenanceUserNotificationRequestModel from "@/models/Requests/CreateAnnualMaintenanceUserNotificationRequest.model";
import ProcessAnnualMaintenanceRequestModel from "@/models/Requests/ProcessAnnualMaintenanceRequest.model";
import RegisterAnnualMaintenanceRequestModel from "@/models/Requests/RegisterAnnualMaintenanceRequest.model";
import UpdateAnnualMaintenancePeriodRequestModel from "@/models/Requests/UpdateAnnualMaintenancePeriodRequest.model";
import UpdateAnnualMaintenanceUserNotificationRequestModel from "@/models/Requests/UpdateAnnualMaintenanceUserNotificationRequest.model";
import AnnualMaintenancePeriodResponseModel from "@/models/Responses/AnnualMaintenancePeriodResponse.model";
import CreateAnnualMaintenanceUserNotificationResponseModel from "@/models/Responses/CreateAnnualMaintenanceUserNotificationResponse.model";
import ProcessApplicationResponseModel from "@/models/Responses/ProcessApplicationResponse.model";
import RegisterAnnualMaintenanceResponseModel from "@/models/Responses/RegisterAnnualMaintenanceResponse.model";
import UpdateAnnualMaintenancePeriodResponseModel from "@/models/Responses/UpdateAnnualMaintenancePeriodResponse.model";
import UpdateAnnualMaintenanceUserNotificationResponseModel from "@/models/Responses/UpdateAnnualMaintenanceUserNotificationResponse.model";
import http from "@/services/HttpCommons";
import { AxiosResponse } from "axios";

class ApplicationAnnualMaintenanceDataService {
  private CONTROLLER_BASE = "/ApplicationAnnualMaintenance";

  downloadAnnualMaintenanceTemplate(): Promise<AxiosResponse<any>> {
    return http.get(this.CONTROLLER_BASE + "/download-template-file", {
      responseType: "blob",
    });
  }

  uploadAnnualMaintenanceTemplate(
    data: RegisterAnnualMaintenanceRequestModel
  ): Promise<AxiosResponse<RegisterAnnualMaintenanceResponseModel>> {
    return http.post(
      this.CONTROLLER_BASE + "/register-requests-from-file",
      data
    );
  }

  getNotificationEmailByProvider(): Promise<
    AxiosResponse<AnnualMaintenanceUserNotificationModel>
  > {
    return http.get(
      this.CONTROLLER_BASE + "/get-notification-email-by-provider"
    );
  }

  createNotificationEmail(
    data: CreateAnnualMaintenanceUserNotificationRequestModel
  ): Promise<
    AxiosResponse<CreateAnnualMaintenanceUserNotificationResponseModel>
  > {
    return http.post(this.CONTROLLER_BASE + "/create-notification-email", data);
  }

  updateNotificationEmail(
    data: UpdateAnnualMaintenanceUserNotificationRequestModel
  ): Promise<
    AxiosResponse<UpdateAnnualMaintenanceUserNotificationResponseModel>
  > {
    return http.post(this.CONTROLLER_BASE + "/update-notification-email", data);
  }

  haveAnyAnnualMaintenanceRequestsBeenProcessed(): Promise<
    AxiosResponse<boolean>
  > {
    return http.get(
      this.CONTROLLER_BASE +
        "/have-any-annual-maintenance-requests-been-processed"
    );
  }

  countApplicationsWithoutAnnualMaintenance(): Promise<AxiosResponse<number>> {
    return http.get(
      this.CONTROLLER_BASE +
        "/count-active-applications-without-annual-maintenance"
    );
  }

  getCurrentOrLastAnnualMaintenancePeriod(): Promise<
    AxiosResponse<AnnualMaintenancePeriodModel>
  > {
    return http.get(
      this.CONTROLLER_BASE + "/get-current-or-last-annual-maintenance-period"
    );
  }

  getCurrentOrNextAnnualMaintenancePeriod(): Promise<
    AxiosResponse<AnnualMaintenancePeriodModel>
  > {
    return http.get(
      this.CONTROLLER_BASE + "/get-current-or-next-annual-maintenance-period"
    );
  }

  processAnnualMaintenanceRequests(
    data: ProcessAnnualMaintenanceRequestModel
  ): Promise<AxiosResponse<Array<ProcessApplicationResponseModel>>> {
    return http.post(
      this.CONTROLLER_BASE + "/process-annual-maintenance-requests",
      data
    );
  }

  updateMaintenancePeriod(
    data: UpdateAnnualMaintenancePeriodRequestModel
  ): Promise<AxiosResponse<UpdateAnnualMaintenancePeriodResponseModel>> {
    return http.post(this.CONTROLLER_BASE + "/update-maintenance-period", data);
  }
}

export default new ApplicationAnnualMaintenanceDataService();
