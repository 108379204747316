import { createApp } from "vue";
import router from "@/router";
import App from "@/App.vue";
import BaseCard from "@/components/BaseCard.vue";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import store from "@/store/index";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";

// createApp(App).use(router).mount("#app");

const app = createApp(App);

app.use(router).use(store).use(VueToast, {
  position: "top",
  duration: 6000,
});

app.component("BaseCard", BaseCard);
app.component("Datepicker", Datepicker);

app.mount("#app");

export const appContext = app;
