
import { defineComponent, ref } from "vue";
import { Form, Field, configure, FormState } from "vee-validate";
import Login from "@/models/Login.model";
import LoginRequestModel from "@/models/Requests/LoginRequest.model";
import { ActionTypes } from "@/store";
import LoginResponseModel from "@/models/Responses/LoginResponse.model";
import ErrorException from "@/models/ErrorException.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import router from "@/router";
import AuthenticationDataService from "@/services/AuthenticationDataService";

export default defineComponent({
  name: "LoginForm",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      email: "",
      password: "",
      loginError: "",
    } as Login;
  },
  computed: {
    schema() {
      const schema = {
        email(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          // All is good
          return true;
        },
        password(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          // All is good
          return true;
        },
      };

      return schema;
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const loginForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      loginForm,
    };
  },
  methods: {
    loginAnacom() {
      AuthenticationDataService.redirectLoginAd();
      // this.$store
      //   .dispatch(ActionTypes.LOGIN_AD)
      //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
      //   .then((resp: AxiosResponse<LoginResponseModel>) => {
      //     if (resp && resp.data) {
      //       const redirectUrl = this.$route.query.redirect?.toString() || "/";
      //       router.push({ path: redirectUrl });
      //     } else {
      //       this.$toast.error("Logged in failed");
      //     }
      //   })
      //   .catch((error: Error | AxiosError) => {
      //     if (axios.isAxiosError(error)) {
      //       // Access to config, request, and response
      //       const err = error as AxiosError;
      //       if (err.response) {
      //         // Just a stock error
      //         const errorException = err.response.data as ErrorException;
      //         this.$toast.error(errorException.message);
      //       }
      //     }
      //   });
    },
    submitForm() {
      this.loginForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            const loginRequest = {
              username: this.email,
              password: this.password,
            } as LoginRequestModel;

            this.loginError = "";
            // AuthenticationDataService.login(loginRequest)
            this.$store
              .dispatch(ActionTypes.LOGIN, loginRequest)
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              .then((resp: AxiosResponse<LoginResponseModel>) => {
                const redirectUrl =
                  this.$route.query.redirect?.toString() || "/";
                router.push({ path: redirectUrl });
              })
              .catch((error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                  // Access to config, request, and response
                  const err = error as AxiosError;
                  if (err.response) {
                    // Just a stock error
                    const errorException = err.response.data as ErrorException;
                    this.loginError = errorException.message;
                  }
                }
              });
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    resetForm() {
      this.loginForm?.resetForm();
      this.loginError = "";
    },
  },
});
