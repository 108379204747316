import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-205a5f0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "card-title"
}
const _hoisted_2 = {
  key: 1,
  class: "card-subtitle"
}
const _hoisted_3 = {
  key: 2,
  class: "card-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.isInvisible ? 'invisible-card' : 'base-card')
  }, [
    (_ctx.hasTitle)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasSubitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}