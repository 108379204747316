
import Pager from "@/models/Pager.model";
import paginate from "jw-paginate";
import { defineComponent } from "vue";

const defaultLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};
const defaultStyles = {
  ul: {
    margin: 0,
    padding: 0,
    display: "inline-block",
  },
  li: {
    listStyle: "none",
    display: "inline",
    textAlign: "center",
  },
  a: {
    cursor: "pointer",
    padding: "6px 12px",
    display: "block",
    float: "left",
  },
};

export default defineComponent({
  name: "Pagination",
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    maxPages: {
      type: Number,
      default: 3,
    },
    labels: {
      type: Object,
      default: () => defaultLabels,
    },
    styles: {
      type: Object,
    },
    disableDefaultStyles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageSizeOption: this.pageSize,
      currentPage: this.initialPage,
      currentPageSize: 10,
      ulStyles: {},
      liStyles: {},
      aStyles: {},
    };
  },
  created() {
    // set default styles unless disabled
    if (!this.disableDefaultStyles) {
      this.ulStyles = defaultStyles.ul;
      this.liStyles = defaultStyles.li;
      this.aStyles = defaultStyles.a;
    }
    // merge custom styles with default styles
    if (this.styles) {
      this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
      this.liStyles = { ...this.liStyles, ...this.styles.li };
      this.aStyles = { ...this.aStyles, ...this.styles.a };
    }
    // set to initial page
    this.setPage(this.initialPage);
  },
  computed: {
    pager(): Pager {
      let pageNumber = this.currentPage;

      if (this.pager && this.totalItems !== this.pager.totalItems) {
        pageNumber = 1;
      }
      // get new pager object for specified page
      return paginate(
        this.totalItems,
        pageNumber,
        this.currentPageSize,
        this.maxPages
      );
    },
  },
  methods: {
    onChangePageSize(event) {
      if (event && event.target && event.target.value) {
        this.currentPageSize = event.target.value;
        this.currentPage = 1;

        // emit change page event to parent component
        this.$emit("changePage", this.pager);
      }
    },
    setPage(page: number | undefined) {
      if (this.totalItems === 0) return;
      this.currentPageSize = this.pageSize;
      this.currentPage = page ?? 1;

      // emit change page event to parent component
      this.$emit("changePage", this.pager);
    },
  },
});
