<template>
  <section class="container">
    <div class="no-permissions-label">Sem permissões atribuídas</div>
  </section>
</template>

<style lang="scss" scoped>
.no-permissions-label {
  margin-top: 10%;
  font-family: "Lato";
  color: $color-grey;
  font-weight: $fw-extra-bold;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
}
</style>
