export enum ApplicationStatusType {
  Submitted = 1,
  AwaitsSocialSecurityAndTaxCustomsAuthority = 2,
  AwaitsTaxCustomsAuthority = 3,
  AwaitsSocialSecurity = 4,
  Conflicting = 5,
  AwaitsANACOM = 6,
  Eligible = 7,
  NonEligible = 8,
  Active = 9,
  Inactive = 10,
  Cancelled = 11,
  Processing = 100,
}
