export const NUMBER_VALIDATION = "{:number}";
export const STRING_VALIDATION = "{:string}";
export const REGEX_VALID_STRING = "[S]*";
export const REGEX_VALID_POSITIVE_NUMBER = "[0-9]+"; // TODO: [0-9]+

/*
 * To variable value insert "{}" and for a value:
 *  number ":number"
 *  string ":string"
 */
export function compareUrl(
  baseRoute: Array<string>,
  sndUrl: string,
  startsWithSlash = true
): boolean {
  let baseRouteRgx = "";
  let isFirstElement = true;
  if (!sndUrl) {
    return false;
  }
  // Regex not needed
  // tslint:disable-next-line:triple-equals
  if (
    baseRoute.filter((x) => x.indexOf("{") == -1 || x.indexOf("}") == -1) ==
    null
  ) {
    baseRoute.forEach((element) => {
      baseRouteRgx +=
        startsWithSlash || !isFirstElement ? "/" + element : element;
      isFirstElement = false;
    });
    return baseRouteRgx === sndUrl;
  } else {
    baseRoute.forEach((element) => {
      // tslint:disable-next-line:triple-equals
      if (element.indexOf(NUMBER_VALIDATION) != -1) {
        baseRouteRgx +=
          startsWithSlash || !isFirstElement
            ? "/" + REGEX_VALID_POSITIVE_NUMBER
            : REGEX_VALID_POSITIVE_NUMBER;
        // tslint:disable-next-line:triple-equals
      } else if (element.indexOf(STRING_VALIDATION) != -1) {
        baseRouteRgx +=
          startsWithSlash || !isFirstElement
            ? "/" + REGEX_VALID_STRING
            : REGEX_VALID_STRING;
      } else {
        baseRouteRgx +=
          startsWithSlash || !isFirstElement ? "/" + element : element;
      }
      isFirstElement = false;
    });
    return sndUrl.match(baseRouteRgx) != null;
  }
}
