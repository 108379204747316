
import { defineComponent, ref } from "vue";
import { Form, Field, configure, FormState } from "vee-validate";
import ApplicationDataService from "@/services/ApplicationDataService";
import ApplicationHistoryDataService from "@/services/ApplicationHistoryDataService";
import Application from "@/models/Application.model";
import ApplicationStatus from "@/models/ApplicationStatus.model";
import ApplicationAddress from "@/models/ApplicationAddress.model";
import { ResourceFileType } from "@/models/Enums/ResouceFileType.enum";
import { ApplicationStatusType } from "@/models/Enums/ApplicationStatusType.enum";
import ValidationMessage from "@/models/ValidationMessage.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import ApplicationStatusTypeModel from "@/models/ApplicationStatusType.model";
import EligibilityApplicationConfirmationModal from "@/components/EligibilityApplicationConfirmationModal.vue";
import ActivateApplicationModal from "@/components/ActivateApplicationModal.vue";
import CancellationApplicationModal from "@/components/CancellationApplicationModal.vue";
import DeactivateApplicationModal from "@/components/DeactivateApplicationModal.vue";
import FileModel from "@/models/File.model";
import ApplicationHistoryModel from "@/models/ApplicationHistory.model";
import GetApplicationHistoryResponseModel from "@/models/Responses/GetApplicationHistoryResponse.model";
import moment from "moment";
import { mapGetters } from "vuex";
import GetApplicationRecheckRequestsResponseModel from "@/models/Responses/GetApplicationRecheckRequestsResponse.model";
import ApplicationRecheckResponseModel from "@/models/Responses/ApplicationRecheckResponse.model";
import GetMailNotificationsResponseModel from "@/models/Responses/GetMailNotificationsResponse.model";
import MailNotificationModel from "@/models/MailNotification.model";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import {
  AdministratorPermission,
  OperatorPermission,
} from "@/helper/Permission";

const PARENT_ROUTE = "applicationManagement";
const CURRENT_ROUTE = "details";
const RECHECK = "recheck";
const APLICATION_RECHECK_FORM = "recheckForm";
const APPLICATION_ANNUAL_MAINTENANCE = "applicationAnnualMaintenance";

export default defineComponent({
  name: "ApplicationDetail",
  components: {
    Form,
    Field,
    EligibilityApplicationConfirmationModal,
    ActivateApplicationModal,
    CancellationApplicationModal,
    DeactivateApplicationModal,
  },
  data() {
    return {
      isRecheckDetail: false,
      ResourceFileType,
      isEligibilityApplicationConfirmationModalVisible: false,
      isDeactivateApplicationModalVisible: false,
      isActivateApplicationModalVisible: false,
      isCancelApplicationModalVisible: false,
      isAddressSelected: false,
      isStudentAddressSelected: false,
      currentApplicationStatus: undefined as
        | ApplicationHistoryModel
        | undefined,
      applicationHistory: [] as Array<ApplicationHistoryModel>,
      applicationRecheckRequests: [] as Array<ApplicationRecheckResponseModel>,
      mailNotifications: [] as Array<MailNotificationModel>,
      isApplicationBeingRechecked: false,
      application: {
        id: null,
        providerId: undefined,
        providerName: "",
        providerEmail: "",
        fullName: "",
        fiscalNumber: "",
        address: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        isStudent: false,
        studentFullName: "",
        studentFiscalNumber: "",
        studentAddress: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        applicantGaveConsent: false,
      } as Application,
      applicationStatus: {
        applicationId: null,
        applicationStatus: {} as ApplicationStatusTypeModel,
      } as ApplicationStatus,
    };
  },
  computed: {
    schema() {
      const schema = {};

      return schema;
    },
    ...mapGetters(["isUserAdmin", "isUserProvider", "isUserOperator"]),
    isStudentFieldRequired(): boolean {
      return this.application.isStudent;
    },
    isApplicationInactive(): boolean {
      if (!this.currentApplicationStatus) {
        return false;
      } else {
        return (
          this.currentApplicationStatus.applicationStatusId ===
          ApplicationStatusType.Inactive
        );
      }
    },
    isApplicationSubmitted(): boolean {
      if (!this.currentApplicationStatus) {
        return false;
      } else {
        return (
          this.currentApplicationStatus.applicationStatusId ===
          ApplicationStatusType.Submitted
        );
      }
    },
    isApplicationConflicting(): boolean {
      if (!this.currentApplicationStatus) {
        return false;
      } else {
        return (
          this.currentApplicationStatus.applicationStatusId ===
          ApplicationStatusType.Conflicting
        );
      }
    },
    isApplicationProcessing(): boolean {
      if (!this.currentApplicationStatus) {
        return false;
      } else {
        return (
          this.currentApplicationStatus.applicationStatusId ===
            ApplicationStatusType.Processing ||
          this.currentApplicationStatus.applicationStatusId ===
            ApplicationStatusType.AwaitsSocialSecurityAndTaxCustomsAuthority ||
          this.currentApplicationStatus.applicationStatusId ===
            ApplicationStatusType.AwaitsTaxCustomsAuthority ||
          this.currentApplicationStatus.applicationStatusId ===
            ApplicationStatusType.AwaitsSocialSecurity
        );
      }
    },
    isApplicationActive(): boolean {
      if (!this.currentApplicationStatus) {
        return false;
      } else {
        return (
          this.currentApplicationStatus.applicationStatusId ===
          ApplicationStatusType.Active
        );
      }
    },
    isApplicationCancelled(): boolean {
      if (!this.currentApplicationStatus) {
        return false;
      } else {
        return (
          this.currentApplicationStatus.applicationStatusId ===
          ApplicationStatusType.Cancelled
        );
      }
    },
    isApplicationNonEligible(): boolean {
      if (!this.currentApplicationStatus) {
        return false;
      } else {
        return (
          this.currentApplicationStatus.applicationStatusId ===
          ApplicationStatusType.NonEligible
        );
      }
    },
    isApplicationEligible(): boolean {
      if (!this.currentApplicationStatus) {
        return false;
      } else {
        return (
          this.currentApplicationStatus.applicationStatusId ===
          ApplicationStatusType.Eligible
        );
      }
    },
    isApplicationAwaitingANACOM(): boolean {
      if (!this.currentApplicationStatus) {
        return false;
      } else {
        return (
          this.currentApplicationStatus.applicationStatusId ===
          ApplicationStatusType.AwaitsANACOM
        );
      }
    },
    applicationActivationDate(): Date | undefined {
      if (!this.isApplicationActive) {
        return undefined;
      } else {
        return this.currentApplicationStatus?.applicationActivation
          ?.activationStartDate;
      }
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const applicationForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      applicationForm,
    };
  },
  methods: {
    canAccessUpdater() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Applications_Updater,
        OperatorPermission.ApplicationsModule_Updater,
      ]);
    },
    canAccessWriter() {
      return AuthenticationDataService.canUserAccess([
        OperatorPermission.ApplicationsModule_Writer,
      ]);
    },
    submitForm() {
      // Do Nothing
    },
    formatDate(date: Date | undefined): string {
      return date ? moment(date).format("DD-MM-YYYY HH:mm") : "";
    },
    toggleIsStudent() {
      this.application.isStudent = !this.application.isStudent;

      // Always reset the other student related fields
      this.application.studentAddress.cp4 = "";
      this.application.studentAddress.cp3 = "";
      this.application.studentFullName = "";
      this.application.studentFiscalNumber = "";
    },
    getApplication() {
      const applicationId = this.isRecheckDetail
        ? (this.$route.params.recheckId as string)
        : (this.$route.params.id as string);
      ApplicationDataService.getById(applicationId)
        .then((response: AxiosResponse<Application>) => {
          if (response && response.data) {
            const app = response.data;
            if (app.address) {
              app.address.cp4 = app.address.cp4.toString().padStart(4, "0");
              app.address.cp3 = app.address.cp3.toString().padStart(3, "0");
            }
            if (app.studentAddress) {
              app.studentAddress.cp4 = app.studentAddress.cp4
                .toString()
                .padStart(4, "0");
              app.studentAddress.cp3 = app.studentAddress.cp3
                .toString()
                .padStart(3, "0");
            }
            this.application = app;
            this.getApplicationHistory();
            if (this.isUserAdmin) {
              this.getMailNotifications();
            }
            this.getApplicationRecheckRequests();
            this.checkIfApplicationBeingRechecked(applicationId);
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            const err = error as AxiosError;
            if (err.response && err.response.status === 400) {
              // Just a stock error
              const validationMessage = err.response.data as ValidationMessage;
              if (
                validationMessage &&
                validationMessage.errors &&
                validationMessage.errors.length &&
                validationMessage.errors[0].errors &&
                validationMessage.errors[0].errors.length
              ) {
                this.$toast.error(validationMessage.errors[0].errors[0]);
              }
            }
          } else {
            this.$toast.error(
              "Ocorreu um erro na obtenção do pedido, por favor tente novamente."
            );
          }
          this.goBack();
        });
    },
    getApplicationHistory() {
      if (this.application && this.application.id) {
        ApplicationHistoryDataService.getByApplicationId(this.application.id)
          .then(
            (response: AxiosResponse<GetApplicationHistoryResponseModel>) => {
              if (response && response.data) {
                this.applicationHistory = response.data.data;
                if (this.applicationHistory) {
                  this.currentApplicationStatus = this.applicationHistory.find(
                    (elem) =>
                      elem.applicationStatusId ===
                      this.application.applicationStatusId
                  );
                }
              }
            }
          )
          .catch((error: Error | AxiosError) => {
            if (axios.isAxiosError(error)) {
              // Access to config, request, and response
              const err = error as AxiosError;
              if (err.response && err.response.status === 400) {
                // Just a stock error
                const validationMessage = err.response
                  .data as ValidationMessage;
                if (
                  validationMessage &&
                  validationMessage.errors &&
                  validationMessage.errors.length &&
                  validationMessage.errors[0].errors &&
                  validationMessage.errors[0].errors.length
                ) {
                  this.$toast.error(validationMessage.errors[0].errors[0]);
                }
              }
            } else {
              this.$toast.error(
                "Ocorreu um erro na obtenção do pedido, por favor tente novamente."
              );
            }
            this.goBack();
          });
      }
    },
    getMailNotifications() {
      if (this.application && this.application.id) {
        ApplicationDataService.getMailNotificationsByApplicationId(
          this.application.id
        )
          .then(
            (response: AxiosResponse<GetMailNotificationsResponseModel>) => {
              if (response && response.data) {
                this.mailNotifications = response.data.data;
              }
            }
          )
          .catch((error: Error | AxiosError) => {
            if (axios.isAxiosError(error)) {
              // Access to config, request, and response
              const err = error as AxiosError;
              if (err.response && err.response.status === 400) {
                // Just a stock error
                const validationMessage = err.response
                  .data as ValidationMessage;
                if (
                  validationMessage &&
                  validationMessage.errors &&
                  validationMessage.errors.length &&
                  validationMessage.errors[0].errors &&
                  validationMessage.errors[0].errors.length
                ) {
                  this.$toast.error(validationMessage.errors[0].errors[0]);
                }
              }
            } else {
              this.$toast.error(
                "Ocorreu um erro na obtenção do pedido, por favor tente novamente."
              );
            }
            this.goBack();
          });
      }
    },
    getApplicationRecheckRequests() {
      if (this.application && this.application.id) {
        ApplicationDataService.getRecheckRequestsByApplicationId(
          this.application.id
        )
          .then(
            (
              response: AxiosResponse<GetApplicationRecheckRequestsResponseModel>
            ) => {
              if (response && response.data) {
                this.applicationRecheckRequests = response.data.data;
              }
            }
          )
          .catch((error: Error | AxiosError) => {
            if (axios.isAxiosError(error)) {
              // Access to config, request, and response
              const err = error as AxiosError;
              if (err.response && err.response.status === 400) {
                // Just a stock error
                const validationMessage = err.response
                  .data as ValidationMessage;
                if (
                  validationMessage &&
                  validationMessage.errors &&
                  validationMessage.errors.length &&
                  validationMessage.errors[0].errors &&
                  validationMessage.errors[0].errors.length
                ) {
                  this.$toast.error(validationMessage.errors[0].errors[0]);
                }
              }
            } else {
              this.$toast.error(
                "Ocorreu um erro na obtenção do pedido, por favor tente novamente."
              );
            }
            this.goBack();
          });
      }
    },
    downloadFiles(type: ResourceFileType | null) {
      ApplicationDataService.getFiles(this.application.id, type)
        .then((response: AxiosResponse<FileModel[]>) => {
          if (response && response.data) {
            response.data.map((fileModel) => {
              ApplicationDataService.downloadResourceFile(
                fileModel.resourceFileId
              )
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                .then((response) => {
                  const blob = new Blob([response.data], {
                    type: "application/pdf",
                  });
                  const link = document.createElement("a");
                  link.href = URL.createObjectURL(blob);
                  link.download = fileModel.filename;
                  link.click();
                  URL.revokeObjectURL(link.href);
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch((error: Error | AxiosError) => {
                  this.$toast.error(
                    "Ocorreu um erro na obtenção do documento, por favor tente novamente."
                  );
                });
            });
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            const err = error as AxiosError;
            if (err.response && err.response.status === 400) {
              // Just a stock error
              const validationMessage = err.response.data as ValidationMessage;
              if (
                validationMessage &&
                validationMessage.errors &&
                validationMessage.errors.length &&
                validationMessage.errors[0].errors &&
                validationMessage.errors[0].errors.length
              ) {
                this.$toast.error(validationMessage.errors[0].errors[0]);
              }
            }
          } else {
            this.$toast.error(
              "Ocorreu um erro na obtenção dos documentos, por favor tente novamente."
            );
          }
        });
    },
    openNonEligibleApplicationConfirmationModal() {
      this.applicationStatus = {
        applicationId: this.application.id,
        applicationStatus: {
          id: 8,
          description: "Não Elegível",
          fullDescription: "",
        },
      };
      this.isEligibilityApplicationConfirmationModalVisible = true;
    },
    openEligibleApplicationConfirmationModal() {
      this.applicationStatus = {
        applicationId: this.application.id,
        applicationStatus: {
          id: 7,
          description: "Elegível",
          fullDescription: "",
        },
      };
      this.isEligibilityApplicationConfirmationModalVisible = true;
    },
    onEligibilityApplicationConfirmationModalCompleted() {
      this.isEligibilityApplicationConfirmationModalVisible = false;
      this.getApplication();
    },
    closeEligibilityApplicationConfirmationModal() {
      this.isEligibilityApplicationConfirmationModalVisible = false;
    },
    openActivateApplicationModal() {
      this.isActivateApplicationModalVisible = true;
    },
    onActivationCompleted() {
      this.isActivateApplicationModalVisible = false;
      this.getApplication();
      this.$emit("scrollToTop");
    },
    closeActivateApplicationModal() {
      this.isActivateApplicationModalVisible = false;
    },
    openCancellationApplicationModal() {
      this.isCancelApplicationModalVisible = true;
    },
    closeCancellationApplicationModal() {
      this.isCancelApplicationModalVisible = false;
    },
    onCancellationCompleted() {
      this.isCancelApplicationModalVisible = false;
      this.getApplication();
      this.$emit("scrollToTop");
    },
    openDeactivateApplicationModal() {
      this.isDeactivateApplicationModalVisible = true;
    },
    onDeactivationCompleted() {
      this.isDeactivateApplicationModalVisible = false;
      this.getApplication();
      this.$emit("scrollToTop");
    },
    closeDeactivateApplicationModal() {
      this.isDeactivateApplicationModalVisible = false;
    },
    goBack() {
      const splitRoute = this.$route.path.split("/");
      const nextRoute = this.isRecheckDetail
        ? splitRoute.slice(0, splitRoute.length - 3).join("/") +
          "/" +
          CURRENT_ROUTE
        : splitRoute.slice(0, splitRoute.length - 2).join("/");

      //Go router back if came from applicationAnnualMaintenance page
      if (
        this.$router.options.history.state.back &&
        this.$router.options.history.state.back
          ?.toString()
          .indexOf(APPLICATION_ANNUAL_MAINTENANCE) > 0
      ) {
        this.$router.back();
      } else {
        this.$router.push(nextRoute);
      }
    },
    getReason(history: ApplicationHistoryModel): string {
      if (!history) {
        return "";
      }

      var retStr = "";
      if (history.applicationStatusId === ApplicationStatusType.Active) {
        if (history.applicationActivation?.activationStartReason) {
          retStr =
            history.applicationActivation?.activationStartReason?.description;

          if (history?.applicationActivation?.activationStartOtherReason) {
            retStr =
              retStr +
              ": " +
              history?.applicationActivation?.activationStartOtherReason;
          }
        }
      } else if (
        history.applicationStatusId === ApplicationStatusType.Inactive
      ) {
        if (history.applicationActivation?.activationEndReason) {
          retStr =
            history.applicationActivation?.activationEndReason?.description;

          if (history?.applicationActivation?.activationEndOtherReason) {
            retStr =
              retStr +
              ". " +
              history?.applicationActivation?.activationEndOtherReason;
          }
        }
      } else if (
        history.applicationStatusId === ApplicationStatusType.Cancelled
      ) {
        if (history.cancellation?.cancellationReason) {
          retStr = history.cancellation?.cancellationReason?.description;
        }
      } else if (
        history.applicationStatusId === ApplicationStatusType.NonEligible
      ) {
        if (history.nonEligibleReason) {
          retStr = history.nonEligibleReason;
        }
      }

      return retStr;
    },
    goToRecheckForm() {
      this.$router.push(
        "/" +
          PARENT_ROUTE +
          "/" +
          this.application.id +
          "/" +
          APLICATION_RECHECK_FORM
      );
    },
    checkIfApplicationBeingRechecked(applicationId: string) {
      ApplicationDataService.isApplicationBeingRechecked(applicationId)
        .then((response: AxiosResponse<boolean>) => {
          if (response && response.data) {
            this.isApplicationBeingRechecked = response.data;
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            const err = error as AxiosError;
            if (err.response && err.response.status === 400) {
              // Just a stock error
              const validationMessage = err.response.data as ValidationMessage;
              if (
                validationMessage &&
                validationMessage.errors &&
                validationMessage.errors.length &&
                validationMessage.errors[0].errors &&
                validationMessage.errors[0].errors.length
              ) {
                this.$toast.error(validationMessage.errors[0].errors[0]);
              }
            }
          } else {
            this.$toast.error(
              "Ocorreu um erro na obtenção do pedido, por favor tente novamente."
            );
          }
          this.goBack();
        });
    },
    goToRecheckDetail(applicationId: string) {
      const splitRoute = this.$route.path.split("/");
      const nextRoute =
        splitRoute.slice(0, splitRoute.length - 1).join("/") +
        "/" +
        RECHECK +
        "/" +
        applicationId +
        "/" +
        CURRENT_ROUTE;

      this.$router.push(nextRoute);
    },
    goToBlockingApplication(applicationId: string | undefined) {
      if (applicationId) {
        const splitRoute = this.$route.path.split("/");
        const nextRoute =
          splitRoute.slice(0, splitRoute.length - 2).join("/") +
          "/" +
          applicationId +
          "/" +
          CURRENT_ROUTE;

        this.$router.push(nextRoute);
      }
    },
  },
  mounted() {
    if (this.$route.params && this.$route.params.recheckId) {
      this.isRecheckDetail = true;
    }
    this.getApplication();
    this.$emit("scrollToTop");
  },
});
