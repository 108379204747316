
import router from "@/router";
import { ActionTypes } from "@/store";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "AppHeader",
  data() {
    return {
      videoTutorialLink: "https://www.anacom.pt/render.jsp?categoryId=423163",
      pageTitle: "",
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getCurrentUserName",
      "isUserLoggedIn",
      "isUserOperator",
      "isUserProvider",
    ]),
  },
  methods: {
    logout() {
      this.$store.dispatch(ActionTypes.LOGOUT).then(() => {
        router.push("/Login");
      });
    },
    changePassword() {
      router.push("/PasswordChange");
    },
    gotTohome() {
      router.push("/");
    },
    goToVideoLink() {
      window.open(this.videoTutorialLink, "_blank", "noreferrer,noopener");
    },
  },
});
