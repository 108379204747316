
import { defineComponent } from "vue";

export default defineComponent({
  name: "SuccessModal",
  props: {
    successTitle: String,
    successSubtitle: String,
    goBackText: String,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
