
import { defineComponent, ref } from "vue";
import { Form, Field, configure, FormState } from "vee-validate";
import UserDataService from "@/services/UserDataService";
import User from "@/models/User.model";
import ValidationMessage from "@/models/ValidationMessage.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import { mapGetters } from "vuex";
import UserStatusesResponseModel from "@/models/Responses/UserStatusesResponse.model";
import UserStatusTypeModel from "@/models/UserStatusType.model";
import CreateUserRequestModel from "@/models/Requests/CreateUserRequest.model";
import CreateUserResponseModel from "@/models/Responses/CreateUserResponse.model";
import Permission from "@/models/Permission.model";
import { UserType } from "@/models/Enums/UserType.enum";
import PermissionsResponseModel from "@/models/Responses/PermissionsResponse.model";
import UpdateUserRequestModel from "@/models/Requests/UpdateUserRequest.model";
import UpdateUserResponseModel from "@/models/Responses/UpdateUserResponse.model";
import { AdministratorPermission } from "@/helper/Permission";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import SearchProvidersRequestModel from "@/models/Requests/SearchProvidersRequest.model";
import ProviderResponseModel from "@/models/Responses/ProviderResponse.model";
import PagedResponseModel from "@/models/Responses/PagedResponse.model";

const PARENT_ROUTE = "users";
const USERP_PROVIDER_ROUTE = "userProviders";
const DETAILS = "details";
const CREATE = "create";
const EDIT = "edit";

export default defineComponent({
  name: "UserDetail",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      isCreating: false,
      isEditing: false,
      isUserProvider: false,
      permissionList: [] as Array<Permission>,
      defaultUserStatusId: -1,
      userStatuses: [] as Array<UserStatusTypeModel>,
      defaultParentId: -1,
      userProviders: [] as Array<ProviderResponseModel>,
      user: {
        username: "",
        userStatusId: -1,
        permissions: [] as Array<string>,
        userTypeId: UserType.Administrator,
      } as User,
    };
  },
  computed: {
    schema() {
      const isCreating = this.isCreating;
      const isEditing = this.isEditing;
      const isUserProvider = this.isUserProvider;

      const schema = {
        parentId(value: number) {
          if (isUserProvider) {
            if (!value || value <= 0) {
              return "Campo obrigatório.";
            }
          }
          // if the field is empty

          // All is good
          return true;
        },
        userStatusId(value: number) {
          if (isCreating || isEditing) {
            if (!value || value <= 0) {
              return "Campo obrigatório.";
            }
          }
          // if the field is empty

          // All is good
          return true;
        },
        username(value: string) {
          if (isCreating) {
            // if the field is empty
            if (!value) {
              return "Campo obrigatório.";
            }
            // if the field is not a valid email
            // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            const regex =
              /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i;
            if (!regex.test(value)) {
              return "E-mail inválido.";
            }
          }
          // All is good
          return true;
        },
      };

      return schema;
    },
    ...mapGetters(["isUserAdmin", "isUserProvider", "isUserOperator"]),
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const userForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      userForm,
    };
  },
  methods: {
    canAccessWriter() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Users_Writer,
      ]);
    },
    canAccessUpdater() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Users_Updater,
      ]);
    },
    submitForm() {
      this.userForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            if (this.isCreating) {
              const createUserRequest = {
                users: [this.user],
              } as CreateUserRequestModel;

              UserDataService.create(createUserRequest)
                .then((resp: AxiosResponse<CreateUserResponseModel[]>) => {
                  if (resp) {
                    if (resp.data) {
                      const created = resp.data[0];
                      if (created.success) {
                        this.user.id = created.userId;
                        this.$toast.success("Utilizador criado com sucesso.");
                        this.goToUserDetail();
                      } else {
                        this.$toast.error(created.errorMessage);
                      }
                    } else {
                      this.$toast.error(
                        `Ocorreu um erro na ${
                          this.isCreating ? "criação" : "edição"
                        } do utilizador, por favor tente novamente.`
                      );
                    }
                  }
                })
                .catch((error: Error | AxiosError) => {
                  if (axios.isAxiosError(error)) {
                    // Access to config, request, and response
                    const err = error as AxiosError;
                    if (err.response && err.response.status === 400) {
                      // Just a stock error
                      const validationMessage = err.response
                        .data as ValidationMessage;
                      if (
                        validationMessage &&
                        validationMessage.errors &&
                        validationMessage.errors.length &&
                        validationMessage.errors[0].errors &&
                        validationMessage.errors[0].errors.length
                      ) {
                        this.$toast.error(
                          validationMessage.errors[0].errors[0]
                        );
                      }
                    }
                  } else {
                    // Just a stock error
                    this.$toast.error(
                      `Ocorreu um erro na ${
                        this.isCreating ? "criação" : "edição"
                      } do utilizador, por favor tente novamente.`
                    );
                  }
                });
            } else if (this.isEditing) {
              const updateUserRequest = {
                users: [this.user],
              } as UpdateUserRequestModel;

              UserDataService.update(updateUserRequest)
                .then((resp: AxiosResponse<UpdateUserResponseModel[]>) => {
                  if (resp) {
                    if (resp.data && resp.data.every((x) => x.success)) {
                      this.user.id = resp.data[0].userId;
                      this.$toast.success("Utilizador atualizado com sucesso.");
                      this.goToUserDetail();
                    } else {
                      this.$toast.error(
                        `Ocorreu um erro na ${
                          this.isCreating ? "criação" : "edição"
                        } do utilizador, por favor tente novamente.`
                      );
                    }
                  }
                })
                .catch((error: Error | AxiosError) => {
                  if (axios.isAxiosError(error)) {
                    // Access to config, request, and response
                    const err = error as AxiosError;
                    if (err.response && err.response.status === 400) {
                      // Just a stock error
                      const validationMessage = err.response
                        .data as ValidationMessage;
                      if (
                        validationMessage &&
                        validationMessage.errors &&
                        validationMessage.errors.length &&
                        validationMessage.errors[0].errors &&
                        validationMessage.errors[0].errors.length
                      ) {
                        this.$toast.error(
                          validationMessage.errors[0].errors[0]
                        );
                      }
                    }
                  } else {
                    // Just a stock error
                    this.$toast.error(
                      `Ocorreu um erro na ${
                        this.isCreating ? "criação" : "edição"
                      } do utilizador, por favor tente novamente.`
                    );
                  }
                });
            }
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    getUser() {
      const userId = Number(this.$route.params.id as string);
      UserDataService.getById(userId)
        .then((response: AxiosResponse<User>) => {
          if (response && response.data) {
            const user = response.data;
            this.user = user;
            this.getPermissions();
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            const err = error as AxiosError;
            if (err.response && err.response.status === 400) {
              // Just a stock error
              const validationMessage = err.response.data as ValidationMessage;
              if (
                validationMessage &&
                validationMessage.errors &&
                validationMessage.errors.length &&
                validationMessage.errors[0].errors &&
                validationMessage.errors[0].errors.length
              ) {
                this.$toast.error(validationMessage.errors[0].errors[0]);
              }
            }
          } else {
            this.$toast.error(
              "Ocorreu um erro na obtenção do utilizador, por favor tente novamente."
            );
          }
          this.goBack();
        });
    },
    getUserStatuses() {
      UserDataService.getUserStatuses()
        .then((response: AxiosResponse<UserStatusesResponseModel>) => {
          if (response && response.data) {
            this.userStatuses = response.data.data;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    getUserProviders() {
      const request = {
        userStatusId: 1, //Active
        pageNumber: 1,
        pageSize: 999,
      } as SearchProvidersRequestModel;
      UserDataService.searchProviders(request)
        .then(
          (
            response: AxiosResponse<PagedResponseModel<ProviderResponseModel>>
          ) => {
            if (response && response.data) {
              this.userProviders = response.data.items;
            }
          }
        )
        .catch((e: Error) => {
          console.error(e);
        });
    },
    getPermissions() {
      UserDataService.getPermissionsByUserTypeId(this.user.userTypeId)
        .then((response: AxiosResponse<PermissionsResponseModel>) => {
          if (response && response.data) {
            this.permissionList = response.data.data;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    goBack() {
      const splitRoute = this.$route.path.split("/");
      const nextRoute = splitRoute
        .slice(0, splitRoute.length - (this.isCreating ? 1 : 2))
        .join("/");

      this.$router.push(nextRoute);
    },
    goToUserDetail() {
      if (this.user && this.user.id) {
        const splitRoute = this.$route.path.split("/");
        const nextRoute =
          splitRoute
            .slice(0, splitRoute.length - (this.isCreating ? 1 : 2))
            .join("/") +
          "/" +
          this.user.id +
          "/" +
          DETAILS;

        this.$router.push(nextRoute);
      }
    },
    goToUserEdit() {
      if (this.user && this.user.id) {
        const splitRoute = this.$route.path.split("/");
        const nextRoute =
          splitRoute.slice(0, splitRoute.length - 1).join("/") + "/" + EDIT;

        this.$router.push(nextRoute);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    writeOrUpdatePermissionChanged(event: any, permission: Permission) {
      if (event.target.checked) {
        if (!this.user.permissions.includes(permission.readerPermission)) {
          this.user.permissions.push(permission.readerPermission);
        }
      }
    },
  },
  mounted() {
    if (this.$route.fullPath.includes(EDIT)) {
      this.isEditing = true;
    } else if (this.$route.fullPath.includes(CREATE)) {
      this.isCreating = true;
    }
    this.isUserProvider = this.$route.fullPath.includes(USERP_PROVIDER_ROUTE);

    if (this.isUserProvider) {
      this.getUserProviders();
      this.user.userTypeId = UserType.Operator;
    }

    this.getUserStatuses();
    if (!this.isCreating) {
      this.getUser();
    } else {
      this.getPermissions();
    }
    this.$emit("scrollToTop");
  },
});
