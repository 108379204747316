
import { defineComponent, ref } from "vue";
import { Form, Field, configure, FormState } from "vee-validate";
import ApplicationDataService from "@/services/ApplicationDataService";
import AddressModal from "@/components/AddressModal.vue";
import SuccessModal from "@/components/SuccessModal.vue";
import Application from "@/models/Application.model";
import ApplicationAddress from "@/models/ApplicationAddress.model";
import Address from "@/models/Address.model";
import FileModel from "@/models/File.model";
import RecheckApplicationRequestModel from "@/models/Requests/RecheckApplicationRequest.model";
import RecheckApplicationResponseModel from "@/models/Responses/RecheckApplicationResponse.model";
import ValidationMessage from "@/models/ValidationMessage.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import * as ValidationHelpers from "@/helper/ValidationHelpers";
import * as FileHelpers from "@/helper/FileHelpers";
import { ResourceFileType } from "@/models/Enums/ResouceFileType.enum";
import { OperatorPermission } from "@/helper/Permission";
import AuthenticationDataService from "@/services/AuthenticationDataService";

const BYTES_IN_MEGABYTE = 1000000;
const PARENT_ROUTE = "applicationManagement";
const DETAILS = "details";

export default defineComponent({
  name: "ApplicationRecheckForm",
  components: {
    Form,
    Field,
    AddressModal,
    SuccessModal,
  },
  data() {
    return {
      isAddressModalVisible: false,
      isAddressSelected: false,
      wasAddressSelectedOnce: false,
      isStudentAddressModalVisible: false,
      isStudentAddressSelected: false,
      wasStudentAddressSelectedOnce: false,
      isSuccessModalVisible: false,
      application: {
        id: null,
        providerId: undefined,
        providerName: "",
        providerEmail: "",
        fullName: "",
        fiscalNumber: "",
        address: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        isStudent: false,
        studentFullName: "",
        studentFiscalNumber: "",
        studentAddress: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        applicantGaveConsent: false,
        isAnnualMaintenance: false,
      } as Application,
    };
  },
  computed: {
    schema() {
      const application = this.application;
      const schema = {
        providerEmail(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          // if the field is not a valid email
          // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          const regex =
            /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i;
          if (!regex.test(value)) {
            return "E-mail inválido.";
          }
          // All is good
          return true;
        },
        addressCp4(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          if (
            !ValidationHelpers.isPositiveNumeric(value) ||
            value.length !== 4
          ) {
            return "Código postal inválido";
          }

          return true;
        },
        addressCp3(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          if (
            !ValidationHelpers.isPositiveNumeric(value) ||
            value.length !== 3
          ) {
            return "Código postal inválido";
          }

          return true;
        },
        studentAddressCp4(value: string) {
          if (application.isStudent) {
            // if the field is empty
            if (!value) {
              return "Campo obrigatório.";
            }
            if (
              !ValidationHelpers.isPositiveNumeric(value) ||
              value.length !== 4
            ) {
              return "Código postal inválido";
            }
          }

          return true;
        },
        studentAddressCp3(value: string) {
          if (application.isStudent) {
            // if the field is empty
            if (!value) {
              return "Campo obrigatório.";
            }
            if (
              !ValidationHelpers.isPositiveNumeric(value) ||
              value.length !== 3
            ) {
              return "Código postal inválido";
            }
          }
          return true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        studentRegistrationCertificateFileInput(value: any) {
          if (application.isStudent) {
            if (!application.studentRegistrationCertificateFile) {
              return "Campo obrigatório.";
            }
          }
          return true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        studentAddressCertificateFileInput(value: any) {
          if (application.isStudent) {
            if (!application.studentAddressCertificateFile) {
              return "Campo obrigatório.";
            }
          }
          return true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        socialSecurityFileInput(value: any) {
          if (application.applicantProvidedRequiredDocuments) {
            if (
              (!application.taxCustomsAuthorityFiles ||
                application.taxCustomsAuthorityFiles.length <= 0) &&
              (!application.socialSecurityFiles ||
                application.socialSecurityFiles.length <= 0)
            ) {
              return "Campo obrigatório.";
            }
          }
          return true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        taxAuthorityFileInput(value: any) {
          if (application.applicantProvidedRequiredDocuments) {
            if (
              (!application.taxCustomsAuthorityFiles ||
                application.taxCustomsAuthorityFiles.length <= 0) &&
              (!application.socialSecurityFiles ||
                application.socialSecurityFiles.length <= 0)
            ) {
              return "Campo obrigatório.";
            }
          }
          return true;
        },
      };

      return schema;
    },
    isStudentFieldRequired(): boolean {
      return this.application.isStudent;
    },
    isAddressSearchButtonDisabled(): boolean {
      var cp4IsValid =
        this.application.address.cp4 &&
        ValidationHelpers.isPositiveNumeric(this.application.address.cp4) &&
        this.application.address.cp4.length === 4;
      var cp3IsValid =
        this.application.address.cp3 &&
        ValidationHelpers.isPositiveNumeric(this.application.address.cp3) &&
        this.application.address.cp3.length === 3;

      return !cp4IsValid || !cp3IsValid;
    },
    isStudentAddressSearchButtonDisabled(): boolean {
      var cp4IsValid =
        this.application.studentAddress.cp4 &&
        ValidationHelpers.isPositiveNumeric(
          this.application.studentAddress.cp4
        ) &&
        this.application.studentAddress.cp4.length === 4;
      var cp3IsValid =
        this.application.studentAddress.cp3 &&
        ValidationHelpers.isPositiveNumeric(
          this.application.studentAddress.cp3
        ) &&
        this.application.studentAddress.cp3.length === 3;

      return !cp4IsValid || !cp3IsValid;
    },
    isAddressButtonRequired(): boolean {
      return (
        this.application.address.cp4.length === 4 &&
        this.application.address.cp3.length === 3 &&
        !this.isAddressSelected
      );
    },
    isStudentAddressButtonRequired(): boolean {
      return (
        this.application.isStudent &&
        this.application.studentAddress.cp4.length === 4 &&
        this.application.studentAddress.cp3.length === 3 &&
        !this.isStudentAddressSelected
      );
    },
    successModalTitle(): string | undefined {
      if (this.application.id) {
        return !this.application.isAnnualMaintenance
          ? `Pedido de reverificação de elegibilidade submetido com sucesso.`
          : `Pedido de manutenção atualizado com sucesso.`;
      }
      return undefined;
    },
    successModalSubtitle(): string | undefined {
      if (this.application.id && !this.application.isAnnualMaintenance) {
        return `Será notificado via e-mail do resultado do pedido de reverificação de elegibilidade.`;
      }
      return undefined;
    },
    successModalGoBackText(): string {
      return "Voltar";
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const recheckForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      recheckForm,
    };
  },
  methods: {
    canAccessWriter() {
      return AuthenticationDataService.canUserAccess([
        OperatorPermission.AnnualMaintenanceModule_Updater,
        OperatorPermission.ApplicationsModule_Writer,
      ]);
    },
    submitForm() {
      this.recheckForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (
            result.valid &&
            this.isAddressSelected &&
            (!this.application.isStudent || this.isStudentAddressSelected)
          ) {
            const recheckApplicationRequest = {
              providerId: this.application.providerId,
              providerEmail: this.application.providerEmail,
              applications: [this.application],
            } as RecheckApplicationRequestModel;
 
            if (!this.application.isAnnualMaintenance)
            {
              this.submissionRecheck(ApplicationDataService.recheck(recheckApplicationRequest));
            }
            else
            {
              this.submissionRecheck(ApplicationDataService.updateAnnualRecheck(recheckApplicationRequest));
            } 
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    submissionRecheck(promise: Promise<AxiosResponse<RecheckApplicationResponseModel[]>>)
    {
      promise
        .then((resp: AxiosResponse<RecheckApplicationResponseModel[]>) => {
            if (resp) {
              if (resp.data && resp.data.every((x) => x.success)) {
                this.application.id = resp.data[0].applicationId;
                this.showSuccessModal();
              } else {
                this.$toast.error(
                  "Ocorreu um erro na submissão do pedido, por favor tente novamente."
                );
              }
            }
          }
        )
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            const err = error as AxiosError;
            if (err.response && err.response.status === 400) {
              // Just a stock error
              const validationMessage = err.response
                .data as ValidationMessage;
              if (
                validationMessage &&
                validationMessage.errors &&
                validationMessage.errors.length &&
                validationMessage.errors[0].errors &&
                validationMessage.errors[0].errors.length
              ) {
                this.$toast.error(
                  validationMessage.errors[0].errors[0]
                );
              }
            }
          } else {
            // Just a stock error
            this.$toast.error(
              "Ocorreu um erro na submissão do pedido, por favor tente novamente."
            );
          }
        });
    },
    resetForm() {
      this.recheckForm?.resetForm();
      this.isAddressSelected = false;
      this.isStudentAddressSelected = false;
      this.wasAddressSelectedOnce = false;
      this.isAddressModalVisible = false;
      this.isStudentAddressModalVisible = false;
      this.wasStudentAddressSelectedOnce = false;
      this.isSuccessModalVisible = false;
      this.application = {
        id: null,
        fullName: "",
        fiscalNumber: "",
        address: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        isStudent: false,
        studentFullName: "",
        studentFiscalNumber: "",
        studentAddress: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        studentAddressCertificateFile: null as FileModel | null,
        studentRegistrationCertificateFile: null as FileModel | null,
        applicantGaveConsent: false,
        applicantProvidedRequiredDocuments: false,
        taxCustomsAuthorityFiles: [] as FileModel[],
        socialSecurityFiles: [] as FileModel[],
        isAnnualMaintenance: false,
      } as Application;
    },
    getApplication() {
      const applicationId = this.$route.params.id as string;
      ApplicationDataService.getById(applicationId)
        .then((response: AxiosResponse<Application>) => {
          if (response && response.data) {
            const app = response.data;
            if (app.address) {
              app.address.cp4 = app.address.cp4.toString().padStart(4, "0");
              app.address.cp3 = app.address.cp3.toString().padStart(3, "0");
              this.wasAddressSelectedOnce = true;
            }
            if (app.studentAddress) {
              app.studentAddress.cp4 = app.studentAddress.cp4
                .toString()
                .padStart(4, "0");
              app.studentAddress.cp3 = app.studentAddress.cp3
                .toString()
                .padStart(3, "0");
              this.wasStudentAddressSelectedOnce = true;
            }
            this.application = app;
            if (this.application.address) {
              this.isAddressSelected = true;
            }
            if (this.application.studentAddress) {
              this.isStudentAddressSelected = true;
            }
            this.getAllFiles();
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            const err = error as AxiosError;
            if (err.response && err.response.status === 400) {
              // Just a stock error
              const validationMessage = err.response.data as ValidationMessage;
              if (
                validationMessage &&
                validationMessage.errors &&
                validationMessage.errors.length &&
                validationMessage.errors[0].errors &&
                validationMessage.errors[0].errors.length
              ) {
                this.$toast.error(validationMessage.errors[0].errors[0]);
              }
            }
          } else {
            this.$toast.error(
              "Ocorreu um erro na obtenção do pedido, por favor tente novamente."
            );
          }
          this.$router.push("/applications");
        });
    },
    getAllFiles() {
      ApplicationDataService.getFiles(this.application.id, null)
        .then((response: AxiosResponse<FileModel[]>) => {
          if (response && response.data) {
            if (this.application.isStudent) {
              var addressCertificateFile = response.data.find(
                (x) =>
                  x.resourceFileTypeId ===
                  ResourceFileType.StudentAddressCertificateFile
              );
              var registrationCertificateFile = response.data.find(
                (x) =>
                  x.resourceFileTypeId ===
                  ResourceFileType.StudentRegistrationCertificateFile
              );

              if (addressCertificateFile) {
                this.application.studentAddressCertificateFile =
                  addressCertificateFile;
              }

              if (registrationCertificateFile) {
                this.application.studentRegistrationCertificateFile =
                  registrationCertificateFile;
              }
            }

            this.application.taxCustomsAuthorityFiles = response.data.filter(
              (x) =>
                x.resourceFileTypeId ===
                ResourceFileType.TaxCustomsAuthorityFile
            );
            this.application.socialSecurityFiles = response.data.filter(
              (x) =>
                x.resourceFileTypeId === ResourceFileType.SocialSecurityFile
            );
          }
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            // Access to config, request, and response
            const err = error as AxiosError;
            if (err.response && err.response.status === 400) {
              // Just a stock error
              const validationMessage = err.response.data as ValidationMessage;
              if (
                validationMessage &&
                validationMessage.errors &&
                validationMessage.errors.length &&
                validationMessage.errors[0].errors &&
                validationMessage.errors[0].errors.length
              ) {
                this.$toast.error(validationMessage.errors[0].errors[0]);
              }
            }
          } else {
            this.$toast.error(
              "Ocorreu um erro na obtenção dos documentos, por favor tente novamente."
            );
          }
        });
    },
    downloadFile(resourceFile: FileModel) {
      ApplicationDataService.downloadResourceFile(resourceFile.resourceFileId)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = resourceFile.filename;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((error: Error | AxiosError) => {
          this.$toast.error(
            "Ocorreu um erro na obtenção do documento, por favor tente novamente."
          );
        });
    },
    goBack() {
      if (
        this.$router.currentRoute.value.path.indexOf(
          "applicationAnnualMaintenance"
        ) > 0
      ) {
        this.$router.back();
      } else {
        const applicationId = this.$route.params.id as string;
        this.$router.push(
          "/" + PARENT_ROUTE + "/" + applicationId + "/" + DETAILS
        );
      }
    },
    handleAddressChange() {
      this.isAddressSelected = false;
      this.application.address = {
        cp4: this.application.address.cp4,
        cp3: this.application.address.cp3,
        postalDesignation: "",
        arteryDesignation: "",
        localityId: null,
        locality: "",
        parishId: null,
        parish: "",
        countyId: null,
        county: "",
        districtId: null,
        district: "",
        doorNumber: "",
        floor: "",
        floorSuffix: "",
      } as ApplicationAddress;
    },
    handleStudentAddressChange() {
      this.isStudentAddressSelected = false;
      this.application.studentAddress = {
        cp4: this.application.studentAddress.cp4,
        cp3: this.application.studentAddress.cp3,
        postalDesignation: "",
        arteryDesignation: "",
        localityId: null,
        locality: "",
        parishId: null,
        parish: "",
        countyId: null,
        county: "",
        districtId: null,
        district: "",
        doorNumber: "",
        floor: "",
        floorSuffix: "",
      } as ApplicationAddress;
    },
    showAddressModal() {
      this.isAddressModalVisible = true;
    },
    closeAddressModal() {
      this.isAddressModalVisible = false;
    },
    showSuccessModal() {
      this.isSuccessModalVisible = true;
    },
    closeSuccessModal() {
      this.isSuccessModalVisible = false;
      this.goBack();
    },
    saveAddressModal(address: Address) {
      this.application.address.postalDesignation = address.postalDesignation;
      this.application.address.arteryDesignation = address.arteryDesignation;
      this.application.address.localityId = address.localityId;
      this.application.address.locality = address.locality;
      this.application.address.parishId = address.parishId;
      this.application.address.parish = address.parish;
      this.application.address.countyId = address.countyId;
      this.application.address.county = address.county;
      this.application.address.districtId = address.districtId;
      this.application.address.district = address.district;
      this.application.address.doorNumber = "";
      this.application.address.floor = "";
      this.application.address.floorSuffix = "";
      this.isAddressModalVisible = false;
      this.isAddressSelected = true;
      this.wasAddressSelectedOnce = true;
    },
    showStudentAddressModal() {
      this.isStudentAddressModalVisible = true;
    },
    closeStudentAddressModal() {
      this.isStudentAddressModalVisible = false;
    },
    saveStudentAddressModal(address: Address) {
      this.application.studentAddress.postalDesignation =
        address.postalDesignation;
      this.application.studentAddress.arteryDesignation =
        address.arteryDesignation;
      this.application.studentAddress.localityId = address.localityId;
      this.application.studentAddress.locality = address.locality;
      this.application.studentAddress.parishId = address.parishId;
      this.application.studentAddress.parish = address.parish;
      this.application.studentAddress.countyId = address.countyId;
      this.application.studentAddress.county = address.county;
      this.application.studentAddress.districtId = address.districtId;
      this.application.studentAddress.district = address.district;
      this.application.studentAddress.doorNumber = "";
      this.application.studentAddress.floor = "";
      this.application.studentAddress.floorSuffix = "";
      this.isStudentAddressModalVisible = false;
      this.isStudentAddressSelected = true;
      this.wasStudentAddressSelectedOnce = true;
    },
    onStudentAddressCertificateFileChange(
      event: Event,
      callback: (e: unknown, shouldValidate?: boolean | undefined) => void
    ) {
      if (event != null && event.target != null) {
        const target = event.target as HTMLInputElement;
        var files = target.files;

        if (files != null) {
          const file = files[0] as File;
          if (file.size / BYTES_IN_MEGABYTE > 5) {
            this.$toast.error("Tamanho máximo de 5Mb por documento excedido");
            return;
          }

          const fileExtension = file.name
            .substr(file.name.length - 4)
            .toUpperCase()
            .toLowerCase();
          if (fileExtension !== ".pdf") {
            this.$toast.error("Formato inválido. Insira documentos .pdf.");
            return;
          }

          FileHelpers.getFileModelFromFile(file)
            .then((fileModel) => {
              this.application.studentAddressCertificateFile = fileModel;
              callback(event, true);
            })
            .catch(() => {
              this.$toast.error(
                "Ocorreu um erro no carregamento do documento, por favor tente novamente."
              );
            });
        }
      }
    },
    onStudentRegistrationCertificateFileChange(
      event: Event,
      callback: (e: unknown, shouldValidate?: boolean | undefined) => void
    ) {
      if (event != null && event.target != null) {
        const target = event.target as HTMLInputElement;
        var files = target.files;

        if (files != null) {
          const file = files[0] as File;
          if (file.size / BYTES_IN_MEGABYTE > 5) {
            this.$toast.error("Tamanho máximo de 5Mb por documento excedido");
            return;
          }

          const fileExtension = file.name
            .substr(file.name.length - 4)
            .toUpperCase()
            .toLowerCase();
          if (fileExtension !== ".pdf") {
            this.$toast.error("Formato inválido. Insira documentos .pdf.");
            return;
          }

          FileHelpers.getFileModelFromFile(file)
            .then((fileModel) => {
              this.application.studentRegistrationCertificateFile = fileModel;
              callback(event, true);
            })
            .catch(() => {
              this.$toast.error(
                "Ocorreu um erro no carregamento do documento, por favor tente novamente."
              );
            });
        }
      }
    },
    onSocialSecurityFileChange(
      event: Event,
      callback: (e: unknown, shouldValidate?: boolean | undefined) => void
    ) {
      if (event != null && event.target != null) {
        const target = event.target as HTMLInputElement;
        var files = target.files;

        if (files != null) {
          for (let i = 0; i < files.length; ++i) {
            if (files[i].size / BYTES_IN_MEGABYTE > 5) {
              this.$toast.error("Tamanho máximo de 5Mb por documento excedido");
              return;
            }

            const fileExtension = files[i].name
              .substr(files[i].name.length - 4)
              .toUpperCase()
              .toLowerCase();
            if (fileExtension !== ".pdf") {
              this.$toast.error("Formato inválido. Insira documentos .pdf.");
              return;
            }
          }

          for (let i = 0; i < files.length; ++i) {
            const inputFile = files[i];
            const matchedFile = this.application.socialSecurityFiles.find(
              (file) => file.filename === inputFile.name
            );

            FileHelpers.getFileModelFromFile(inputFile)
              .then((fileModel) => {
                if (!matchedFile) {
                  this.application.socialSecurityFiles.push(fileModel);
                } else {
                  this.application.socialSecurityFiles[
                    this.application.socialSecurityFiles.findIndex(
                      (file) => file.filename === matchedFile?.filename
                    )
                  ] = fileModel;
                }

                if (
                  this.application.taxCustomsAuthorityFiles.length > 0 ||
                  this.application.socialSecurityFiles.length > 0
                ) {
                  this.recheckForm?.setFieldError(
                    "taxAuthorityFiles",
                    undefined
                  );
                  // this.applicationForm?.setFieldError(
                  //   "socialSecurityFiles",
                  //   undefined
                  // );
                  callback(event, true);
                }
              })
              .catch(() => {
                this.$toast.error(
                  "Ocorreu um erro no carregamento do documento, por favor tente novamente."
                );
                return;
              });
          }
        }
      }
    },
    onTaxAuthorityFileChange(
      event: Event,
      callback: (e: unknown, shouldValidate?: boolean | undefined) => void
    ) {
      if (event != null && event.target != null) {
        const target = event.target as HTMLInputElement;
        var files = target.files;

        if (files != null) {
          for (let i = 0; i < files.length; ++i) {
            if (files[i].size / BYTES_IN_MEGABYTE > 5) {
              this.$toast.error("Tamanho máximo de 5Mb por documento excedido");
              return;
            }

            const fileExtension = files[i].name
              .substr(files[i].name.length - 4)
              .toUpperCase()
              .toLowerCase();
            if (fileExtension !== ".pdf") {
              this.$toast.error("Formato inválido. Insira documentos .pdf.");
              return;
            }
          }

          for (let i = 0; i < files.length; ++i) {
            const inputFile = files[i];
            const matchedFile = this.application.taxCustomsAuthorityFiles.find(
              (file) => file.filename === inputFile.name
            );

            FileHelpers.getFileModelFromFile(inputFile)
              .then((fileModel) => {
                if (!matchedFile) {
                  this.application.taxCustomsAuthorityFiles.push(fileModel);
                } else {
                  this.application.taxCustomsAuthorityFiles[
                    this.application.taxCustomsAuthorityFiles.findIndex(
                      (file) => file.filename === matchedFile?.filename
                    )
                  ] = fileModel;
                }

                if (
                  this.application.taxCustomsAuthorityFiles.length > 0 ||
                  this.application.socialSecurityFiles.length > 0
                ) {
                  // this.applicationForm?.setFieldError(
                  //   "taxAuthorityFiles",
                  //   undefined
                  // );
                  this.recheckForm?.setFieldError(
                    "socialSecurityFiles",
                    undefined
                  );
                  callback(event, true);
                }
              })
              .catch(() => {
                this.$toast.error(
                  "Ocorreu um erro no carregamento do documento, por favor tente novamente."
                );
              });
          }
        }
      }
    },
    removeStudentRegistrationCertificateFile() {
      this.application.studentRegistrationCertificateFile = null;
      this.recheckForm?.setFieldError(
        "studentRegistrationCertificateFileInput",
        "Campo obrigatório."
      );
      const fileInput = document.getElementById(
        "studentRegistrationCertificateFileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    },
    removeStudentAddressCertificateFile() {
      this.application.studentAddressCertificateFile = null;
      this.recheckForm?.setFieldError(
        "studentAddressCertificateFileInput",
        "Campo obrigatório."
      );
      const fileInput = document.getElementById(
        "studentAddressCertificateFileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    },
    removeSocialSecurityFile(file: FileModel) {
      const fileIndex = this.application.socialSecurityFiles.indexOf(file);

      if (fileIndex > -1) {
        this.application.socialSecurityFiles.splice(fileIndex, 1);

        if (
          this.application.taxCustomsAuthorityFiles.length <= 0 &&
          this.application.socialSecurityFiles.length <= 0
        ) {
          this.recheckForm?.setFieldError(
            "taxAuthorityFileInput",
            "Campo obrigatório."
          );
          this.recheckForm?.setFieldError(
            "socialSecurityFileInput",
            "Campo obrigatório."
          );
        }
      }
      const fileInput = document.getElementById(
        "socialSecurityFileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    },
    removeTaxAuthorityFile(file: FileModel) {
      const fileIndex = this.application.taxCustomsAuthorityFiles.indexOf(file);
      if (fileIndex > -1) {
        this.application.taxCustomsAuthorityFiles.splice(fileIndex, 1);

        if (
          this.application.taxCustomsAuthorityFiles.length <= 0 &&
          this.application.socialSecurityFiles.length <= 0
        ) {
          this.recheckForm?.setFieldError(
            "taxAuthorityFileInput",
            "Campo obrigatório."
          );
          this.recheckForm?.setFieldError(
            "socialSecurityFileInput",
            "Campo obrigatório."
          );
        }
      }
      const fileInput = document.getElementById(
        "taxAuthorityFileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    },
  },
  mounted() {
    this.getApplication();
    this.$emit("scrollToTop");
  },
});
