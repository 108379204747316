
import { defineComponent } from "vue";

export default defineComponent({
  name: "ConfirmationModal",
  data() {
    return {
      accepted: false,
    };
  },
  props: {
    confirmationTitle: String,
    confirmationSubtitle: String,
    denyText: String,
    acceptText: String,
  },

  computed: {},
  methods: {
    accept() {
      this.accepted = true;
      this.$emit("accept");
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.$emit("scrollToTop");
  },
});
