import NavigationState from "@/models/NavigationState.model";

class SessionService {
  setNavigation(navigationState: NavigationState) {
    if (navigationState) {
      return (localStorage["navigationState"] =
        JSON.stringify(navigationState));
    }
    return (localStorage["navigationState"] = JSON.stringify(
      {} as NavigationState
    ));
  }

  getNavigation(): NavigationState {
    return localStorage["navigationState"]
      ? (JSON.parse(localStorage["navigationState"]) as NavigationState)
      : ({} as NavigationState);
  }

  resetSearch() {
    localStorage["search"] = JSON.stringify({});
  }

  changeSearch(route, searchObj) {
    this.resetSearch();

    const stringSearch = localStorage["search"];
    const currentSearch = stringSearch
      ? JSON.parse(localStorage["search"])
      : {};

    currentSearch[route] = searchObj;
    localStorage["search"] = JSON.stringify(currentSearch);
  }

  public getSearch(route) {
    if (localStorage["search"]) {
      return JSON.parse(localStorage["search"])[route];
    }
    return null;
  }
}

export default new SessionService();
