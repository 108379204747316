export enum AdministratorPermission {
  Applications_Reader = "Applications_Reader",
  Applications_Updater = "Applications_Updater",
  Users_Reader = "Users_Reader",
  Users_Writer = "Users_Writer",
  Users_Updater = "Users_Updater",
  Providers_Reader = "Providers_Reader",
  Providers_Writer = "Providers_Writer",
  Providers_Updater = "Providers_Updater",
  Configurations_Reader = "Configurations_Reader",
  Configurations_Updater = "Configurations_Updater",
  AnnualMaintenance_Reader = "AnnualMaintenance_Reader",
  AnnualMaintenance_Updater = "AnnualMaintenance_Updater",
}
export enum OperatorPermission {
  ApplicationsModule_Reader = "ApplicationsModule_Reader",
  ApplicationsModule_Writer = "ApplicationsModule_Writer",
  ApplicationsModule_Updater = "ApplicationsModule_Updater",
  AnnualMaintenanceModule_Reader = "AnnualMaintenanceModule_Reader",
  AnnualMaintenanceModule_Writer = "AnnualMaintenanceModule_Writer",
  AnnualMaintenanceModule_Updater = "AnnualMaintenanceModule_Updater",
}

export interface Permission {
  Administrator: AdministratorPermission;
  Operator: OperatorPermission;
}
