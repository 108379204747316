/* eslint-disable @typescript-eslint/no-explicit-any */
import authHeader from "@/helper/AuthHeader";
import router from "@/router";
import axios, { AxiosError, AxiosInstance } from "axios";
import store, { ActionTypes } from "@/store/index";
import RefreshAccessTokenRequestModel from "@/models/Requests/RefreshAccessTokenRequest.model";

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + "/api",
  headers: {
    "Content-type": "application/json",
  },
});

const authInterceptor = (config: any) => {
  config.headers = {
    ...config.headers,
    ...authHeader(),
  };
  store.dispatch(ActionTypes.SET_LOADING);
  return config;
};
const responseInterceptor = (response: any) => {
  store.dispatch(ActionTypes.UNSET_LOADING);
  return response;
};
const errorResponseInterceptor = (err: Error | AxiosError) => {
  store.dispatch(ActionTypes.UNSET_LOADING);
  if (axios.isAxiosError(err)) {
    const error = err as AxiosError;
    // check if it's a server error
    if (!error.response) {
      //notify.warn('Network/Server error');
      console.error("**Network/Server error");
      console.log(error.response);
      return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
      case 400:
        console.error(error.response.status, error.message);
        //notify.warn('Nothing to display', 'Data Not Found');
        break;
      case 401:
        //notify.warn('Please login again', 'Session Expired');
        console.error(error.response.status, error.message);
        return store
          .dispatch(ActionTypes.REFRESH_TOKEN)
          .then(() => {
            return apiClient(err.config);
          })
          .catch(() => {
            router.push("/Login");
            Promise.reject();
          });

      default:
        console.error(error.response.status, error.message);
      //notify.error('Server Error');
    }
  }
  return Promise.reject(err);
};
apiClient.interceptors.request.use(authInterceptor, () => {
  store.dispatch(ActionTypes.UNSET_LOADING);
});
apiClient.interceptors.response.use(
  responseInterceptor,
  errorResponseInterceptor
);
export default apiClient;
