
import { defineComponent, ref } from "vue";
import { Form, Field, configure, FormState } from "vee-validate";
import ProviderDataService from "@/services/ProviderDataService";
import ApplicationDataService from "@/services/ApplicationDataService";
import Provider from "@/models/Provider.model";
import AddressModal from "@/components/AddressModal.vue";
import SuccessModal from "@/components/SuccessModal.vue";
import Application from "@/models/Application.model";
import ApplicationAddress from "@/models/ApplicationAddress.model";
import Address from "@/models/Address.model";
import FileModel from "@/models/File.model";
import CreateApplicationRequestModel from "@/models/Requests/CreateApplicationRequest.model";
import CreateApplicationResponseModel from "@/models/Responses/CreateApplicationResponse.model";
import ValidationMessage from "@/models/ValidationMessage.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import ProviderContextResponseModel from "@/models/Responses/ProviderContextResponse.model";
import * as ValidationHelpers from "@/helper/ValidationHelpers";
import * as FileHelpers from "@/helper/FileHelpers";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import { OperatorPermission } from "@/helper/Permission";

const BYTES_IN_MEGABYTE = 1000000;

export default defineComponent({
  name: "ApplicationForm",
  components: {
    Form,
    Field,
    AddressModal,
    SuccessModal,
  },
  data() {
    return {
      isAddressModalVisible: false,
      isAddressSelected: false,
      wasAddressSelectedOnce: false,
      isStudentAddressModalVisible: false,
      isStudentAddressSelected: false,
      wasStudentAddressSelectedOnce: false,
      isSuccessModalVisible: false,
      defaultProviderId: -1,
      providers: [] as Array<Provider>,
      providerId: -1,
      providerEmail: "",
      application: {
        id: null,
        fullName: "",
        fiscalNumber: "",
        address: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        isStudent: false,
        studentFullName: "",
        studentFiscalNumber: "",
        studentAddress: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        studentAddressCertificateFile: null as FileModel | null,
        studentRegistrationCertificateFile: null as FileModel | null,
        applicantGaveConsent: false,
        applicantProvidedRequiredDocuments: false,
        taxCustomsAuthorityFiles: [] as FileModel[],
        socialSecurityFiles: [] as FileModel[],
      } as Application,
    };
  },
  computed: {
    schema() {
      const application = this.application;
      const schema = {
        fullName(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          // All is good
          return true;
        },
        fiscalNumber(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }

          if (!ValidationHelpers.validateNIF(value)) {
            return "NIF inválido";
          }

          return true;
        },
        providerId(value: number) {
          // if the field is empty
          if (!value || value <= 0) {
            return "Campo obrigatório.";
          }
          // All is good
          return true;
        },
        providerEmail(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          // if the field is not a valid email
          // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          const regex =
            /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i;
          if (!regex.test(value)) {
            return "E-mail inválido.";
          }
          // All is good
          return true;
        },
        addressCp4(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          if (
            !ValidationHelpers.isPositiveNumeric(value) ||
            value.length !== 4
          ) {
            return "Código postal inválido";
          }

          return true;
        },
        addressCp3(value: string) {
          // if the field is empty
          if (!value) {
            return "Campo obrigatório.";
          }
          if (
            !ValidationHelpers.isPositiveNumeric(value) ||
            value.length !== 3
          ) {
            return "Código postal inválido";
          }

          return true;
        },
        studentAddressCp4(value: string) {
          if (application.isStudent) {
            // if the field is empty
            if (!value) {
              return "Campo obrigatório.";
            }
            if (
              !ValidationHelpers.isPositiveNumeric(value) ||
              value.length !== 4
            ) {
              return "Código postal inválido";
            }
          }

          return true;
        },
        studentAddressCp3(value: string) {
          if (application.isStudent) {
            // if the field is empty
            if (!value) {
              return "Campo obrigatório.";
            }
            if (
              !ValidationHelpers.isPositiveNumeric(value) ||
              value.length !== 3
            ) {
              return "Código postal inválido";
            }
          }
          return true;
        },
        studentFiscalNumber(value: string) {
          if (application.isStudent) {
            // if the field is empty
            if (!value) {
              return "Campo obrigatório.";
            }

            if (!ValidationHelpers.validateNIF(value)) {
              return "NIF inválido";
            }
          }

          return true;
        },
        studentFullName(value: string) {
          if (application.isStudent) {
            // if the field is empty
            if (!value) {
              return "Campo obrigatório.";
            }
          }

          return true;
        },
        // applicantGaveConsent(value: boolean) {
        //   // if the field is empty
        //   if (!value) {
        //     return "Campo obrigatório.";
        //   }

        //   return true;
        // },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        studentRegistrationCertificateFileInput(value: any) {
          if (application.isStudent) {
            if (!application.studentRegistrationCertificateFile) {
              return "Campo obrigatório.";
            }
          }
          return true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        studentAddressCertificateFileInput(value: any) {
          if (application.isStudent) {
            if (!application.studentAddressCertificateFile) {
              return "Campo obrigatório.";
            }
          }
          return true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        socialSecurityFileInput(value: any) {
          if (application.applicantProvidedRequiredDocuments) {
            if (
              application.taxCustomsAuthorityFiles.length <= 0 &&
              application.socialSecurityFiles.length <= 0
            ) {
              return "Campo obrigatório.";
            }
          }
          return true;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
        taxAuthorityFileInput(value: any) {
          if (application.applicantProvidedRequiredDocuments) {
            if (
              application.taxCustomsAuthorityFiles.length <= 0 &&
              application.socialSecurityFiles.length <= 0
            ) {
              return "Campo obrigatório.";
            }
          }
          return true;
        },
      };

      return schema;
    },
    isStudentFieldRequired(): boolean {
      return this.application.isStudent;
    },
    isAddressSearchButtonDisabled(): boolean {
      var cp4IsValid =
        this.application.address.cp4 &&
        ValidationHelpers.isPositiveNumeric(this.application.address.cp4) &&
        this.application.address.cp4.length === 4;
      var cp3IsValid =
        this.application.address.cp3 &&
        ValidationHelpers.isPositiveNumeric(this.application.address.cp3) &&
        this.application.address.cp3.length === 3;

      return !cp4IsValid || !cp3IsValid;
    },
    isStudentAddressSearchButtonDisabled(): boolean {
      var cp4IsValid =
        this.application.studentAddress.cp4 &&
        ValidationHelpers.isPositiveNumeric(
          this.application.studentAddress.cp4
        ) &&
        this.application.studentAddress.cp4.length === 4;
      var cp3IsValid =
        this.application.studentAddress.cp3 &&
        ValidationHelpers.isPositiveNumeric(
          this.application.studentAddress.cp3
        ) &&
        this.application.studentAddress.cp3.length === 3;

      return !cp4IsValid || !cp3IsValid;
    },
    successModalTitle(): string | undefined {
      if (this.application.id) {
        return `Pedido de verificação de elegibilidade submetido com sucesso com o número [${this.application.id}].`;
      }
      return undefined;
    },
    successModalSubtitle(): string | undefined {
      if (this.application.id) {
        return `Será notificado via e-mail do resultado do pedido de verificação de elegibilidade.`;
      }
      return undefined;
    },
    successModalGoBackText(): string {
      return "Voltar à página Inicial";
    },
    isAddressButtonRequired(): boolean {
      return (
        this.application.address.cp4.length === 4 &&
        this.application.address.cp3.length === 3 &&
        !this.isAddressSelected
      );
    },
    isStudentAddressButtonRequired(): boolean {
      return (
        this.application.isStudent &&
        this.application.studentAddress.cp4.length === 4 &&
        this.application.studentAddress.cp3.length === 3 &&
        !this.isStudentAddressSelected
      );
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const applicationForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      applicationForm,
    };
  },
  methods: {
    canAccessWriter() {
      return AuthenticationDataService.canUserAccess([
        OperatorPermission.ApplicationsModule_Writer,
      ]);
    },
    submitForm() {
      this.applicationForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (
            result.valid &&
            this.isAddressSelected &&
            (!this.application.isStudent || this.isStudentAddressSelected)
          ) {
            const createApplicationRequest = {
              providerId: this.providerId,
              providerEmail: this.providerEmail,
              applications: [this.application],
            } as CreateApplicationRequestModel;

            ApplicationDataService.create(createApplicationRequest)
              .then((resp: AxiosResponse<CreateApplicationResponseModel[]>) => {
                if (resp) {
                  if (resp.data && resp.data.every((x) => x.success)) {
                    this.application.id = resp.data[0].applicationId;
                    this.showSuccessModal();
                  } else {
                    this.$toast.error(
                      "Ocorreu um erro na submissão do pedido, por favor tente novamente."
                    );
                  }
                }
              })
              .catch((error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                  // Access to config, request, and response
                  const err = error as AxiosError;
                  if (err.response && err.response.status === 400) {
                    // Just a stock error
                    const validationMessage = err.response
                      .data as ValidationMessage;
                    if (
                      validationMessage &&
                      validationMessage.errors &&
                      validationMessage.errors.length &&
                      validationMessage.errors[0].errors &&
                      validationMessage.errors[0].errors.length
                    ) {
                      this.$toast.error(validationMessage.errors[0].errors[0]);
                    }
                  }
                } else {
                  // Just a stock error
                  this.$toast.error(
                    "Ocorreu um erro na submissão do pedido, por favor tente novamente."
                  );
                }
              });
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    resetForm() {
      this.applicationForm?.resetForm();
      this.isAddressSelected = false;
      this.isStudentAddressSelected = false;
      this.isAddressModalVisible = false;
      this.wasAddressSelectedOnce = false;
      this.isStudentAddressModalVisible = false;
      this.wasStudentAddressSelectedOnce = false;
      this.isSuccessModalVisible = false;
      this.providerId = -1;
      this.providerEmail = "";
      this.application = {
        id: null,
        fullName: "",
        fiscalNumber: "",
        address: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        isStudent: false,
        studentFullName: "",
        studentFiscalNumber: "",
        studentAddress: {
          cp4: "",
          cp3: "",
          postalDesignation: "",
          arteryDesignation: "",
          localityId: null,
          locality: "",
          parishId: null,
          parish: "",
          countyId: null,
          county: "",
          districtId: null,
          district: "",
          doorNumber: "",
          floor: "",
          floorSuffix: "",
        } as ApplicationAddress,
        studentAddressCertificateFile: null as FileModel | null,
        studentRegistrationCertificateFile: null as FileModel | null,
        applicantGaveConsent: false,
        applicantProvidedRequiredDocuments: false,
        taxCustomsAuthorityFiles: [] as FileModel[],
        socialSecurityFiles: [] as FileModel[],
      } as Application;
    },
    getProviders() {
      ProviderDataService.getProviderContext()
        .then((response: AxiosResponse<ProviderContextResponseModel>) => {
          if (response) {
            this.providers = [
              {
                id: response.data.providerId,
                designation: response.data.providerDescription,
              } as Provider,
            ];
            this.providerId = response.data.providerId;
            // this.providerEmail = response.data.providerEmail;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    toggleIsStudent() {
      this.application.isStudent = !this.application.isStudent;

      // Always reset the other student related fields
      this.handleStudentAddressChange();
      this.application.studentAddress.cp4 = "";
      this.application.studentAddress.cp3 = "";
      this.application.studentFullName = "";
      this.application.studentFiscalNumber = "";
      this.removeStudentRegistrationCertificateFile();
      this.removeStudentAddressCertificateFile();
    },
    handleAddressChange() {
      this.isAddressSelected = false;
      this.application.address = {
        cp4: this.application.address.cp4,
        cp3: this.application.address.cp3,
        postalDesignation: "",
        arteryDesignation: "",
        localityId: null,
        locality: "",
        parishId: null,
        parish: "",
        countyId: null,
        county: "",
        districtId: null,
        district: "",
        doorNumber: "",
        floor: "",
        floorSuffix: "",
      } as ApplicationAddress;
    },
    handleStudentAddressChange() {
      this.isStudentAddressSelected = false;
      this.application.studentAddress = {
        cp4: this.application.studentAddress.cp4,
        cp3: this.application.studentAddress.cp3,
        postalDesignation: "",
        arteryDesignation: "",
        localityId: null,
        locality: "",
        parishId: null,
        parish: "",
        countyId: null,
        county: "",
        districtId: null,
        district: "",
        doorNumber: "",
        floor: "",
        floorSuffix: "",
      } as ApplicationAddress;
    },
    showAddressModal() {
      this.isAddressModalVisible = true;
    },
    closeAddressModal() {
      this.isAddressModalVisible = false;
    },
    showSuccessModal() {
      this.isSuccessModalVisible = true;
    },
    closeSuccessModal() {
      this.isSuccessModalVisible = false;
      this.resetForm();
      this.$emit("scrollToTop");
    },
    saveAddressModal(address: Address) {
      this.application.address.postalDesignation = address.postalDesignation;
      this.application.address.arteryDesignation = address.arteryDesignation;
      this.application.address.localityId = address.localityId;
      this.application.address.locality = address.locality;
      this.application.address.parishId = address.parishId;
      this.application.address.parish = address.parish;
      this.application.address.countyId = address.countyId;
      this.application.address.county = address.county;
      this.application.address.districtId = address.districtId;
      this.application.address.district = address.district;
      this.application.address.doorNumber = "";
      this.application.address.floor = "";
      this.application.address.floorSuffix = "";
      this.isAddressModalVisible = false;
      this.wasAddressSelectedOnce = true;
      this.isAddressSelected = true;
    },
    showStudentAddressModal() {
      this.isStudentAddressModalVisible = true;
    },
    closeStudentAddressModal() {
      this.isStudentAddressModalVisible = false;
    },
    saveStudentAddressModal(address: Address) {
      this.application.studentAddress.postalDesignation =
        address.postalDesignation;
      this.application.studentAddress.arteryDesignation =
        address.arteryDesignation;
      this.application.studentAddress.localityId = address.localityId;
      this.application.studentAddress.locality = address.locality;
      this.application.studentAddress.parishId = address.parishId;
      this.application.studentAddress.parish = address.parish;
      this.application.studentAddress.countyId = address.countyId;
      this.application.studentAddress.county = address.county;
      this.application.studentAddress.districtId = address.districtId;
      this.application.studentAddress.district = address.district;
      this.application.studentAddress.doorNumber = "";
      this.application.studentAddress.floor = "";
      this.application.studentAddress.floorSuffix = "";
      this.isStudentAddressModalVisible = false;
      this.wasStudentAddressSelectedOnce = true;
      this.isStudentAddressSelected = true;
    },
    onStudentAddressCertificateFileChange(
      event: Event,
      callback: (e: unknown, shouldValidate?: boolean | undefined) => void
    ) {
      if (event != null && event.target != null) {
        const target = event.target as HTMLInputElement;
        var files = target.files;

        if (files != null) {
          const file = files[0] as File;
          if (file.size / BYTES_IN_MEGABYTE > 5) {
            this.$toast.error("Tamanho máximo de 5Mb por documento excedido");
            return;
          }

          const fileExtension = file.name
            .substr(file.name.length - 4)
            .toUpperCase()
            .toLowerCase();
          if (fileExtension !== ".pdf") {
            this.$toast.error("Formato inválido. Insira documentos .pdf.");
            return;
          }

          FileHelpers.getFileModelFromFile(file)
            .then((fileModel) => {
              this.application.studentAddressCertificateFile = fileModel;
              callback(event, true);
            })
            .catch(() => {
              this.$toast.error(
                "Ocorreu um erro no carregamento do documento, por favor tente novamente."
              );
            });
        }
      }
    },
    onStudentRegistrationCertificateFileChange(
      event: Event,
      callback: (e: unknown, shouldValidate?: boolean | undefined) => void
    ) {
      if (event != null && event.target != null) {
        const target = event.target as HTMLInputElement;
        var files = target.files;

        if (files != null) {
          const file = files[0] as File;
          if (file.size / BYTES_IN_MEGABYTE > 5) {
            this.$toast.error("Tamanho máximo de 5Mb por documento excedido");
            return;
          }

          const fileExtension = file.name
            .substr(file.name.length - 4)
            .toUpperCase()
            .toLowerCase();
          if (fileExtension !== ".pdf") {
            this.$toast.error("Formato inválido. Insira documentos .pdf.");
            return;
          }

          FileHelpers.getFileModelFromFile(file)
            .then((fileModel) => {
              this.application.studentRegistrationCertificateFile = fileModel;
              callback(event, true);
            })
            .catch(() => {
              this.$toast.error(
                "Ocorreu um erro no carregamento do documento, por favor tente novamente."
              );
            });
        }
      }
    },
    onApplicantProvidedRequiredDocumentsChange() {
      if (
        this.application.taxCustomsAuthorityFiles.length <= 0 &&
        this.application.socialSecurityFiles.length <= 0
      ) {
        this.applicationForm?.setFieldError(
          "taxAuthorityFileInput",
          "Campo obrigatório."
        );
        this.applicationForm?.setFieldError(
          "socialSecurityFileInput",
          "Campo obrigatório."
        );
      }
    },
    onSocialSecurityFileChange(
      event: Event,
      callback: (e: unknown, shouldValidate?: boolean | undefined) => void
    ) {
      if (event != null && event.target != null) {
        const target = event.target as HTMLInputElement;
        var files = target.files;

        if (files != null) {
          for (let i = 0; i < files.length; ++i) {
            if (files[i].size / BYTES_IN_MEGABYTE > 5) {
              this.$toast.error("Tamanho máximo de 5Mb por documento excedido");
              return;
            }

            const fileExtension = files[i].name
              .substr(files[i].name.length - 4)
              .toUpperCase()
              .toLowerCase();
            if (fileExtension !== ".pdf") {
              this.$toast.error("Formato inválido. Insira documentos .pdf.");
              return;
            }
          }

          for (let i = 0; i < files.length; ++i) {
            const inputFile = files[i];
            if (
              this.application.socialSecurityFiles.find(
                (file) => file.filename === inputFile.name
              ) === undefined
            ) {
              FileHelpers.getFileModelFromFile(inputFile)
                .then((fileModel) => {
                  this.application.socialSecurityFiles.push(fileModel);

                  if (
                    this.application.taxCustomsAuthorityFiles.length > 0 ||
                    this.application.socialSecurityFiles.length > 0
                  ) {
                    this.applicationForm?.setFieldError(
                      "taxAuthorityFileInput",
                      undefined
                    );
                    this.applicationForm?.setFieldError(
                      "socialSecurityFileInput",
                      undefined
                    );
                    callback(event, true);
                  }
                })
                .catch(() => {
                  this.$toast.error(
                    "Ocorreu um erro no carregamento do documento, por favor tente novamente."
                  );
                  return;
                });
            }
          }
        }
      }
    },
    onTaxAuthorityFileChange(
      event: Event,
      callback: (e: unknown, shouldValidate?: boolean | undefined) => void
    ) {
      if (event != null && event.target != null) {
        const target = event.target as HTMLInputElement;
        var files = target.files;

        if (files != null) {
          for (let i = 0; i < files.length; ++i) {
            if (files[i].size / BYTES_IN_MEGABYTE > 5) {
              this.$toast.error("Tamanho máximo de 5Mb por documento excedido");
              return;
            }

            const fileExtension = files[i].name
              .substr(files[i].name.length - 4)
              .toUpperCase()
              .toLowerCase();
            if (fileExtension !== ".pdf") {
              this.$toast.error("Formato inválido. Insira documentos .pdf.");
              return;
            }
          }

          for (let i = 0; i < files.length; ++i) {
            const inputFile = files[i];
            if (
              this.application.taxCustomsAuthorityFiles.find(
                (file) => file.filename === inputFile.name
              ) === undefined
            ) {
              FileHelpers.getFileModelFromFile(inputFile)
                .then((fileModel) => {
                  this.application.taxCustomsAuthorityFiles.push(fileModel);

                  if (
                    this.application.taxCustomsAuthorityFiles.length > 0 ||
                    this.application.socialSecurityFiles.length > 0
                  ) {
                    this.applicationForm?.setFieldError(
                      "taxAuthorityFileInput",
                      undefined
                    );
                    this.applicationForm?.setFieldError(
                      "socialSecurityFileInput",
                      undefined
                    );
                    callback(event, true);
                  }
                })
                .catch(() => {
                  this.$toast.error(
                    "Ocorreu um erro no carregamento do documento, por favor tente novamente."
                  );
                });
            }
          }
        }
      }
    },
    removeStudentRegistrationCertificateFile() {
      this.application.studentRegistrationCertificateFile = null;
      this.applicationForm?.setFieldError(
        "studentRegistrationCertificateFileInput",
        "Campo obrigatório."
      );
      const fileInput = document.getElementById(
        "studentRegistrationCertificateFileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    },
    removeStudentAddressCertificateFile() {
      this.application.studentAddressCertificateFile = null;
      this.applicationForm?.setFieldError(
        "studentAddressCertificateFileInput",
        "Campo obrigatório."
      );
      const fileInput = document.getElementById(
        "studentAddressCertificateFileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    },
    removeSocialSecurityFile(file: FileModel) {
      const fileIndex = this.application.socialSecurityFiles.indexOf(file);

      if (fileIndex > -1) {
        this.application.socialSecurityFiles.splice(fileIndex, 1);

        if (
          this.application.taxCustomsAuthorityFiles.length <= 0 &&
          this.application.socialSecurityFiles.length <= 0
        ) {
          this.applicationForm?.setFieldError(
            "taxAuthorityFileInput",
            "Campo obrigatório."
          );
          this.applicationForm?.setFieldError(
            "socialSecurityFileInput",
            "Campo obrigatório."
          );
        }
      }
      const fileInput = document.getElementById(
        "socialSecurityFileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    },
    removeTaxAuthorityFile(file: FileModel) {
      const fileIndex = this.application.taxCustomsAuthorityFiles.indexOf(file);
      if (fileIndex > -1) {
        this.application.taxCustomsAuthorityFiles.splice(fileIndex, 1);

        if (
          this.application.taxCustomsAuthorityFiles.length <= 0 &&
          this.application.socialSecurityFiles.length <= 0
        ) {
          this.applicationForm?.setFieldError(
            "taxAuthorityFileInput",
            "Campo obrigatório."
          );
          this.applicationForm?.setFieldError(
            "socialSecurityFileInput",
            "Campo obrigatório."
          );
        }
      }
      const fileInput = document.getElementById(
        "taxAuthorityFileInput"
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = "";
      }
    },
  },
  mounted() {
    this.getProviders();
    this.$emit("scrollToTop");
  },
});
