import http from "@/services/HttpCommons";
import { AxiosResponse } from "axios";
import GetApplicationHistoryResponseModel from "@/models/Responses/GetApplicationHistoryResponse.model";

class ApplicationDataService {
  private CONTROLLER_BASE = "/ApplicationHistory";

  getByApplicationId(
    applicationId: string
  ): Promise<AxiosResponse<GetApplicationHistoryResponseModel>> {
    return http.get(this.CONTROLLER_BASE + "/get-by-application-id", {
      params: { applicationId: applicationId },
    });
  }
}

export default new ApplicationDataService();
