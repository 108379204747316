
import { defineComponent, PropType, ref } from "vue";
import { configure, Form, Field, FormState } from "vee-validate";
import ReasonModel from "@/models/Reason.model";
import ApplicationDataService from "@/services/ApplicationDataService";
import axios, { AxiosError, AxiosResponse } from "axios";
import ValidationMessage from "@/models/ValidationMessage.model";
import CancelApplicationRequestModel from "@/models/Requests/CancelApplicationRequest.model";
import ApplicationCancellationModel from "@/models/ApplicationCancellation.model";
import CancelApplicationResponseModel from "@/models/Responses/CancelApplicationResponse.model";
import ApplicationCancellationReasonsResponseModel from "@/models/Responses/ApplicationCancellationReasonsResponse.model";
import Application from "@/models/Application.model";

export default defineComponent({
  name: "CancellationApplicationModal",
  components: {
    Form,
    Field,
  },
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
  },
  data() {
    return {
      defaultReasonId: -1,
      reasons: [] as Array<ReasonModel>,
      isDelayedcancellation: false,
      formData: {
        applicationId: this.application?.id,
        cancellationDate: new Date(),
        cancellationReasonId: -1,
      } as ApplicationCancellationModel,
    };
  },
  computed: {
    schema() {
      const schema = {
        cancellationReasonId(value: number) {
          // if the field is empty
          if (!value || value <= 0) {
            return "Campo obrigatório.";
          }
          // All is good
          return true;
        },
      };

      return schema;
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const cancellationForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      cancellationForm,
    };
  },
  methods: {
    yes() {
      this.cancellationForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            const request = {
              applicationCancellations: [
                this.formData,
              ] as Array<ApplicationCancellationModel>,
            } as CancelApplicationRequestModel;

            ApplicationDataService.cancelApplication(request)
              .then((resp: AxiosResponse<CancelApplicationResponseModel>) => {
                if (resp) {
                  if (resp.data && resp.data.data.every((x) => x.success)) {
                    this.$toast.success("Pedido cancelado com sucesso.");
                    this.$emit("yes");
                  } else {
                    this.$toast.error(
                      "Ocorreu um erro no cancelamento do pedido, por favor tente novamente."
                    );
                  }
                }
              })
              .catch((error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                  // Access to config, request, and response
                  const err = error as AxiosError;
                  if (err.response && err.response.status === 400) {
                    // Just a stock error
                    const validationMessage = err.response
                      .data as ValidationMessage;
                    if (
                      validationMessage &&
                      validationMessage.errors &&
                      validationMessage.errors.length &&
                      validationMessage.errors[0].errors &&
                      validationMessage.errors[0].errors.length
                    ) {
                      this.$toast.error(validationMessage.errors[0].errors[0]);
                    }
                  }
                } else {
                  // Just a stock error
                  console.log(error);
                  this.$toast.error(
                    "Ocorreu um erro no cancelamento do pedido, por favor tente novamente."
                  );
                }
              });
          }
        });
    },
    no() {
      this.$emit("no");
    },
    close() {
      this.$emit("close");
    },
    getReasons() {
      ApplicationDataService.getCancellationReasons()
        .then(
          (
            response: AxiosResponse<ApplicationCancellationReasonsResponseModel>
          ) => {
            if (response && response.data) {
              this.reasons = response.data.data;
            }
          }
        )
        .catch((e: Error) => {
          console.log(e);
        });
    },
    isDelayedcancellationToggle() {
      this.formData.cancellationReasonId = this.defaultReasonId;
    },
  },
  mounted() {
    this.getReasons();
    this.$emit("scrollToTop");
  },
});
