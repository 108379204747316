import http from "@/services/HttpCommons";
import CreateUserResponseModel from "@/models/Responses/CreateUserResponse.model";
import CreateUserRequestModel from "@/models/Requests/CreateUserRequest.model";
import { AxiosResponse } from "axios";
import PagedResponseModel from "@/models/Responses/PagedResponse.model";
import SearchUsersRequestModel from "@/models/Requests/SearchUsersRequest.model";
import UserResponseModel from "@/models/Responses/UserResponse.model";
import UserStatusesResponseModel from "@/models/Responses/UserStatusesResponse.model";
import User from "@/models/User.model";
import PermissionsResponseModel from "@/models/Responses/PermissionsResponse.model";
import UpdateUserResponseModel from "@/models/Responses/UpdateUserResponse.model";
import UpdateUserRequestModel from "@/models/Requests/UpdateUserRequest.model";
import SearchProvidersRequestModel from "@/models/Requests/SearchProvidersRequest.model";
import ProviderResponseModel from "@/models/Responses/ProviderResponse.model";
import SearchProviderUsersRequestModel from "@/models/Requests/SearchProviderUsersRequest.model";

class UserDataService {
  private CONTROLLER_BASE = "/User";

  create(
    data: CreateUserRequestModel
  ): Promise<AxiosResponse<CreateUserResponseModel[]>> {
    return http.post(this.CONTROLLER_BASE + "/create", data);
  }

  update(
    data: UpdateUserRequestModel
  ): Promise<AxiosResponse<UpdateUserResponseModel[]>> {
    return http.post(this.CONTROLLER_BASE + "/update", data);
  }

  search(
    data: SearchUsersRequestModel
  ): Promise<AxiosResponse<PagedResponseModel<UserResponseModel>>> {
    return http.post(this.CONTROLLER_BASE + "/search", data);
  }

  createProvider(
    data: CreateUserRequestModel
  ): Promise<AxiosResponse<CreateUserResponseModel[]>> {
    return http.post(this.CONTROLLER_BASE + "/create-provider", data);
  }

  updateProvider(
    data: UpdateUserRequestModel
  ): Promise<AxiosResponse<UpdateUserResponseModel[]>> {
    return http.post(this.CONTROLLER_BASE + "/update-provider", data);
  }

  searchProviders(
    data: SearchProvidersRequestModel
  ): Promise<AxiosResponse<PagedResponseModel<ProviderResponseModel>>> {
    return http.post(this.CONTROLLER_BASE + "/search-providers", data);
  }

  searchProviderUsers(
    data: SearchProviderUsersRequestModel
  ): Promise<AxiosResponse<PagedResponseModel<UserResponseModel>>> {
    return http.post(this.CONTROLLER_BASE + "/search-users-by-provider", data);
  }

  getUserStatuses(): Promise<AxiosResponse<UserStatusesResponseModel>> {
    return http.get(this.CONTROLLER_BASE + "/get-statuses");
  }

  getById(id: number): Promise<AxiosResponse<User>> {
    return http.get(this.CONTROLLER_BASE + "/get-by-id", {
      params: { userId: id },
    });
  }

  getPermissionsByUserTypeId(
    userTypeId: number
  ): Promise<AxiosResponse<PermissionsResponseModel>> {
    return http.get(this.CONTROLLER_BASE + "/get-permissions-by-user-type-id", {
      params: { userTypeId: userTypeId },
    });
  }
}

export default new UserDataService();
