
import { defineComponent, ref } from "vue";
import { Form, Field, configure, FormState } from "vee-validate";
import PasswordRecoveryRequestModel from "@/models/Requests/PasswordRecoveryRequest.model";
import axios, { AxiosError, AxiosResponse } from "axios";
import router from "@/router";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import PasswordRecoveryResponseModel from "@/models/Responses/PasswordRecoveryResponse.model";
import ValidationMessage from "@/models/ValidationMessage.model";
import BusinessException from "@/models/BusinessException.model";

export default defineComponent({
  name: "PasswordRecoveryForm",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      email: "",
      loginError: "",
      isSuccessModalVisible: false,
    };
  },
  computed: {
    schema() {
      const schema = {
        email(value: string) {
          // Email is mandatory
          if (!value) {
            return "Campo obrigatório.";
          }
          // Email format validation
          const regex =
            /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i;
          if (!regex.test(value)) {
            return "E-mail inválido.";
          }
          // All is good
          return true;
        },
      };
      return schema;
    },
  },
  setup() {
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const passwordRecoveryForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      passwordRecoveryForm,
    };
  },
  methods: {
    submitForm() {
      this.passwordRecoveryForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            const passwordRecoveryRequest = {
              username: this.email,
            } as PasswordRecoveryRequestModel;

            AuthenticationDataService.passwordRecovery(passwordRecoveryRequest)
              .then((resp: AxiosResponse<PasswordRecoveryResponseModel>) => {
                if (resp) {
                  this.$toast.success(
                    "E-mail de reposição da palavra-passe enviado!"
                  );
                } else {
                  this.$toast.error(
                    "Ocorreu um erro na reposição da palavra-passe, por favor tente novamente."
                  );
                }
                const redirectUrl =
                  this.$route.query.redirect?.toString() || "/";
                router.push({ path: redirectUrl });
              })
              .catch((error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                  // Access to config, request, and response
                  const err = error as AxiosError;
                  if (err.response && err.response.status === 400) {
                    // Just a stock error
                    const validationMessage = err.response
                      .data as ValidationMessage;
                    if (
                      validationMessage &&
                      validationMessage.errors &&
                      validationMessage.errors.length &&
                      validationMessage.errors[0].errors &&
                      validationMessage.errors[0].errors.length
                    ) {
                      this.$toast.error(validationMessage.errors[0].errors[0]);
                    }
                  } else {
                    const businessEx = err.response!.data as BusinessException;

                    this.$toast.error(businessEx.message);
                  }
                } else {
                  // Just a stock error
                  this.$toast.error(
                    "Ocorreu um erro na submissão do pedido, por favor tente novamente."
                  );
                }
              });
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    resetForm() {
      this.passwordRecoveryForm?.resetForm();
      this.isSuccessModalVisible = false;
      this.loginError = "";
    },
    showSuccessModal() {
      this.isSuccessModalVisible = true;
    },
    closeSuccessModal() {
      this.isSuccessModalVisible = false;
      this.resetForm();
      this.$emit("scrollToTop");
    },
  },
});
