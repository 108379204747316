
import { defineComponent, PropType, ref } from "vue";
import { configure, Form, Field, FormState } from "vee-validate";
import ReasonModel from "@/models/Reason.model";
import ApplicationDataService from "@/services/ApplicationDataService";
import axios, { AxiosError, AxiosResponse } from "axios";
import ApplicationActivationReasonsResponseModel from "@/models/Responses/ApplicationActivationReasonsResponse.model";
import ApplicationActivationModel from "@/models/ApplicationActivation.model";
import * as DateHelpers from "@/helper/DateHelpers";
import SetActivationApplicationRequestModel from "@/models/Requests/SetActivationApplicationRequest.model";
import SetActivationApplicationResponseModel from "@/models/Responses/SetActivationApplicationResponse.model";
import ValidationMessage from "@/models/ValidationMessage.model";
import Application from "@/models/Application.model";

const BENEFICIARY_OTHER_REASON = 4;
const PROVIDER_OTHER_REASON = 5;

export default defineComponent({
  name: "ActivateApplicationModal",
  components: {
    Form,
    Field,
  },
  props: {
    application: {
      type: Object as PropType<Application>,
      required: true,
    },
  },
  data() {
    return {
      defaultReasonId: -1,
      reasons: [] as Array<ReasonModel>,
      isDelayedActivation: false,
      activateModel: {
        applicationId: this.application && this.application.id,
        activationStartDate: undefined,
        activationStartReasonId: -1,
        activationStartOtherReason: "",
      } as ApplicationActivationModel,
    };
  },
  computed: {
    schema() {
      const schema = {};

      return schema;
    },
    isReasonOtherReason(): boolean {
      return (
        this.activateModel.activationStartReasonId ===
          BENEFICIARY_OTHER_REASON ||
        this.activateModel.activationStartReasonId === PROVIDER_OTHER_REASON
      );
    },
    datepickerMinDate() {
      if (this.application.eligibilityNotificationSentDate) {
        var applicationNotificationDate = new Date(
          this.application.eligibilityNotificationSentDate
        );
        var eligibilityNotificationSentDate = new Date(
          applicationNotificationDate.getFullYear(),
          applicationNotificationDate.getMonth(),
          applicationNotificationDate.getDate()
        );

        return eligibilityNotificationSentDate;
      }

      return null;
    },
    datepickerMaxDate(): Date {
      return new Date();
    },
    datePickerStartTime() {
      return { hours: 0, minutes: 0 };
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const activateForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    const datePickerFormat = (date: Date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${String(day).padStart(2, "0")}/${String(month).padStart(
        2,
        "0"
      )}/${year}`;
    };

    return {
      activateForm,
      datePickerFormat,
    };
  },
  methods: {
    yes() {
      this.simpleDatePickerValidation().then((valid) => {
        if (valid) {
          var clonedModel = {
            applicationId: this.activateModel.applicationId,
            activationStartDate: this.activateModel.activationStartDate,
            activationStartReasonId:
              this.activateModel.activationStartReasonId ===
              this.defaultReasonId
                ? undefined
                : this.activateModel.activationStartReasonId,
            activationStartOtherReason:
              this.activateModel.activationStartOtherReason,
          };
          if (clonedModel.activationStartReasonId === this.defaultReasonId) {
            clonedModel.activationStartReasonId = undefined;
          }
          const activationRequest = {
            applicationActivations: [
              clonedModel,
            ] as Array<ApplicationActivationModel>,
          } as SetActivationApplicationRequestModel;

          ApplicationDataService.setActivations(activationRequest)
            .then(
              (resp: AxiosResponse<SetActivationApplicationResponseModel>) => {
                if (resp) {
                  if (resp.data && resp.data.data.every((x) => x.success)) {
                    this.$toast.success("TSI ativada com sucesso.");
                    this.$emit("yes");
                  } else {
                    this.$toast.error(
                      "Ocorreu um erro na ativação da TSI, por favor tente novamente."
                    );
                  }
                }
              }
            )
            .catch((error: Error | AxiosError) => {
              if (axios.isAxiosError(error)) {
                // Access to config, request, and response
                const err = error as AxiosError;
                if (err.response && err.response.status === 400) {
                  // Just a stock error
                  const validationMessage = err.response
                    .data as ValidationMessage;
                  if (
                    validationMessage &&
                    validationMessage.errors &&
                    validationMessage.errors.length &&
                    validationMessage.errors[0].errors &&
                    validationMessage.errors[0].errors.length
                  ) {
                    this.$toast.error(validationMessage.errors[0].errors[0]);
                  }
                }
              } else {
                this.$toast.error(
                  "Ocorreu um erro na ativação da TSI, por favor tente novamente."
                );
              }
            });
        }
      });
    },
    no() {
      this.$emit("no");
    },
    close() {
      this.$emit("close");
    },
    getReasons() {
      ApplicationDataService.getActivationReasons()
        .then(
          (
            response: AxiosResponse<ApplicationActivationReasonsResponseModel>
          ) => {
            if (response && response.data) {
              this.reasons = response.data.data;
            }
          }
        )
        .catch((e: Error) => {
          console.log(e);
        });
    },
    onActivationDateChange(inputDate?: Date) {
      if (inputDate && this.application.eligibilityNotificationSentDate) {
        var applicationNotificationDate = new Date(
          this.application.eligibilityNotificationSentDate
        );
        var eligibilityNotificationSentDate = new Date(
          applicationNotificationDate.getFullYear(),
          applicationNotificationDate.getMonth(),
          applicationNotificationDate.getDate()
        );

        var selectedDate = new Date(
          inputDate.getFullYear(),
          inputDate.getMonth(),
          inputDate.getDate()
        );

        this.isDelayedActivation =
          DateHelpers.getDaysBetween(
            selectedDate,
            eligibilityNotificationSentDate
          ) > 10;
      } else {
        this.isDelayedActivation = false;
      }

      this.isDelayedActivationToggle();
    },
    onReasonChange() {
      this.activateModel.activationStartOtherReason = "";
    },
    isDelayedActivationToggle() {
      this.activateModel.activationStartReasonId = -1;
      this.activateModel.activationStartOtherReason = "";
    },
    simpleDatePickerValidation(): Promise<boolean> {
      return new Promise((resolve) => {
        if (this.activateModel.activationStartDate) {
          this.activateForm?.setFieldError("startDate", undefined);
          resolve(true);
        } else {
          this.activateForm?.setFieldError(
            "startDate",
            "O campo é obrigatório."
          );
          resolve(false);
        }
      });
    },
    validate() {
      this.simpleDatePickerValidation().then((resp) => {
        console.log(resp);
      });
    },
  },
  mounted() {
    this.getReasons();
    this.$emit("scrollToTop");
  },
});
