/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import LoginResponseModel from "@/models/Responses/LoginResponse.model";

export default function authHeader() {
  const localStorageItem = localStorage.getItem("currentUser");
  const user =
    localStorageItem && (JSON.parse(localStorageItem) as LoginResponseModel);

  if (user && user.accessToken) {
    return { Authorization: "Bearer " + user.accessToken };
  } else {
    return undefined;
  }
}
