
import { defineComponent } from "vue";
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import BounceLoader from "vue-spinner/src/BounceLoader.vue";
import "@/assets/scss/styles.scss";
import { mapGetters } from "vuex";
import AuthenticationDataService from "./services/AuthenticationDataService";
import {
  AdministratorPermission,
  OperatorPermission,
} from "./helper/Permission";

const spinnerParams = {
  color: "#ffc800",
  size: "100px",
};

export default defineComponent({
  components: { AppHeader, AppFooter, BounceLoader },
  name: "App",
  data() {
    return {
      spinnerParams: spinnerParams,
      scTimer: 0, //Needed for the scroll up button
      scY: 0, //Needed for the scroll up button
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "isUserLoggedIn",
      "isUserAdmin",
      "isUserProvider",
      "isUserOperator",
    ]),
    homeRoutePath(): string {
      if (this.isUserAdmin) {
        return "/applications";
      } else {
        return "/form";
      }
    },
  },
  methods: {
    canAccessWriterApplications() {
      return AuthenticationDataService.canUserAccess([
        OperatorPermission.ApplicationsModule_Writer,
      ]);
    },
    canAccessReaderApplications() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Applications_Reader,
        AdministratorPermission.Applications_Updater,
        OperatorPermission.ApplicationsModule_Reader,
        OperatorPermission.ApplicationsModule_Writer,
        OperatorPermission.ApplicationsModule_Updater,
      ]);
    },
    canAccessUsers() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Users_Reader,
        AdministratorPermission.Users_Writer,
        AdministratorPermission.Users_Updater,
      ]);
    },
    canAccessReaderAnnualMaintenance() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.AnnualMaintenance_Reader,
        OperatorPermission.AnnualMaintenanceModule_Reader,
      ]);
    },
    canAccessProviders() {
      return AuthenticationDataService.canUserAccess([
        AdministratorPermission.Providers_Reader,
        AdministratorPermission.Providers_Writer,
        AdministratorPermission.Providers_Updater,
      ]);
    },
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = (this.$refs.pageElement as HTMLElement).scrollTop;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    scrollToTop: function () {
      (this.$refs.pageElement as HTMLElement).scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    (this.$refs.pageElement as HTMLElement).addEventListener(
      "scroll",
      this.handleScroll,
      false
    );
  },
});
