
import { defineComponent, ref } from "vue";
import { Form, Field, configure, FormState } from "vee-validate";
import axios, { AxiosError, AxiosResponse } from "axios";
import AuthenticationDataService from "@/services/AuthenticationDataService";
import ValidationMessage from "@/models/ValidationMessage.model";
import PasswordChangeResponseModel from "@/models/Responses/PasswordChangeResponse.model";
import PasswordChangeRequestModel from "@/models/Requests/PasswordChangeRequest.model";
import router from "@/router";

export default defineComponent({
  name: "PasswordChangeForm",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      loginError: "",
      isSuccessModalVisible: false,
      submitted: false,
    };
  },
  computed: {
    schema() {
      const model = this.form;
      const schema = {
        oldPassword(value: string) {
          if (!value) {
            return "Campo obrigatório.";
          }
          // All is good
          return true;
        },

        newPassword(value: string) {
          if (!value) {
            return "Campo obrigatório.";
          }
          const regex =
            /^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.,"'?!;:#$%&()*+\-/<>=@[\]\\^_{}|~])[A-Za-z\d.,"'?!;:#$%&()*+\-/<>=@[\]\\^_{}|~]{10,25}$/;
          if (!regex.test(value)) {
            return "Insira no mínimo 10 e máximo 25 caracteres, contendo, pelo menos, uma letra maiúscula, uma letra minúscula, um número e um caracter especial (. , \" ' ? ! ; : # $ % & ( ) * + - / < > = @ [ ] \\ ^ _ { } | ~).";
          }

          if (value == model.oldPassword) {
            return 'Este campo deve ser diferente da "Palavra-passe atual."';
          }

          // All is good
          return true;
        },

        confirmNewPassword(value: string) {
          if (!value) {
            return "Campo obrigatório.";
          }

          if (value != model.newPassword) {
            return 'Este campo deve coincidir com "Nova Palavra-passe"';
          }

          // All is good
          return true;
        },
      };

      return schema;
    },
  },
  setup() {
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const passwordChangeForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      passwordChangeForm,
    };
  },
  methods: {
    submitForm() {
      this.passwordChangeForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            const passwordChangeRequest = {
              oldPassword: this.form.oldPassword,
              newPassword: this.form.confirmNewPassword,
            } as PasswordChangeRequestModel;

            AuthenticationDataService.passwordChange(passwordChangeRequest)
              .then((resp: AxiosResponse<PasswordChangeResponseModel>) => {
                if (resp) {
                  if (resp.data && resp.data.success) {
                    this.$toast.success(
                      "A palavra-passe foi atualizada com sucesso."
                    );
                    const redirectUrl =
                      this.$route.query.redirect?.toString() || "/";
                    router.push({ path: redirectUrl });
                  } else {
                    if (resp.data.errorCode === "Wrong_OldPassword") {
                      this.passwordChangeForm?.setFieldError(
                        "oldPassword",
                        resp.data.errorMessage
                      );
                      return;
                    }
                    if (resp.data.errorCode === "Identical_Password") {
                      this.passwordChangeForm?.setFieldError(
                        "newPassword",
                        resp.data.errorMessage
                      );
                      return;
                    }
                    this.$toast.error("Ocorreu um erro.");
                    console.error(resp.data.errorMessage);
                  }
                }
              })
              .catch((error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                  // Access to config, request, and response
                  const err = error as AxiosError;
                  if (err.response && err.response.status === 400) {
                    // Just a stock error
                    const validationMessage = err.response
                      .data as ValidationMessage;
                    if (
                      validationMessage &&
                      validationMessage.errors &&
                      validationMessage.errors.length &&
                      validationMessage.errors[0].errors &&
                      validationMessage.errors[0].errors.length
                    ) {
                      this.$toast.error(validationMessage.errors[0].errors[0]);
                    }
                  }
                } else {
                  // Just a stock error
                  this.$toast.error(
                    "Ocorreu um erro na submissão do pedido, por favor tente novamente."
                  );
                }
              });
          }
        })
        .catch((error: Error) => {
          console.log(error);
        });
    },
    resetForm() {
      this.passwordChangeForm?.resetForm();
      this.isSuccessModalVisible = false;
      this.loginError = "";
    },
    showSuccessModal() {
      this.isSuccessModalVisible = true;
    },
    closeSuccessModal() {
      this.isSuccessModalVisible = false;
      this.resetForm();
      this.$emit("scrollToTop");
    },
  },
});
