
import { defineComponent } from "vue";
import AddressDataService from "@/services/AddressDataService";
import SearchAddressResponseModel from "@/models/Responses/SearchAddressResponse.model";
import Address from "@/models/Address.model";
import { AxiosResponse } from "axios";

interface SelectableAddress {
  address: Address;
  isSelected: boolean;
}

export default defineComponent({
  name: "AddressModal",
  props: { cp4: String, cp3: String },
  data() {
    return {
      addresses: [] as SelectableAddress[],
    };
  },
  computed: {
    isAddressSelected(): boolean {
      return this.addresses.filter((x) => x.isSelected).length !== 0;
    },
  },
  methods: {
    getAddresses(cp4: string, cp3: string) {
      AddressDataService.search(cp4, cp3)
        .then((response: AxiosResponse<SearchAddressResponseModel>) => {
          if (response && response.data) {
            const addressArray = response.data.data.map((addr: Address) => {
              addr.arteryDesignation =
                addr.arteryDesignation +
                (addr.arteryLocalInfo ? " - " + addr.arteryLocalInfo : "");

              return {
                address: addr,
                isSelected: false,
              };
            });

            addressArray[0].isSelected = true;

            this.addresses = addressArray;
          }
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    close() {
      this.$emit("close");
    },
    save() {
      const selectedAddress = this.addresses.filter((x) => x.isSelected)[0];
      this.$emit("save", selectedAddress.address);
    },
    rowClicked(address: SelectableAddress) {
      const selectedAddresses = this.addresses.filter((x) => x.isSelected);
      // if none are selected, toggle the one that was selected
      if (selectedAddresses.length === 0) {
        address.isSelected = !address.isSelected;
      } else {
        //if any other is selected, de-select them
        selectedAddresses.forEach((addr) => {
          addr.isSelected = false;
        });

        address.isSelected = !address.isSelected;
      }
      return;
    },
  },
  mounted() {
    if (this.cp4 != undefined && this.cp3 != undefined) {
      this.getAddresses(this.cp4, this.cp3);
    }
  },
});
