import http from "@/services/HttpCommons";
import { AxiosResponse } from "axios";
import SearchAddressResponseModel from "@/models/Responses/SearchAddressResponse.model";

class AddressDataService {
  private CONTROLLER_BASE = "/Address";

  search(
    cp4: string,
    cp3: string
  ): Promise<AxiosResponse<SearchAddressResponseModel>> {
    return http.get(this.CONTROLLER_BASE + "/search", {
      params: { cp4: cp4, cp3: cp3 },
    });
  }
}

export default new AddressDataService();
