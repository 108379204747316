
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseCard",
  props: {
    isInvisible: Boolean,
  },
  computed: {
    hasTitle(): boolean {
      return !!this.$slots.title;
    },
    hasSubitle(): boolean {
      return !!this.$slots.subtitle;
    },
    hasContent(): boolean {
      return !!this.$slots.content;
    },
  },
});
