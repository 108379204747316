
import { defineComponent, PropType, ref } from "vue";
import { configure, Form, Field, FormState } from "vee-validate";
import ApplicationDataService from "@/services/ApplicationDataService";
import axios, { AxiosError, AxiosResponse } from "axios";
import ValidationMessage from "@/models/ValidationMessage.model";
import SetEligibilityApplicationRequestModel from "@/models/Requests/SetEligibilityApplicationRequest.model";
import EligibilityApplicationConfirmationModel from "@/models/EligibilityApplicationConfirmation.model";
import ApplicationStatus from "@/models/ApplicationStatus.model";

export default defineComponent({
  name: "EligibilityApplicationConfirmationModal",
  components: {
    Form,
    Field,
  },
  props: {
    inputData: {
      type: Object as PropType<ApplicationStatus>,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        ...this.inputData,
        reason: "",
      } as EligibilityApplicationConfirmationModel,
    };
  },
  computed: {
    schema() {
      const inputData = this.inputData;
      const schema = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reason(value: string) {
          // if the field is empty
          if (
            !value &&
            inputData.applicationStatus?.description === "Não Elegível"
          ) {
            return "Campo obrigatório.";
          }
          // All is good
          return true;
        },
      };

      return schema;
    },
  },
  setup() {
    // Default values
    configure({
      validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
      validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
      validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
      validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    });

    const confirmationForm = ref<null | {
      setFieldError: (field: string, message: string | undefined) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setFieldValue: (field: string, value: any) => void;
      validate: () => Promise<{
        valid: boolean;
        errors: Record<string, string>;
      }>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resetForm: (state?: Partial<FormState<any>>) => void;
    }>(null);

    return {
      confirmationForm,
    };
  },
  methods: {
    yes() {
      this.confirmationForm
        ?.validate()
        .then((result: { valid: boolean; errors: Record<string, string> }) => {
          if (result.valid) {
            const request = {
              applicationId: this.formData.applicationId,
              applicationStatus: this.formData.applicationStatus?.id,
              reason: this.formData.reason,
            } as SetEligibilityApplicationRequestModel;

            ApplicationDataService.setEligibility(request)
              .then((resp: AxiosResponse<boolean>) => {
                if (resp) {
                  if (resp.data) {
                    this.$toast.success("Operação efetuada com sucesso.");
                    this.$emit("yes");
                  } else {
                    this.$toast.error(
                      "Ocorreu um erro na operação, por favor tente novamente."
                    );
                  }
                }
              })
              .catch((error: Error | AxiosError) => {
                if (axios.isAxiosError(error)) {
                  // Access to config, request, and response
                  const err = error as AxiosError;
                  if (err.response && err.response.status === 400) {
                    // Just a stock error
                    const validationMessage = err.response
                      .data as ValidationMessage;
                    if (
                      validationMessage &&
                      validationMessage.errors &&
                      validationMessage.errors.length &&
                      validationMessage.errors[0].errors &&
                      validationMessage.errors[0].errors.length
                    ) {
                      this.$toast.error(validationMessage.errors[0].errors[0]);
                      return;
                    }
                  }
                }

                this.$toast.error(
                  "Ocorreu um erro na operação, por favor tente novamente."
                );
              });
          }
        });
    },
    no() {
      this.$emit("no");
    },
    close() {
      this.$emit("close");
    },
    isNonEligible() {
      return this.formData.applicationStatus?.description === "Não Elegível";
    },
  },
  mounted() {
    this.$emit("scrollToTop");
  },
});
